import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { notification, Modal, Button, Form, Upload, message } from "antd";
import { connect } from "react-redux";
import appInfo from "../../app-info";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function Training() {
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [trainingImage, setTrainingImage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get("section").then((result) => {
      if (result.status == 200) {
        setTrainingImage([
          {
            uid: result.data.content.id,
            name: "section",
            status: "done",
            url: appInfo.apiURL + result.data.content.filePath,
          },
        ]);
      }
    });
  };

  const onFinish = () => {
    setButtonLoading(true);

    if (trainingImage.length > 0) {
      var formData = new FormData();
      formData.set("file", trainingImage[0].originFileObj);

      API.post("section/add", formData).then((result) => {
        if (result.status === 200) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
          fetchData();
        }
      });
    } else {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Зураг сонгоно уу",
      });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = ({ file, fileList: newFileList }) => {
    if (newFileList.length >= 2) {
      newFileList = newFileList.slice(-1);
    }
    const isImage = file.type === "image/jpeg" || file.type === "image/png";

    if (!isImage) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Файлын 5мб-аас ихгүй байх ёстой!");
      return;
    }

    setTrainingImage(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <h5>Дугуйлангийн хуваарь</h5>
      <Form layout="horizontal" form={form} onFinish={onFinish}>
        <Form.Item>
          <Upload
            listType="picture-card"
            fileList={trainingImage}
            onPreview={handlePreview}
            customRequest={dummyRequest}
            onChange={onChange}
          >
            + Зураг солих
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={buttonLoading}>
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
      <Modal
        visible={previewVisible}
        title={"Зураг"}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, schoolClass } = authentication;
  return {
    userData: user,
    schoolClass: schoolClass !== undefined ? schoolClass : [],
  };
}

const connectedTraining = connect(mapState, null)(Training);
export { connectedTraining as Training };
