import React, { useState, useEffect } from "react";
import { API } from "../../../_helpers/service";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { PageTitle } from "../../../components";
import { AndroidVendingMachine } from "./AndroidVendingMachine";

function AndroidVendingTable({ userData, schoolClass }) {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    API.get("vending/android/dashboard/list").then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setProducts(result.data.content.content);
        }
      }
    });
  };

  return (
    <>
      <PageTitle title="Бараа тохируулах-2" description="Бараа тохируулах-2" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="oyunlag-vending-machine">
                      <div className="vending-row">
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={1}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={2}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={3}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={4}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={5}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={6}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={7}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={8}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={9}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={10}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={11}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={12}
                        />
                      </div>
                      <div className="vending-row">
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={21}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={22}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={23}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={24}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={25}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={26}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={27}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={28}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={29}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={30}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={31}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={32}
                        />
                      </div>
                      <div className="vending-row">
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={41}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={42}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={43}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={44}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={45}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={46}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={47}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={48}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={49}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={50}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={51}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={52}
                        />
                      </div>
                      <div className="vending-row">
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={61}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={62}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={63}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={64}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={65}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={66}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={67}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={68}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={69}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={70}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={71}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={72}
                        />
                      </div>
                      <div className="vending-row">
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={81}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={82}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={83}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={84}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={85}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={86}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={87}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={88}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={89}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={90}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={91}
                        />
                        <AndroidVendingMachine
                          products={products}
                          rowNumber={92}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return { userData: user, teacherData: teacher, schoolClass };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedAndroidVendingTable = connect(
  mapState,
  actionCreators
)(AndroidVendingTable);
export { connectedAndroidVendingTable as AndroidVendingTable };
