import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import NumberFormat from "react-number-format";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Products() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getProducts = () => {
    API.get("product/list").then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
        }
      }
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const confirm = (productId) => {
    deleteProduct(productId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteProduct = (productId) => {
    API.delete(`product/delete/?productId=${productId}`).then((result) => {
      if (result.status == 200) {
        getProducts();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Бүтээгдэхүүний нэр",
      dataIndex: "name",
      key: "name",
      //   render: (text, record) => (
      //     <Link
      //       to={{
      //         pathname: `/product/${record.routeNumber}`,
      //         state: { routeData: record },
      //       }}
      //     >
      //       {text}
      //     </Link>
      //   ),
    },
    {
      title: "Үнэ",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    // {
    //   title: "Доод хязгаар",
    //   dataIndex: "minBalance",
    //   key: "minBalance",
    //   render: (text) => (
    //     <NumberFormat
    //       style={{ color: "red" }}
    //       displayType={"text"}
    //       thousandSeparator={true}
    //       suffix={"₮"}
    //       value={text}
    //     />
    //   ),
    // },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            <Link
              to={`/product/${record.id}`}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-pencil"></i>
            </Link>

            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => confirm(record.id)}
              onCancel={cancel}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerRoute(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerRoute = (values) => {
    API.post("product/add", {
      name: values.name,
      price: values.price,
      minBalance: values.minBalance,
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.success) {
            getProducts();
            setVisible(false);
          } else {
            message.error(result.data.message);
          }
        }
      })
      .catch((error) => {
        const result = error.response;
        if (result.status === 400) {
          if (result.data.errors !== undefined) {
            if (result.data.errors.length > 0) {
              message.error(result.data.errors[0].defaultMessage);
            } else {
              message.error("Алдаа гарлаа");
            }
          } else {
            message.error(result.data.message);
          }
        }
      });
  };
  return (
    <>
      <PageTitle
        title="Бүтээгдэхүүний жагсаалт"
        description="Бүтээгдэхүүний жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Бүтээгдэхүүн нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        destroyOnClose={true}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Барааны нэр"
            name="name"
            rules={[{ required: true, message: "Барааны нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Барааны үнэ"
            name="price"
            rules={[{ required: true, message: "Барааны үнэ оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Доод хязгаар"
            name="minBalance"
            rules={[{ required: true, message: "Доод хязгаар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedProducts = connect(mapState, actionCreators)(Products);
export { connectedProducts as Products };
