import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  InputNumber,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;
const { TreeNode } = TreeSelect;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Library() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [formArchive] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleArchive, setVisibleArchive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentBook, setCurrentBook] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      {
        params: {
          page: currentPage - 1,
          size: showNum,
          type: "PAPER",
          status: "ACTIVE",
        },
      },
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, type: "PAPER", status: "ACTIVE" } },
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return (
          <Zoom>
            <img src={appInfo.apiURL + text} width={50} />
          </Zoom>
        );
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/book/${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: "Ангилал",
      dataIndex: "category",
      key: "category",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/category/${record.category.id}`,
            state: { categoryName: record.category.categoryName },
          }}
        >
          {record.category.categoryName}
        </Link>
      ),
    },
    {
      title: "Үүсгэсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "ISBN",
      dataIndex: "isbn",
      key: "isbn",
    },
    {
      title: "Хэвлэсэн огноо",
      dataIndex: "publicationDate",
      key: "publicationDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Нийт",
      dataIndex: "bookCount",
      key: "bookCount",
      render: (text, record) => text + "ш",
    },
    {
      title: "Бэлэн",
      dataIndex: "currentBookCount",
      key: "currentBookCount",
      render: (text, record) => text + "ш",
    },
    {
      title: "Шинэ ном",
      dataIndex: "newBookCount",
      key: "newBookCount",
      render: (text, record) => text + "ш",
    },
    {
      title: "Хэрэглэсэн",
      dataIndex: "usedBookCount",
      key: "usedBookCount",
      render: (text, record) => text + "ш",
    },
    {
      title: "Төлөв",
      dataIndex: "bookStatus",
      key: "bookStatus",
      render: (text, record) => (text === "ACTIVE" ? "Идэвхтэй" : "Архив"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            <Link
              to={`/book/${record.id}`}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-pencil"></i>
            </Link>

            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Delete"
              onClick={() => {
                setCurrentBook(record.id);
                setVisibleArchive(true);
              }}
            >
              <i className="mdi mdi-package-down"></i>
            </button>
          </div>
        </Space>
      ),
    },
  ];

  const confirm = (bookId) => {
    deleteBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteBook = (bookId) => {
    API.delete(`library/books/delete/?bookId=${bookId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerBook = (values) => {
    setUploading(true);
    API.post("library/books/add", {
      name: values.name,
      isbn: values.isbn,
      authorName: values.authorName,
      publishedPlace: values.publishedPlace,
      publicationDate: moment(values.publicationDate).format("YYYY-MM-DD"),
      schoolRegisteredDate: moment(values.schoolRegisteredDate).format(
        "YYYY-MM-DD",
      ),
      note: values.note,
      bookCondition: "NEW",
      categoryId: values.category,
      bookType: "PAPER",
      bookCount: values.bookCount,
      usedBookCount: values.usedBookCount,
      newBookCount: values.newBookCount,
    }).then((result) => {
      if (result.status === 200) {
        const response = result.data.content;
        var formData = new FormData();
        formData.set("file", values.cover[0].originFileObj);
        formData.set("type", "COVER_PHOTO");
        formData.set("id", response.id);
        formData.set("entity", "LIBRARY");
        API.post("file/upload", formData).then((resultCover) => {
          if (resultCover.status === 200) {
            form.resetFields();
            setUploading(false);
            fetchData(currentPage);
            setVisible(false);
          } else {
            setUploading(false);
            notification["error"]({
              message: "Амжилтгүй",
              description: result.data.message,
            });
          }
        });
      } else {
        setUploading(false);
        notification["error"]({
          message: "Амжилтгүй",
          description: result.data.message,
        });
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const handleArchive = () => {
    formArchive
      .validateFields()
      .then((values) => {
        API.post("library/books/archive", {
          id: currentBook,
          bookCount: values.bookCount,
          bookCondition: values.bookCondition,
          archiveReason: values.note,
        }).then((result) => {
          if (result.status === 200) {
            setVisibleArchive(false);
            formArchive.resetFields();
            fetchData(currentPage);
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const changeNewBook = (num) => {
    let used = form.getFieldValue("usedBookCount");
    form.setFieldsValue({ bookCount: used + num });
    return num;
  };

  const changeUsedBook = (num) => {
    let newCount = form.getFieldValue("newBookCount");
    form.setFieldsValue({ bookCount: newCount + num });
    return num;
  };

  return (
    <>
      <PageTitle
        title="Номын жагсаалт"
        description="Системийн нийт номын жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Ном нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="name" key="name">
                              Номын нэрээр
                            </Option>
                            <Option value="authorName" key="authorName">
                              Номын зохиолчоор
                            </Option>
                            <Option value="isbn" key="isbn">
                              ISBN
                            </Option>
                            <Option
                              value="publicationDate"
                              key="publicationDate"
                            >
                              Хэвлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Номын нэр">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10} key={10}>
                          10
                        </Option>
                        <Option value={20} key={20}>
                          20
                        </Option>
                        <Option value={30} key={30}>
                          30
                        </Option>
                        <Option value={40} key={40}>
                          40
                        </Option>
                        <Option value={50} key={50}>
                          50
                        </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном архивлах"
        visible={visibleArchive}
        onOk={handleArchive}
        onCancel={() => {
          setVisibleArchive(false);
          formArchive.resetFields();
        }}
        okText="Архивлах"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={formArchive}
          initialValues={{ bookCount: 1 }}
        >
          <Form.Item label="Тоо ширхэг" name="bookCount">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="Үнэлгээ" name="bookCondition">
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value="NEW">Шинэ</Option>
              <Option value="OLD">Ашигласан</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Тэмдэглэл" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={uploading}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{
            bookCondition: "NEW",
            newBookCount: 0,
            usedBookCount: 0,
          }}
        >
          <Form.Item
            label="Номын нэр"
            name="name"
            rules={[{ required: true, message: "Номын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номын ангилал"
            name="category"
            rules={[{ required: true, message: "Номын ангилал сонгоно уу!" }]}
          >
            <TreeSelect
              treeData={categories}
              showSearch
              style={{ width: "100%" }}
              placeholder="Сонгоно уу"
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              allowClear
              treeDefaultExpandAll
              treeNodeLabelProp="categoryName"
              multiple={false}
              showCheckedStrategy="SHOW_PARENT"
            />
          </Form.Item>
          <Form.Item
            label="ISBN"
            name="isbn"
            rules={[{ required: true, message: "ISBN оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Зохиолчын нэр"
            name="authorName"
            rules={[{ required: true, message: "Зохиолчын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Хэвлэсэн газар"
            name="publishedPlace"
            rules={[{ required: true, message: "Хэвлэсэн газар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Номын тоо" name="bookCount">
            <InputNumber disabled />
          </Form.Item>
          <Form.Item
            label="Шинэ номын тоо"
            name="newBookCount"
            getValueFromEvent={changeNewBook}
            rules={[{ required: true, message: "Шинэ номын тоо оруулна уу!" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Ашигласан номын тоо"
            name="usedBookCount"
            getValueFromEvent={changeUsedBook}
            rules={[
              { required: true, message: "Ашигласан номын тоо оруулна уу!" },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Хэвлэсэн огноо"
            name="publicationDate"
            rules={[{ required: true, message: "Хэвлэсэн огноо оруулна уу!" }]}
          >
            <DatePicker
              disabledDate={(d) =>
                !d || d.isAfter(moment().subtract(1, "days"))
              }
            />
          </Form.Item>
          <Form.Item
            label="Бүртгэгдсэн огноо"
            name="schoolRegisteredDate"
            rules={[{ required: true, message: "Огноо оруулна уу!" }]}
          >
            <DatePicker
              disabledDate={(d) =>
                !d || d.isAfter(moment().subtract(1, "days"))
              }
            />
          </Form.Item>
          <Form.Item
            name="cover"
            label="Номын хавтас"
            valuePropName="fileList"
            getValueFromEvent={normCover}
            rules={[{ required: true, message: "Номын хавтас сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Тэмдэглэл" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedLibrary = connect(mapState, actionCreators)(Library);
export { connectedLibrary as Library };
