import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "../../_helpers/service";
import { history } from "../../App";
import {
  UserOutlined,
  BellOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Icon } from "../";
import {
  Layout,
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Checkbox,
  notification,
} from "antd";
import Moment from "react-moment";
import { askForPermissioToReceiveNotifications } from "../../push-notification";
import firebase from "firebase";
import { alertActions } from "../../_actions";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
// import i18n from "i18next";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function MainHeader({ setCollapsed, userData }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Header style={{ padding: 0, height: 70 }}>
      <div className="navbar-header">
        <div className="d-flex">
          {/* <button
            type="button"
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={() => setCollapsed()}
          >
            <Icon name="left-indent" color="#e9ecef" />
          </button> */}
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <div className="dropdown d-inline-block">
            <OutsideClickHandler
              onOutsideClick={() => (isOpen ? setIsOpen(false) : null)}
            >
              <button
                type="button"
                className="btn header-item waves-effect"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={require("../../assets/images/users/avatar-1.jpg")}
                  alt="Header Avatar"
                />
                <span className="d-none d-sm-inline-block ml-1">
                  {userData ? userData.lastName + " " + userData.firstName : ""}
                </span>
                <i className="mdi mdi-chevron-down d-none d-sm-inline-block"></i>
              </button>
            </OutsideClickHandler>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                isOpen ? "show" : ""
              }`}
            >
              <Link className="dropdown-item" to="/profile">
                <i className="mdi mdi-face-profile font-size-16 align-middle mr-1"></i>
                Профайл
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/login">
                <i className="mdi mdi-logout font-size-16 align-middle mr-1"></i>
                Гарах
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}

const mapState = function (state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user: authentication,
    userData: user,
  };
};

const connectedMainHeader = connect(mapState)(MainHeader);
export { connectedMainHeader as MainHeader };
