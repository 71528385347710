import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Typography,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";
import { FormItemContext } from "antd/lib/form/context";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function KitchenSupplyHistory({ userData }) {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = () => {
    API.post(
      "class/all",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: 0, size: 50 } }
    ).then((result) => {
      if (result.status === 200) {
        setCategories(result.data.content);
      }
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    let days = [];
    for (
      var m = moment(values.date[0]);
      m.isSameOrBefore(values.date[1]);
      m.add(1, "days")
    ) {
      days.push({
        title: m.format("YYYY.MM.DD"),
        dataIndex: m.format("YYYY.MM.DD"),
        width: 120,
        align: "center",
        render: (text, row, index) =>
          text !== undefined ? text : <span style={{ color: "red" }}>0</span>,
      });
    }

    setDateColumns(days);

    API.get(`kitchen/supply-list-history`, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          let content = result.data.content;
          let tableData = [];
          categories.map((category) => {
            let classData = {};
            const classId = category.classId;
            const filteredContent = content.filter(
              (item) => item.classId === classId
            );
            classData.className = category.displayName;

            filteredContent.map(
              (item) =>
                (classData[moment(item.requestedDate).format("YYYY.MM.DD")] =
                  item.count)
            );

            tableData.push(classData);
          });

          setData(tableData);
        }
        setLoading(false);
      }
    });
  };

  return (
    <>
      <PageTitle
        title="Ангийн хоолны тооны түүх"
        description="Ангийн хоолны тооны түүх"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                      >
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        scroll={{ y: 600, x: 960 }}
                        columns={[
                          {
                            title: "№",
                            dataIndex: "id",
                            key: "id",
                            width: 70,
                            fixed: "left",
                            render: (text, row, index) => <>{index + 1}</>,
                          },
                          {
                            title: "Анги",
                            dataIndex: "className",
                            key: "className",
                            fixed: "left",
                            width: 100,
                          },
                          ...dateColumns,
                          {
                            title: "Нийт",
                            dataIndex: "summary",
                            key: "summary",
                            width: 120,
                            fixed: "right",
                            render: (text, row, index) => {
                              let total = 0;
                              Object.values(row).map((item) => {
                                if (Number.isInteger(item)) {
                                  total += item;
                                }
                              });

                              return <>{total}</>;
                            },
                          },
                        ]}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedKitchenSupplyHistory = connect(
  mapState,
  actionCreators
)(KitchenSupplyHistory);
export { connectedKitchenSupplyHistory as KitchenSupplyHistory };
