import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  Input,
  message,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  StudentTermResult,
  StudentAccountHistory,
  StudentProfile,
  InvoiceList,
  StudentActivity,
  StudentLibrary,
  StudentMonthResult,
  StudentAttendance,
  StudentHomeWork,
} from "./";

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function StudentItem({ userData }) {
  const [form] = Form.useForm();
  const [formRfid] = Form.useForm();
  const [searchForm] = Form.useForm();
  let history = useHistory();
  let { studentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const [name, setName] = useState("-");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchData();

    if (
      userData.userRoles.includes("ROLE_ADMIN") ||
      userData.userRoles.includes("ROLE_EMPLOYEE")
    ) {
      setIsAdmin(true);
    }
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.get(`students/${studentId}`, {}).then((result) => {
      if (result.status == 200) {
        setName(result.data.content.displayName);
        setData(result.data.content);
        setLoading(false);
      }
    });
  };

  const handleOk = () => {
    formRfid
      .validateFields()
      .then((values) => {
        API.post("students/rfid/assign", {
          studentId: studentId,
          rfid: values.rfid,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setVisible(false);
              formRfid.resetFields();
              fetchData();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "RFID устгах уу?",
      icon: <ExclamationCircleOutlined />,
      content: null,
      okText: "Устгах",
      okType: "danger",
      cancelText: "Болих",
      onOk() {
        API.delete(`students/rfid/remove/${studentId}`).then((result) => {
          if (result.status == 200) {
            fetchData();
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <PageTitle
        title={name}
        description={
          <Link
            to={{
              pathname: `/class-item/${data.classId}`,
            }}
          >
            Буцах
          </Link>
        }
        right={
          isAdmin ? (
            data.rfidCardId !== null ? (
              <Button onClick={() => showDeleteConfirm()} danger>
                RFID устгах
              </Button>
            ) : (
              <Button onClick={() => setVisible(true)}>RFID оноох</Button>
            )
          ) : null
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Tabs defaultActiveKey="9" onChange={callback}>
                      <TabPane tab={<span>Сурагчийн мэдээлэл</span>} key="1">
                        <StudentProfile data={data} />
                      </TabPane>
                      <TabPane
                        tab={<span>Сурагчийн үйл ажиллагаа</span>}
                        key="2"
                      >
                        <StudentActivity studentData={data} />
                      </TabPane>
                      <TabPane tab={<span>Ерөнхий дүн</span>} key="3">
                        <StudentTermResult />
                      </TabPane>
                      <TabPane tab={<span>Явцын шалгалт</span>} key="7">
                        <StudentMonthResult />
                      </TabPane>
                      <TabPane tab={<span>Номын сан</span>} key="4">
                        <StudentLibrary />
                      </TabPane>
                      <TabPane tab={<span>Дансны хуулга</span>} key="5">
                        <StudentAccountHistory />
                      </TabPane>
                      <TabPane tab={<span>Нэхэмжлэлүүд</span>} key="6">
                        <InvoiceList />
                      </TabPane>
                      <TabPane tab={<span>Ирц</span>} key="8">
                        <StudentAttendance />
                      </TabPane>
                      <TabPane tab={<span>Гэрийн даалгавар</span>} key="9">
                        <StudentHomeWork />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="RFID оноох"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
          formRfid.resetFields();
        }}
        okText="Оноох"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={formRfid}>
          <Form.Item
            label="Картын дугаар"
            name="rfid"
            rules={[{ required: true, message: "Картын дугаар оруулна уу" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { userData: user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentItem = connect(mapState, actionCreators)(StudentItem);
export { connectedStudentItem as StudentItem };
