import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const { TreeNode } = TreeSelect;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function InvoiceList() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [date, setDate] = useState(moment().format("YYYY/MM/DD"));
  const [vendingAccountID, setVendingAccountID] = useState(null);
  const [kitchenAccountID, setKitchenAccountID] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("vending");

  let { studentId } = useParams();

  const checkPayment = (paymentId) => {
    API.get("students/account/charge-check-dashboard", {
      params: {
        paymentId: paymentId,
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          form.submit();
        }
      }
    });
  };

  const getStatusName = (status) => {
    switch (status) {
      case "PAID":
        return <span style={{ color: "green" }}>Амжилттай</span>;
      case "INVOICE_CREATED":
        return <span style={{ color: "orange" }}>Нэхэмжлэл үүсэн</span>;
      default:
        return <span style={{ color: "red" }}>Төлөөгүй</span>;
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Данс",
      dataIndex: "accountType",
      key: "accountType",
      render: (text, row, index) =>
        text === "FOOD" ? "Хоолны данс" : "Вендинг машины данс",
    },
    {
      title: "Үүссэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Гүйлгээний дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (text, record) => getStatusName(text),
    },
    {
      title: "Гүйлгээний дугаар",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Системийн шалгалт",
      dataIndex: "verifiedCron",
      key: "verifiedCron",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "Гар шалгалт",
      dataIndex: "verfied",
      key: "verfied",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "Шалгах",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.status === "PAID" ? (
          <span style={{ color: "green" }}>Амжилттай</span>
        ) : (
          <Button type="primary" onClick={() => checkPayment(record.paymentId)}>
            Шалгах
          </Button>
        ),
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    API.get("payment/transactions", {
      params: {
        studentId: studentId,
        startDate: moment(values.date[0]).format("YYYY/MM/DD 00:00"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD 23:59"),
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="inline"
          style={{ marginBottom: 10 }}
          initialValues={{
            date: [moment().subtract(1, "days"), moment()],
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="date"
            label="Хугацаа"
            rules={[{ required: true, message: "Хугацаа сонгоно уу!" }]}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Харах
            </Button>
          </Form.Item>
        </Form>
        <Table
          className="table table-centered table-hover mb-0"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          //   locale={{
          //     emptyText: `${moment(form.getFieldValue("date")[0]).format(
          //       "YYYY/MM/DD"
          //     )} - ${moment(form.getFieldValue("date")[1]).format(
          //       "YYYY/MM/DD"
          //     )} хооронд гүйлгээ хийгдээгүй байна`,
          //   }}
        />
      </Spin>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedInvoiceList = connect(mapState, actionCreators)(InvoiceList);
export { connectedInvoiceList as InvoiceList };
