import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "../../_helpers/service";
import { history } from "../../App";
import {
  UserOutlined,
  BellOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Menu, Avatar, Badge, Popover, notification } from "antd";
import Moment from "react-moment";
import { askForPermissioToReceiveNotifications } from "../../push-notification";
import firebase from "firebase";
import { alertActions } from "../../_actions";

// import i18n from "i18next";

function PageTitle({ user, title = "", description = "", right = null }) {
  let location = useLocation();
  const userData = user.user;
  const [notifications, setNotification] = useState([]);
  const [counter, setCounter] = useState(0);

  // useEffect(() => {
  //   let noCounter = 0;
  //   let notifDatas = [];
  //   API.get("notification/history", {
  //     params: { userID: userData.userID, getPublic: true },
  //   }).then((result) => {
  //     if (result.status == 200) {
  //       const historyDataJson = JSON.stringify(result);
  //       const historyData = JSON.parse(historyDataJson);
  //       const historyDataObj = JSON.parse(historyData.data);
  //       if (historyDataObj !== null) {
  //         noCounter = historyDataObj.length;
  //         setCounter(noCounter);
  //         historyDataObj.map((item) =>
  //           notifDatas.push({
  //             ...item.Message.message,
  //             date: item.Date,
  //           }),
  //         );
  //         setNotification(notifDatas);
  //       }
  //     }
  //   });

  //   if (firebase.messaging.isSupported()) {
  //     if (!firebase.apps.length) {
  //       firebase.initializeApp({
  //         apiKey: "AIzaSyAqavs7xREPSNRwArHBkg-4BXQSiqwXCHI",
  //         authDomain: "digitalworks-cbs.firebaseapp.com",
  //         databaseURL: "https://digitalworks-cbs.firebaseio.com",
  //         projectId: "digitalworks-cbs",
  //         storageBucket: "digitalworks-cbs.appspot.com",
  //         messagingSenderId: "876190067517",
  //         appId: "1:876190067517:web:f40971f52eae9c96f47354",
  //         measurementId: "G-JLWX58XT3N",
  //       });
  //       askForPermissioToReceiveNotifications(userData.userID);
  //       const messaging = firebase.messaging();

  //       messaging.onMessage((payload) => {
  //         if (payload.data.isStart) {
  //           notification["info"]({
  //             duration: 0,
  //             message: payload.notification.title,
  //             description: payload.notification.body,
  //             placement: "bottomRight",
  //           });
  //         } else {
  //           notification["success"]({
  //             message: payload.notification.title,
  //             description: payload.notification.body,
  //           });
  //         }
  //         let newCounter = noCounter + 1;
  //         setNotification([...notifDatas, payload]);
  //         setCounter(newCounter);
  //       });
  //     }
  //   }
  // }, []);
  return (
    <div className="page-title-box">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h4 className="page-title mb-1">{title}</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">{description}</li>
            </ol>
          </div>
          {right ? (
            <div className="col-md-4">
              <div className="float-right d-none d-md-block">
                <div className="dropdown">{right}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const steps = [
  {
    selector: ".tour-1",
    content: "Харилцагч нь ингэдэг тэгдэг юмаа",
  },
  {
    selector: ".tour-2",
    content: "Зээл нь ингэдэг тэгдэг юмаа",
  },
  {
    selector: ".tour-3",
    content: "Тайлан нь ингэдэг тэгдэг юмаа",
  },
];

const mapState = function (state) {
  const { authentication } = state;
  return {
    user: authentication,
  };
};

const connectedPageTitle = connect(mapState)(PageTitle);
export { connectedPageTitle as PageTitle };
