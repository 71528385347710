import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  TreeSelect,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import Zoom from "react-medium-image-zoom";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function BookCategory() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [currentCover, setCurrentCover] = useState(10);
  const [coverVisible, setCoverVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    // setLoading(true);
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setData(result.data.content);
        // setLoading(false);
      }
    });
  };

  const getStageName = (name) => {
    switch (name) {
      case "High":
        return "Ахлах";
      case "Middle":
        return "Дунд";
      case "Elementary":
        return "Бага";
      default:
        return "Нийтийн";
    }
  };

  const columns = [
    {
      title: "Нэр",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (text, record) => <a>{record.categoryName}</a>,
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return !!text ? (
          <Zoom>
            <img src={appInfo.apiURL + text} width={50} />
          </Zoom>
        ) : (
          ""
        );
      },
    },
    {
      title: "Номын тоо",
      dataIndex: "totalBookCount",
      key: "totalBookCount",
    },
    {
      title: "Төрөл",
      dataIndex: "stage",
      key: "stage",
      render: (text, record) => getStageName(text),
    },
    {
      title: "Үүсгэсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            <Link
              to={{
                pathname: `/category/${record.id}`,
                state: { categoryName: record.categoryName },
              }}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-eye"></i>
            </Link>

            <Link
              to={{
                pathname: `/book-category-edit/${record.id}`,
                state: { categoryData: record },
              }}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-pencil"></i>
            </Link>

            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => confirm(record.id)}
              onCancel={cancel}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const confirm = (bookId) => {
    deleteBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const changePageNum = (val) => {
    console.log(val);
  };

  const deleteBook = (bookId) => {
    API.delete(`library/books/category/delete/?categoryId=${bookId}`).then(
      async (result) => {
        if (result.status == 200) {
          await fetchData();

          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        }
      }
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        API.post("library/books/category/add", {
          categoryName: values.name,
          parentId: values.parentId,
          stage: values.stage,
        }).then((result) => {
          if (result.status === 200) {
            var formData = new FormData();
            formData.set("file", values.book[0].originFileObj);
            formData.set("id", result.id);
            formData.set("type", "FILE");
            formData.set("entity", "LIBRARY");

            API.post("file/upload", formData).then((resultFile) => {
              if (resultFile.status === 200) {
                formData.set("file", values.cover[0].originFileObj);
                formData.set("type", "COVER_PHOTO");
                formData.set("entity", "LIBRARY_BOOK_CATEGORY");
                API.post("file/upload", formData).then((resultCover) => {
                  if (resultCover.status === 200) {
                    fetchData();
                    setVisible(false);
                    form.resetFields();
                  }
                });
              }
            });
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreviewCover = (file) => {
    console.log(file);
    setCoverVisible(true);
    setCurrentCover(file.url);
  };

  const normCover = (e) => {
    console.log(e.fileList);
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <PageTitle
        title="Ангиллын жагсаалт"
        description="Системийн нийт ангиллын жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Ангилал нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ангилал нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ stage: "PUBLIC" }}
        >
          <Form.Item
            label="Ангилалын нэр"
            name="name"
            rules={[{ required: true, message: "Ангилалын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Дэд ангилал" name="parentId">
            <TreeSelect
              treeData={data}
              showSearch
              style={{ width: "100%" }}
              placeholder="Сонгоно уу"
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              allowClear
              treeDefaultExpandAll
              treeNodeLabelProp="categoryName"
              multiple={false}
              showCheckedStrategy="SHOW_PARENT"
            />
          </Form.Item>
          <Form.Item name="stage" label="Төрөл">
            <Select>
              <Option value="Elementary">Бага анги</Option>
              <Option value="Middle">Дунд анги</Option>
              <Option value="High">Ахлах анги</Option>
              <Option value="PUBLIC">Нийтийн</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="cover"
            label="Зураг"
            valuePropName="fileList"
            getValueFromEvent={normCover}
            rules={[
              {
                required: true,
                message: "Зураг сонгоно уу!",
              },
            ]}
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture"
              onPreview={handlePreviewCover}
            >
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedBookCategory = connect(mapState, actionCreators)(BookCategory);
export { connectedBookCategory as BookCategory };
