import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { notification, Modal, Button, Form, Upload, message } from "antd";
import { connect } from "react-redux";
import ReactQuill from "react-quill";

function Rehearsal() {
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.post("dynamic/rehearsal").then((result) => {
      if (result.status == 200) {
        setValue(result.data.content.content);
      }
    });
  };

  const saveRehearsal = () => {
    setButtonLoading(true);
    API.post("dynamic/rehearsal/add", {
      content: value,
    }).then((result) => {
      if (result.status === 200) {
        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
        fetchData();
      }
      setButtonLoading(false);
    });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <h5>Давтлага тарах хугацаа</h5>

      <div style={{ marginBottom: 20 }}>
        <ReactQuill
          theme="snow"
          defaultValue={value}
          value={value}
          onChange={setValue}
        />
      </div>
      <Button
        type="primary"
        onClick={() => saveRehearsal()}
        loading={buttonLoading}
      >
        Хадгалах
      </Button>
    </div>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, schoolClass } = authentication;
  return {
    userData: user,
    schoolClass: schoolClass !== undefined ? schoolClass : [],
  };
}

const connectedRehearsal = connect(mapState, null)(Rehearsal);
export { connectedRehearsal as Rehearsal };
