import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  Select,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import NumberFormat from "react-number-format";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

function ClassItem({ user }) {
  const [form] = Form.useForm();
  const [formRfid] = Form.useForm();
  const [data, setData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleRfid, setVisibleRfid] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);

  let { classId } = useParams();

  useEffect(() => {
    getRoutes();
    fetchData();
    if (user.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
    if (user.userRoles.includes("ROLE_EMPLOYEE")) {
      setIsManager(true);
    }
  }, []);

  const getRoutes = () => {
    API.get("driver/route-list").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setDrivers(result.data.content);
        }
      }
    });
  };

  const fetchData = () => {
    setLoading(true);
    API.get(`class/${classId}`, {}).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setClassData(result.data.content);
          const classId = result.data.content.id;
          API.get(`class/students/${classId}`).then((result) => {
            if (result.status === 200) {
              setData(result.data.content);
              setLoading(false);
            }
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Нэр",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => (
        <Link to={`/student/${record.studentId}`}>{text}</Link>
      ),
    },
    {
      title: "Имэйл",
      dataIndex: "microsoftMail",
      key: "microsoftMail",
    },
    {
      title: "Student ID",
      dataIndex: "studentId",
      key: "studentId",
    },
    {
      title: "Вендинг машины данс",
      dataIndex: "vendingBalance",
      key: "vendingBalance",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Хоолны данс",
      dataIndex: "kitchenBalance",
      key: "kitchenBalance",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "RFID",
      dataIndex: "rfid",
      key: "rfid",
      render: (text, row, index) =>
        row.rfidCardId !== null ? (
          isManager ? (
            <>
              {row.rfidCardId}{" "}
              <Button
                type="link"
                onClick={() => {
                  setVisibleRfid(true);
                  setSelectedStudent(row.studentId);
                }}
              >
                RFID оноох
              </Button>
            </>
          ) : (
            <>{row.rfidCardId}</>
          )
        ) : isManager ? (
          <>
            <Button
              type="link"
              onClick={() => {
                setVisibleRfid(true);
                setSelectedStudent(row.studentId);
              }}
            >
              RFID оноох
            </Button>
          </>
        ) : (
          <>-</>
        ),
    },
    {
      title: "Чиглэл",
      dataIndex: "route",
      key: "route",
      render: (text, row, index) =>
        text !== null ? (
          isAdmin ? (
            <>
              <span>{text.routeDescription}</span>
              <Button
                type="link"
                style={{ color: "green" }}
                onClick={() => setRoute(row.studentId)}
              >
                Чиглэл сонгох
              </Button>
              <Button
                type="link"
                style={{ color: "orange" }}
                onClick={() => removeRoute(row.studentId)}
              >
                Чиглэлээс хасах
              </Button>
            </>
          ) : (
            text.routeDescription
          )
        ) : isAdmin ? (
          <>
            <span style={{ color: "red" }}>Чиглэл сонгоогүй</span>
            <Button
              type="link"
              style={{ color: "green" }}
              onClick={() => setRoute(row.studentId)}
            >
              Чиглэл сонгох
            </Button>
          </>
        ) : (
          <span>-</span>
        ),
    },
  ];
  const handleOkRFID = () => {
    formRfid
      .validateFields()
      .then((values) => {
        API.post("students/rfid/assign", {
          studentId: selectedStudent,
          rfid: values.rfid,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setVisible(false);
              formRfid.resetFields();
              fetchData();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const setRoute = (studentId) => {
    setCurrentStudent(studentId);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        API.post("students/assign-route", {
          routeId: values.routeId,
          studentUserId: currentStudent,
        }).then((result) => {
          if (result.status == 200) {
            if (result.data.success) {
              fetchData();
              setVisible(false);
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              setVisible(false);
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const removeRoute = (studentId) => {
    API.post("students/remove-route", {
      studentId: studentId,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          fetchData();
          setVisible(false);
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          setVisible(false);
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
    });
  };

  return (
    <>
      <PageTitle
        title={classData.displayName}
        description={`${classData.displayName} - нийт сурагчид`}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Чиглэл сонгох"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        okText="Нэмэх"
        cancelText="Болих"
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label={`Чиглэл (${drivers.length > 0 ? drivers.length : 0})`}
            name="routeId"
            rules={[{ required: true, message: "Багш сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {drivers.map((route) => (
                <Option key={route.id} value={route.id}>
                  {route.routeDescription}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="RFID оноох"
        visible={visibleRfid}
        onOk={handleOkRFID}
        onCancel={() => {
          setVisibleRfid(false);
          setSelectedStudent(null);
          formRfid.resetFields();
        }}
        okText="Оноох"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={formRfid}>
          <Form.Item
            label="Картын дугаар"
            name="rfid"
            rules={[{ required: true, message: "Картын дугаар оруулна уу" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedClassItem = connect(mapState, actionCreators)(ClassItem);
export { connectedClassItem as ClassItem };
