import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { connect } from "react-redux";
import {
  adminNav,
  librarianNav,
  teacherNav,
  kitchenNav,
  employeeNav,
  vendingNav,
  shopNav,
  accountantNav,
} from "../../navigations";
import { Icon } from "../../components";
import {
  NavLink,
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
// import i18n from "i18next";

const { SubMenu } = Menu;

function VerticalMenu({ user, collapsed = false }) {
  const userData = user.user;
  let location = useLocation();
  const [mainNavs, setMainNavs] = useState([]);

  const getNavLinkClass = (path) => {
    return location.pathname === path ? "mm-active" : "";
  };

  useEffect(() => {
    let navs = [];
    const loggingIn = user.loggingIn;
    if (loggingIn) {
      const userRoles = userData.userRoles;
      userRoles.map((role) => {
        switch (role) {
          case "ROLE_LIBRARIAN":
            navs.push(...librarianNav);
            return;
          case "ROLE_EMPLOYEE":
            navs.push(...employeeNav);
            return;
          case "ROLE_ADMIN":
            navs.push(...adminNav);
            return;
          case "ROLE_TEACHER":
            navs.push(...teacherNav);
            return;
          case "ROLE_KITCHEN":
            navs.push(...kitchenNav);
            return;
          case "ROLE_VENDING":
            navs.push(...vendingNav);
            return;
          case "ROLE_SHOP":
            navs.push(...shopNav);
            return;
          case "ROLE_ACCOUNTING":
            navs.push(...accountantNav);
            return;

          default:
            return;
        }
      });
      setMainNavs(
        Array.from(new Set(navs.map((item) => item.path))).map((path) => {
          return {
            component: navs.find((s) => s.path === path).component,
            icon: navs.find((s) => s.path === path).icon,
            label: navs.find((s) => s.path === path).label,
            path: navs.find((s) => s.path === path).path,
            showHeader: navs.find((s) => s.path === path).showHeader,
            childrens: navs.find((s) => s.path === path).childrens,
          };
        })
      );
    }
  }, [userData]);

  return (
    <div>
      <Menu mode="inline">
        {mainNavs.map((nav) => {
          let childs = [];
          if (nav.childrens !== undefined && nav.childrens.length > 0) {
            const subMenus = nav.childrens;
            subMenus.map((item) => {
              childs.push(
                <Menu.Item key={item.path}>
                  <NavLink to={item.path}>
                    <div className="d-inline-block icons-sm mr-1">
                      <Icon name={item.icon} />
                    </div>
                    <span>{item.label}</span>
                  </NavLink>
                </Menu.Item>
              );
            });
            return (
              <SubMenu
                key={nav.path}
                icon={<Icon name={nav.icon} />}
                className={getNavLinkClass(nav.path)}
                title={nav.label}
              >
                {childs}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={nav.path} className={getNavLinkClass(nav.path)}>
                <NavLink to={nav.path}>
                  <div className="d-inline-block icons-sm mr-1">
                    <Icon name={nav.icon} />
                  </div>
                  <span>{nav.label}</span>
                </NavLink>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </div>
  );
}

const mapState = function (state) {
  const { authentication } = state;
  return {
    user: authentication,
  };
};

const connectedVerticalMenu = connect(mapState)(VerticalMenu);
export { connectedVerticalMenu as VerticalMenu };
