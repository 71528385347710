import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Table, Button, DatePicker, Form, Tag, Spin, Space } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { RangePicker } = DatePicker;

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function DriverRouteHistory({ userData }) {
  const [form] = Form.useForm();

  const [filterDates, setFilterDates] = useState({
    startDate: moment().format("YYYY/MM/DD"),
    endDate: moment().format("YYYY/MM/DD"),
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(filterDates.startDate, filterDates.endDate);
  }, []);

  const fetchData = (startDate, endDate) => {
    setFilterDates({
      startDate: startDate,
      endDate: endDate,
    });

    API.get(`driver/history-list`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setData(result.data.content);
        }
        setLoading(false);
      }
    });
  };

  function onDateFilterChange(dates, dateStrings) {
    fetchData(dates[0].format("YYYY/MM/DD"), dates[1].format("YYYY/MM/DD"));
  }

  const onFinish = (values) => {
    setLoading(true);
    fetchData(filterDates.startDate, filterDates.endDate);
    setLoading(false);
  };

  return (
    <>
      <PageTitle
        title="Жолоочийн зогсоолын түүх"
        description="Буулгаж суулгасан хүүхдүүдийн нэрс"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                      >
                        <Form.Item
                          name="filter-dates"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <RangePicker
                            defaultValue={[moment(), moment()]}
                            ranges={{
                              Өнөөдөр: [moment(), moment()],
                              "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
                            }}
                            onChange={onDateFilterChange}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        scroll={{ y: 600, x: 960 }}
                        columns={[
                          {
                            title: "№",
                            dataIndex: "id",
                            key: "id",
                            width: 70,
                            fixed: "left",
                            render: (text, row, index) => <>{index + 1}</>,
                          },
                          {
                            title: "Жолооч",
                            dataIndex: "driver",
                            key: "driver",
                            fixed: "left",
                            width: 100,
                          },

                          {
                            title: "Огноо",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            fixed: "left",
                            width: 200,
                            render: (text, row, index) => (
                              <>{moment(text).format("YYYY/MM/DD HH:mm")}</>
                            ),
                          },
                          {
                            title: "Үйлдэл",
                            dataIndex: "action",
                            key: "action",
                            fixed: "left",
                            width: 100,
                            render: (text, row, index) => (
                              <>
                                {text === "FINISH" ? (
                                  <Tag color="orange">Буусан</Tag>
                                ) : (
                                  <Tag color="blue">Суусан</Tag>
                                )}
                              </>
                            ),
                          },
                          {
                            title: "Сурагчийн нэр",
                            dataIndex: "students",
                            key: "students",
                            //width: 100,
                            render: (text, row, index) => (
                              <>
                                {row.students.length > 0 ? (
                                  <tr>
                                    {row.students.map((student, studIndex) => (
                                      <Space size={"small"} direction="vertical" align="center">
                                        <Tag color="green" key={student}>
                                          {student}
                                        </Tag>{" "}
                                        {row.students.length - 1 !== studIndex ? "" : ""}
                                      </Space>
                                    ))}
                                  </tr>
                                ) : (
                                  <p>-</p>
                                )}
                              </>
                            ),
                          },
                        ]}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedDriverRouteHistory = connect(mapState, actionCreators)(DriverRouteHistory);
export { connectedDriverRouteHistory as DriverRouteHistory };
