import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;

function Kitchen() {
  const [data, setData] = useState({
    High: 0,
    Middle: 0,
    Elementary: 0,
  });
  const [tableData, setTableData] = useState({
    Elementary: [],
    Middle: [],
    High: [],
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [teacherForm] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [teacherData, setTeacherData] = useState([]);
  const [time, setTime] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const [teacherTotal, setTeacherTotal] = useState(0);

  useEffect(() => {
    fetchData();
    getTeacherData();
  }, []);

  const getTeacherData = () => {
    API.get("kitchen/teacher/assigned-list", {
      params: {
        startDate: moment().startOf("date").format("YYYY/MM/DD HH:mm"),
        endDate: moment()
          .startOf("date")
          .add(23, "hours")
          .format("YYYY/MM/DD HH:mm"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setTeacherData(result.data.content);
          let total = 0;
          result.data.content.map((item) => (total += item.count));
          setTeacherTotal(total);
        }
      }
      setLoading(false);
      setButtonLoading(false);
    });
  };

  const fetchData = () => {
    setLoading(true);
    setButtonLoading(true);
    API.get("kitchen/supply-list", {
      params: {
        startDate: moment().startOf("date").format("YYYY/MM/DD HH:mm"),
        endDate: moment()
          .startOf("date")
          .add(23, "hours")
          .format("YYYY/MM/DD HH:mm"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setTime(moment().format("YYYY-MM-DD HH:mm"));
          let elementaryCount = 0;
          let mediumCount = 0;
          let highCount = 0;
          let elementary = result.data.content.Elementary;
          let middle = result.data.content.Middle;
          let high = result.data.content.High;
          elementary.map((item) => (elementaryCount += item.count));
          middle.map((item) => (mediumCount += item.count));
          high.map((item) => (highCount += item.count));
          setData({
            High: highCount,
            Middle: mediumCount,
            Elementary: elementaryCount,
          });
          setTableData(result.data.content);
        }
      }
      setLoading(false);
      setButtonLoading(false);
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: "Ангийн нэр",
      dataIndex: "className",
      key: "className",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.className.localeCompare(b.className),
    },
    {
      title: "Сурагчийн тоо",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (text, row, index) => (
        <span style={{ color: "green" }}>{text}</span>
      ),
    },
  ];

  const teacherColumns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: "Багшийн нэр",
      dataIndex: "teacherName",
      key: "teacherName",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a.teacher.displayName.localeCompare(b.teacher.displayName),
      render: (text, row, index) => row.teacher.displayName,
    },
    {
      title: "Тоо",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (text, row, index) => (
        <span style={{ color: "green" }}>{text}</span>
      ),
    },
  ];

  const onFinish = (values) => {
    setSaveLoading(true);

    API.post("kitchen/register-served-food", {
      date: moment(values.date).format("YYYY-MM-DD"),
      crepe: values.crepe,
      entree: values.entree,
      sideDish: values.sideDish,
      salade: values.salade,
      drink: values.drink,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          form.resetFields();
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setSaveLoading(false);
    });
  };

  const onFinishTeacher = (values) => {
    API.post("kitchen/teacher/assign-food-count", {
      rfidCardNumber: values.rfid,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          getTeacherData();
          teacherForm.resetFields();
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          teacherForm.resetFields();
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setSaveLoading(false);
    });
  };

  return (
    <>
      <PageTitle
        title="Хоолны тоо"
        description="Хоолны тоо"
        right={
          <>
            <span style={{ color: "#fff", marginRight: 20 }}>
              Шинэчлсэн огноо: {time}
            </span>
            <Button
              loading={buttonLoading}
              onClick={() => {
                fetchData();
                getTeacherData();
              }}
            >
              Шинэчлэх
            </Button>
          </>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    layout="vertical"
                    style={{ marginBottom: 10 }}
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                      date: moment(),
                    }}
                  >
                    <Row gutter={16} align="bottom">
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            { required: true, message: "Өдөр сонгоно уу!" },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="crepe"
                          label="1-р хоол"
                          rules={[
                            { required: true, message: "1-р хоол сонгоно уу!" },
                          ]}
                        >
                          <Select allowClear>
                            <Option value={"Гурилтай шөл"}>Гурилтай шөл</Option>
                            <Option value={"Ногоотой шөл"}>Ногоотой шөл</Option>
                            <Option value={"Будаатай шөл"}>Будаатай шөл</Option>
                            <Option value={"Жунзтай шөл"}>Жунзтай шөл</Option>
                            <Option value={"Броклины зутан шөл"}>
                              Броклины зутан шөл
                            </Option>
                            <Option value={"Шар хулууны зутан"}>
                              Шар хулууны зутан
                            </Option>
                            <Option value={"Борш"}>Борш</Option>
                            <Option value={"Хуйцай"}>Хуйцай</Option>
                            <Option value={"Бантан"}>Бантан</Option>
                            <Option value={"Сүүтэй будаа"}>Сүүтэй будаа</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="entree"
                          label="2-р хоол"
                          rules={[
                            { required: true, message: "2-р хоол сонгоно уу!" },
                          ]}
                        >
                          <Select allowClear>
                            <Option value={"Төмсний хучмал"}>
                              Төмсний хучмал
                            </Option>
                            <Option value={"Ногоотой хуурга"}>
                              Ногоотой хуурга
                            </Option>
                            <Option value={"Пүнтүзтэй хуурга"}>
                              Пүнтүзтэй хуурга
                            </Option>
                            <Option value={"Будаатай хуурга"}>
                              Будаатай хуурга
                            </Option>
                            <Option value={"Тефтель"}>Тефтель</Option>
                            <Option value={"Шпагети"}>Шпагети</Option>
                            <Option value={"Мантуун бууз"}>Мантуун бууз</Option>
                            <Option value={"Пирошки"}>Пирошки</Option>
                            <Option value={"Бресс"}>Бресс</Option>
                            <Option value={"Цуйван"}>Цуйван</Option>
                            <Option value={"Гоймонтой хуурга"}>
                              Гоймонтой хуурга
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}></Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="sideDish"
                          label="Хачир"
                          rules={[
                            { required: true, message: "Хачир сонгоно уу!" },
                          ]}
                        >
                          <Select allowClear>
                            <Option value={"Алим, Лийр"}>Алим, Лийр</Option>
                            <Option value={"Боорцог"}>Боорцог</Option>
                            <Option value={"Булочка"}>Булочка</Option>
                            <Option value={"Мантуу /цэцгэн/"}>
                              Мантуу /цэцгэн/
                            </Option>
                            <Option value={"Жигнэмэг"}>Жигнэмэг</Option>
                            <Option value={"Талх"}>Талх</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="salade"
                          label="Салат"
                          rules={[
                            { required: true, message: "Салат сонгоно уу!" },
                          ]}
                        >
                          <Select allowClear>
                            <Option value={"Жимсний салат"}>
                              Жимсний салат
                            </Option>
                            <Option value={"Броклины салат"}>
                              Броклины салат
                            </Option>
                            <Option value={"Шинэ ногооны салат"}>
                              Шинэ ногооны салат
                            </Option>
                            <Option value={"Луувангийн салат"}>
                              Луувангийн салат
                            </Option>
                            <Option value={"Байцааны салат"}>
                              Байцааны салат
                            </Option>
                            <Option value={"Эрүүл мэнд салат"}>
                              Эрүүл мэнд салат
                            </Option>
                            <Option value={"Огурцы, помиодор"}>
                              Огурцы, помиодор
                            </Option>
                            <Option value={"Хүрэн манжингийн салат"}>
                              Хүрэн манжингийн салат
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="drink"
                          label="Уух юм"
                          rules={[
                            { required: true, message: "Уух юм сонгоно уу!" },
                          ]}
                        >
                          <Select allowClear>
                            <Option value={"Аньсны шүүс"}>Аньсны шүүс</Option>
                            <Option value={"Нэрсний шүүс"}>Нэрсний шүүс</Option>
                            <Option value={"Хярам"}>Хярам</Option>
                            <Option value={"Ногоон цай"}>Ногоон цай</Option>
                            <Option value={"Тараг"}>Тараг</Option>
                            <Option value={"Хөөрүүлсэн сүү"}>
                              Хөөрүүлсэн сүү
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={saveLoading}
                          >
                            Оруулах
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    layout="vertical"
                    style={{ marginBottom: 10 }}
                    form={teacherForm}
                    onFinish={onFinishTeacher}
                  >
                    <Row gutter={16} align="bottom">
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="rfid"
                          label="Багшийн RFID"
                          rules={[
                            { required: true, message: "RFID оруулна уу!" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={6}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={saveLoading}
                          >
                            Хадгалах
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="header-title mb-4">Бага анги</h5>
                  <div className="kitchen-number">{data.Elementary}ш</div>
                  <Table
                    className="table table-centered table-hover mb-0"
                    columns={columns}
                    dataSource={tableData.Elementary}
                    pagination={false}
                    rowKey="id"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="header-title mb-4">Дунд анги</h5>
                  <div className="kitchen-number">{data.Middle}ш</div>
                  <Table
                    className="table table-centered table-hover mb-0"
                    columns={columns}
                    dataSource={tableData.Middle}
                    pagination={false}
                    rowKey="id"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="header-title mb-4">Ахлах анги</h5>
                  <div className="kitchen-number">{data.High}ш</div>
                  <Table
                    className="table table-centered table-hover mb-0"
                    columns={columns}
                    dataSource={tableData.High}
                    pagination={false}
                    rowKey="id"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="header-title mb-4">Багш</h5>
                  <div className="kitchen-number">{teacherTotal}ш</div>
                  <Table
                    className="table table-centered table-hover mb-0"
                    columns={teacherColumns}
                    dataSource={teacherData}
                    pagination={false}
                    rowKey="id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedKitchen = connect(mapState, actionCreators)(Kitchen);
export { connectedKitchen as Kitchen };
