import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function User({ user }) {
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("-");

  let { userID } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.get(`users/?id=${userID}`, {}).then((result) => {
      if (result.status == 200) {
        setName(result.data.content.displayName);
        form.setFieldsValue({
          lastName: result.data.content.lastName,
          firstName: result.data.content.firstName,
          registerNumber: result.data.content.registerNumber,
          role: result.data.content.userRoles,
          email: result.data.content.email,
        });
        setLoading(false);
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (values) => {
    console.log("Finish:", values);

    setLoading(true);

    API.post(`users/edit`, {
      id: userID,
      roles: values.role,
      email: values.email,
      registerNumber: values.registerNumber,
      firstName: values.firstName,
      lastName: values.lastName,
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }

        setLoading(false);
      }
    });
  };

  const onChangePassword = (values) => {
    setLoading(true);
    API.put(`users/change-password`, {
      id: userID,
      newPassword: values.password,
      isDashboard: true,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          changePasswordForm.resetFields();
          notification["success"]({
            message: result.data.message,
            description: "Хэрэглэгчийн нууц үг амжилттай солигдлоо",
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setLoading(false);
    });
  };

  return (
    <>
      <PageTitle title={name} description={"Хэрэглэгчийн мэдээлэл засах"} />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form {...formItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                          name="cover"
                          label="Профайл зураг"
                          valuePropName="fileList"
                          getValueFromEvent={normCover}
                        >
                          <Upload
                            customRequest={dummyRequest}
                            listType="picture"
                          >
                            <Button>
                              <UploadOutlined /> сонгох
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          label="Овог"
                          name="lastName"
                          rules={[
                            { required: true, message: "Нэр оруулна уу!" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Нэр"
                          name="firstName"
                          rules={[
                            { required: true, message: "Нэр оруулна уу!" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Системийн эрх"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Системийн эрх сонгоно уу!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Сонгоно уу"
                            showSearch
                            allowClear
                            mode="multiple"
                            style={{ width: "100%" }}
                          >
                            <Option value="ROLE_ADMIN">Админ</Option>
                            <Option value="ROLE_DRIVER">Жолооч</Option>
                            <Option value="ROLE_LIBRARIAN">Номын санч</Option>
                            <Option value="ROLE_TEACHER">Багш</Option>
                            <Option value="ROLE_KITCHEN">Тогооч</Option>
                            <Option value="ROLE_EMPLOYEE">Менежер</Option>
                            <Option value="ROLE_VENDING">Вендинг</Option>
                            <Option value="ROLE_SHOP">Дэлгүүр</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Имэйл хаяг"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Имэйл хаяг оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Регистрийн дугаар"
                          name="registerNumber"
                          rules={[
                            {
                              required: true,
                              message: "Регистрийн дугаар оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Хадгалах
                          </Button>
                        </Form.Item>
                      </Form>
                      <Row>
                        <Col span={14} offset={6}>
                          <h5>Нууц үг солих</h5>
                        </Col>
                      </Row>

                      <Form
                        {...formItemLayout}
                        name="basic"
                        initialValues={{ remember: true }}
                        form={changePasswordForm}
                        onFinish={onChangePassword}
                      >
                        <Form.Item
                          label="Нууц үг"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Нууц үг оруулна уу!",
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Нууц үг солих
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedUser = connect(mapState, actionCreators)(User);
export { connectedUser as User };
