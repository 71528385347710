import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { ReactReader, EpubView } from "react-reader";
import ReactAudioPlayer from "react-audio-player";
import Zoom from "react-medium-image-zoom";
import appInfo from "../../app-info";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function ELibrary() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleBookRead, setVisibleBookRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentBook, setCurrentBook] = useState("");
  const [currentExt, setExt] = useState("");
  const [currentCover, setCurrentCover] = useState("");
  const [currentBookTitle, setCurrentBookTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        console.log(result.data.content);
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: currentPage - 1, size: showNum, type: "EBOOK" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, type: "EBOOK" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isPDF =
      file.type === "application/pdf" ||
      // file.type === "application/epub+zip" ||
      file.type === "audio/mpeg";

    if (!isPDF) {
      message.error("Зөвхөн PDF, MP3 файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return (
          <Zoom>
            <img src={appInfo.apiURL + text} width={50} />
          </Zoom>
        );
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/book/${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: "Ангилал",
      dataIndex: "category",
      key: "category",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/category/${record.category.id}`,
            state: { categoryName: record.category.categoryName },
          }}
        >
          {record.category.categoryName}
        </Link>
      ),
    },
    {
      title: "Үүсгэсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "ISBN",
      dataIndex: "isbn",
      key: "isbn",
    },
    {
      title: "Хэвлэсэн огноо",
      dataIndex: "publicationDate",
      key: "publicationDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="View"
              onClick={() => {
                setCurrentBookTitle(record.name);
                setCurrentBook(record.filePath);
                setExt(record.extension);
                setCurrentCover(record.coverPhoto);
                setVisibleBookRead(true);
                setBookLoading(true);
              }}
            >
              <i className="mdi mdi-eye"></i>
            </button>
            <Link
              to={`/book/${record.id}`}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-pencil"></i>
            </Link>

            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => confirm(record.id)}
              onCancel={cancel}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const confirm = (bookId) => {
    deleteBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteBook = (bookId) => {
    API.delete(`library/books/delete/?bookId=${bookId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setUploading(true);
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerBook = (values) => {
    API.post("library/books/add", {
      name: values.name,
      isbn: values.isbn,
      authorName: values.authorName,
      publishedPlace: values.publishedPlace,
      publicationDate: moment(values.publicationDate).format("YYYY-MM-DD"),
      schoolRegisteredDate: moment(values.schoolRegisteredDate).format(
        "YYYY-MM-DD"
      ),
      note: values.note,
      bookCondition: "NEW",
      categoryId: values.category,
      bookType: "EBOOK",
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          const response = result.data.content;
          var formData = new FormData();
          formData.set("file", values.book[0].originFileObj);
          formData.set("id", response.id);
          formData.set("type", "FILE");
          formData.set("entity", "LIBRARY");

          API.post("file/upload", formData).then((resultFile) => {
            if (resultFile.status === 200) {
              formData.set("file", values.cover[0].originFileObj);
              formData.set("type", "COVER_PHOTO");
              API.post("file/upload", formData).then((resultCover) => {
                if (resultCover.status === 200) {
                  form.resetFields();
                  fetchData(currentPage);
                  setVisible(false);
                }
              });
            }
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setUploading(false);
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const renderFile = (currentExt) => {
    switch (currentExt) {
      case "epub":
        return (
          <div
            style={{
              position: "relative",
              height: "700px",
              textAlign: "center",
            }}
          >
            {" "}
            <ReactReader
              loadingView={<Spin spinning={true}></Spin>}
              url={appInfo.apiURL + currentBook}
              locationChanged={(epubcifi) => console.log(epubcifi)}
            />
          </div>
        );
        break;

      case "pdf":
        return (
          <div style={{ position: "relative", height: "700px" }}>
            <Document
              file={appInfo.apiURL + currentBook}
              onLoadSuccess={({ numPages }) => {
                setBookLoading(false);
                setNumPages(numPages);
              }}
              loading={<Spin />}
            >
              <Page loading={<Spin />} pageNumber={page} />
              {page > 1 ? (
                <Button type="primary" onClick={() => setPage(page - 1)}>
                  Өмнөх хуудас
                </Button>
              ) : null}
              {page}/{numPages}
              {page < numPages ? (
                <Button type="primary" onClick={() => setPage(page + 1)}>
                  Дараагын хуудас
                </Button>
              ) : null}
            </Document>
          </div>
        );
        break;
      case "mp3":
        return (
          <div className="book-listen">
            <img src={appInfo.apiURL + currentCover} />
            <ReactAudioPlayer src={appInfo.apiURL + currentBook} controls />
          </div>
        );
        break;
      default:
        return "Файл олдсонгүй";
        break;
    }
  };

  return (
    <>
      <PageTitle
        title="Цахим номын жагсаалт"
        description="Системийн нийт номын жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Ном нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="name">Номын нэрээр</Option>
                            <Option value="authorName">Номын зохиолчоор</Option>
                            <Option value="isbn">ISBN</Option>
                            <Option value="publicationDate">
                              Хэвлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Номын нэр">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={currentBookTitle}
        visible={visibleBookRead}
        onCancel={() => setVisibleBookRead(false)}
        destroyOnClose={true}
        footer={null}
        width={720}
      >
        {renderFile(currentExt)}
      </Modal>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={uploading}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ schoolRegisteredDate: moment() }}
        >
          <Form.Item
            label="Номын нэр"
            name="name"
            rules={[{ required: true, message: "Номын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номын ангилал"
            name="category"
            rules={[{ required: true, message: "Номын ангилал сонгоно уу!" }]}
          >
            <TreeSelect
              treeData={categories}
              showSearch
              // style={{ width: "100%" }}
              placeholder="Сонгоно уу"
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              allowClear
              treeDefaultExpandAll
              treeNodeLabelProp="categoryName"
              multiple={false}
              showCheckedStrategy="SHOW_PARENT"
            />
          </Form.Item>
          <Form.Item label="ISBN" name="isbn">
            <Input />
          </Form.Item>
          <Form.Item label="Зохиолчын нэр" name="authorName">
            <Input />
          </Form.Item>
          <Form.Item label="Хэвлэсэн газар" name="publishedPlace">
            <Input />
          </Form.Item>
          <Form.Item label="Хэвлэсэн огноо" name="publicationDate">
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Бүртгэгдсэн огноо"
            name="schoolRegisteredDate"
            rules={[{ required: true, message: "Огноо оруулна уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="book"
            label="Файл"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Ном сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="cover"
            label="Номын хавтас"
            valuePropName="fileList"
            getValueFromEvent={normCover}
            rules={[{ required: true, message: "Номын хавтас сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Тэмдэглэл" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedELibrary = connect(mapState, actionCreators)(ELibrary);
export { connectedELibrary as ELibrary };
