import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import {
  notification,
  Modal,
  Button,
  Form,
  DatePicker,
  Select,
  Upload,
  InputNumber,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";
import appInfo from "../../app-info";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function Book() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  let history = useHistory();
  const [data, setData] = useState({ bookType: "EBOOK" });
  const [currentCover, setCurrentCover] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [coverVisible, setCoverVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  let { bookId } = useParams();

  useEffect(() => {
    getCategories();
    fetchData();
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = () => {
    setLoading(true);
    API.get(`library/books/${bookId}`, {}).then((result) => {
      if (result.status == 200) {
        setData(result.data.content);

        form.setFieldsValue({
          name: result.data.content.name,
          category:
            result.data.content.category !== null
              ? result.data.content.category.id
              : "",
          authorName: result.data.content.authorName,
          isbn: result.data.content.isbn,
          bookCount: result.data.content.bookCount,
          newBookCount: result.data.content.newBookCount,
          usedBookCount: result.data.content.usedBookCount,
          availableBookCount: result.data.content.availableBookCount,
          publishedPlace: result.data.content.publishedPlace,
          publicationDate: moment(result.data.content.publicationDate),
          schoolRegisteredDate: moment(
            result.data.content.schoolRegisteredDate
          ),
          note: result.data.content.note,
          book: [
            {
              url: appInfo.apiURL + result.data.content.filePath,
              name: result.data.content.name,
              type: "application/*",
              uid: "book",
            },
          ],
          cover: [
            {
              url: appInfo.apiURL + result.data.content.coverPhoto,
              name: result.data.content.name,
              type: "image/*",
              uid: "cover",
            },
          ],
        });
        setLoading(false);
      }
    });
  };

  const deleteBook = () => {
    const bookId = data ? data.id : "";
    API.delete(`library/books/delete/?bookId=${bookId}`).then((result) => {
      if (result.status == 200) {
        history.push("/library");

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isPDF = file.type === "application/pdf";

    if (!isPDF) {
      message.error("Зөвхөн PDF файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handlePreview = (file) => {
    console.log(file);
  };

  const handlePreviewCover = (file) => {
    setCoverVisible(true);
    setCurrentCover(file.url);
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (values) => {
    setUploading(true);
    API.put("library/books/edit", {
      id: data.id,
      name: values.name,
      isbn: values.isbn,
      authorName: values.authorName,
      publishedPlace: values.publishedPlace,
      bookCount: values.bookCount,
      newBookCount: values.newBookCount,
      usedBookCount: values.usedBookCount,
      availableBookCount: values.availableBookCount,
      publicationDate: values.publicationDate,
      schoolRegisteredDate: values.schoolRegisteredDate,
      note: values.note,
      categoryId: values.category,
    }).then(async (result) => {
      if (result.data.success) {
        var formData = new FormData();
        if (data.bookType === "EBOOK") {
          if (values.book && values.book[0].originFileObj) {
            formData.set("file", values.book[0].originFileObj);
            formData.set("id", data.id);
            formData.set("entity", "LIBRARY");
            formData.set("type", "FILE");
            await API.post("file/upload", formData);
          }

          if (values.cover && values.cover[0].originFileObj) {
            formData.set("id", data.id);
            formData.set("entity", "LIBRARY");
            formData.set("file", values.cover[0].originFileObj);
            formData.set("type", "COVER_PHOTO");
            await API.post("file/upload", formData);
          }
        }

        form.resetFields();
        fetchData();
        setVisible(false);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      } else {
        notification["error"]({
          message: "Амжилтгүй",
          description: result.data.message,
        });
      }
      setUploading(false);
    });
  };

  const changeNewBook = (num) => {
    let used = form.getFieldValue("usedBookCount");
    form.setFieldsValue({ bookCount: used + num });
    return num;
  };

  const changeUsedBook = (num) => {
    let newCount = form.getFieldValue("newBookCount");
    form.setFieldsValue({ bookCount: newCount + num });
    return num;
  };

  return (
    <>
      <PageTitle
        title={data ? data.name : ""}
        description={data ? data.name : ""}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form {...formItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                          label="Номын нэр"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Номын нэр оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Номын ангилал"
                          name="category"
                          rules={[
                            {
                              required: true,
                              message: "Номын ангилал сонгоно уу!",
                            },
                          ]}
                        >
                          <TreeSelect
                            treeData={categories}
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Сонгоно уу"
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            allowClear
                            treeDefaultExpandAll
                            treeNodeLabelProp="categoryName"
                            multiple={false}
                            showCheckedStrategy="SHOW_PARENT"
                          />
                        </Form.Item>
                        <Form.Item
                          label="ISBN"
                          name="isbn"
                          rules={[
                            { required: true, message: "ISBN оруулна уу!" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Зохиолчын нэр"
                          name="authorName"
                          rules={[
                            {
                              required: true,
                              message: "Зохиолчын нэр оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Хэвлэсэн газар"
                          name="publishedPlace"
                          rules={[
                            {
                              required: true,
                              message: "Хэвлэсэн газар оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        {data.bookType === "PAPER" ? (
                          <>
                            <Form.Item label="Номын тоо" name="bookCount">
                              <InputNumber disabled />
                            </Form.Item>
                            <Form.Item
                              label="Шинэ номын тоо"
                              name="newBookCount"
                              getValueFromEvent={changeNewBook}
                              rules={[
                                {
                                  required: true,
                                  message: "Шинэ номын тоо оруулна уу!",
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                            <Form.Item
                              label="Ашигласан номын тоо"
                              name="usedBookCount"
                              getValueFromEvent={changeUsedBook}
                              rules={[
                                {
                                  required: true,
                                  message: "Ашигласан номын тоо оруулна уу!",
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                          </>
                        ) : null}
                        <Form.Item
                          label="Хэвлэсэн огноо"
                          name="publicationDate"
                          rules={[
                            {
                              required: true,
                              message: "Хэвлэсэн огноо оруулна уу!",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={(d) =>
                              !d || d.isAfter(moment().subtract(1, "days"))
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Бүртгэгдсэн огноо"
                          name="schoolRegisteredDate"
                          rules={[
                            { required: true, message: "Огноо оруулна уу!" },
                          ]}
                        >
                          <DatePicker
                            disabledDate={(d) =>
                              !d || d.isAfter(moment().subtract(1, "days"))
                            }
                          />
                        </Form.Item>
                        {data.bookType === "EBOOK" ? (
                          <Form.Item
                            name="book"
                            label="Файл"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              { required: true, message: "Ном сонгоно уу!" },
                            ]}
                          >
                            <Upload
                              customRequest={dummyRequest}
                              listType="picture"
                              onPreview={handlePreview}
                            >
                              <Button>
                                <UploadOutlined /> сонгох
                              </Button>
                            </Upload>
                          </Form.Item>
                        ) : null}
                        <Form.Item
                          name="cover"
                          label="Номын хавтас"
                          valuePropName="fileList"
                          getValueFromEvent={normCover}
                          rules={[
                            {
                              required: true,
                              message: "Номын хавтас сонгоно уу!",
                            },
                          ]}
                        >
                          <Upload
                            customRequest={dummyRequest}
                            listType="picture"
                            onPreview={handlePreviewCover}
                          >
                            <Button>
                              <UploadOutlined /> сонгох
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item label="Тэмдэглэл" name="note">
                          <Input.TextArea />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={uploading}
                          >
                            Хадгалах
                          </Button>
                          <Button
                            danger
                            style={{ margin: "0 8px" }}
                            onClick={() => setVisible(true)}
                          >
                            Устгах
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Номын хавтас"
        visible={coverVisible}
        onCancel={() => setCoverVisible(false)}
        footer={null}
        width={720}
      >
        <img src={currentCover} className="book-cover" />
      </Modal>
      <Modal
        title="Устгах"
        visible={visible}
        onOk={() => deleteBook()}
        onCancel={() => setVisible(false)}
        okText="Устгах"
        cancelText="Болих"
      >
        <p>"{data ? data.name : ""}" устгахдаа итгэлтэй байна уу?</p>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedBook = connect(mapState, actionCreators)(Book);
export { connectedBook as Book };
