import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import {
  notification,
  Space,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  TreeSelect,
} from "antd";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

function HomeWorkModal({ visible, modalData, onHide, fetchData }) {
  const [form] = Form.useForm();
  const [formAccount] = Form.useForm();

  const [data, setData] = useState(modalData);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(visible);

  let { studentId } = useParams();

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  useEffect(() => {
    setData(modalData);

    formAccount.setFieldsValue({
      date: modalData.date,
      subjects: modalData.subjects,
    });
  }, [modalData]);

  const handleOk = () => {
    formAccount
      .validateFields()
      .then((values) => {
        API.post("homework/add", {
          studentId: studentId,
          homeworkData: JSON.stringify(values.subjects),
          sentDate: moment(values.date).format("YYYY-MM-DD"),
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              onHide();
              fetchData();
              formAccount.resetFields();
              form.submit();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Даалгавар харах"
      visible={showModal}
      onOk={handleOk}
      onCancel={() => {
        setShowModal(false);
        onHide();
        formAccount.resetFields();
      }}
      okText="Хадгалах"
      cancelText="Болих"
      width={900}
    >
      <Form {...formItemLayout} form={formAccount}>
        <Form.Item
          label="Өдөр"
          name="date"
          rules={[{ required: true, message: "Өдөр сонгоно уу" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.List name="subjects">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "subjectName"]}
                    label="Хичээлийн нэр"
                    rules={[
                      { required: true, message: "Хичээлийн нэр сонгоно уу" },
                    ]}
                  >
                    <Select style={{ width: 200 }} allowClear>
                      <Option value="Математик">Математик</Option>
                      <Option value="Монгол хэл">Монгол хэл</Option>
                      <Option value="Уран зохиол">Уран зохиол</Option>
                      <Option value="Монгол бичиг">Монгол бичиг</Option>
                      <Option value="Түүх">Түүх</Option>
                      <Option value="Биеийн тамир">Биеийн тамир</Option>
                      <Option value="Нийгэм">Нийгэм</Option>
                      <Option value="Дуу хөгжим">Дуу хөгжим</Option>
                      <Option value="Физик">Физик</Option>
                      <Option value="Хими ">Хими </Option>
                      <Option value="Биологи">Биологи</Option>
                      <Option value="Газарзүй">Газарзүй</Option>
                      <Option value="Мэдээллийн технологи">
                        Мэдээллийн технологи
                      </Option>
                      <Option value="График дизайн">График дизайн</Option>
                      <Option value="Англи хэл">Англи хэл</Option>
                      <Option value="Математик сонгон">Математик сонгон</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "status"]}
                    label="Төлөв"
                    rules={[
                      {
                        required: true,
                        message: "Хичээлийн төлөв сонгоно уу",
                      },
                    ]}
                  >
                    <Select style={{ width: 200 }} allowClear>
                      <Option value="yes">Илгээсэн</Option>
                      <Option value="no">Илгээгээгүй</Option>
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Хичээл нэмэх
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default HomeWorkModal;
