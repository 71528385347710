import { API } from "../_helpers/service";

export const libService = {
  getLibService,
};

function getLibService() {
  return API.get("lib/all").then((result) => {
    return result.data;
  });
}
