import { Card, Col, Row, Statistic } from "antd";
import React from "react";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";

function Statistics() {
  return (
    <>
      <PageTitle title="Статистикууд" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="site-statistic-demo-card">
            <Row gutter={16} className="pb-2">
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Нийт сурагч"
                    value={1989}
                    valueStyle={{
                      color: "#3D82ED",
                    }}
                    suffix="ш"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Нийт багш"
                    value={195}
                    valueStyle={{
                      color: "#ac5d9e",
                    }}
                    suffix="ш"
                  />
                </Card>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Нийт бэлтгэл ангийн бүртгэлийн үнийн дүн"
                    value={37503750}
                    precision={2}
                    valueStyle={{
                      color: "#3f8600",
                    }}
                    suffix="₮"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Нийт гүйлгээний үнийн дүн"
                    value={1911217625}
                    precision={2}
                    valueStyle={{
                      color: "#fa8072",
                    }}
                    suffix="₮"
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTransactions = connect(mapState, actionCreators)(Statistics);
export { connectedTransactions as Statistics };
