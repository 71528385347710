import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import { MainHeader, PrivateRoute, VerticalMenu } from "../";
import {
  adminRoutes,
  librarianRoutes,
  kitchenRoutes,
  teacherRoutes,
  initialRoute,
  employeeRoutes,
  vendingRoutes,
  shopRoutes,
  accountantRoutes,
} from "../../routes";

const { Content, Footer, Sider } = Layout;
const RouteContainer = ({ user, ...rest }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    let navs = [];
    navs.push(...initialRoute);

    if (user.loggingIn) {
      const userRoles = user.user.userRoles;
      userRoles.map((role) => {
        switch (role) {
          case "ROLE_LIBRARIAN":
            navs.push(...librarianRoutes);
            return;
          case "ROLE_ADMIN":
            navs.push(...adminRoutes);
            return;
          case "ROLE_EMPLOYEE":
            navs.push(...employeeRoutes);
            return;
          case "ROLE_KITCHEN":
            navs.push(...kitchenRoutes);
            return;
          case "ROLE_TEACHER":
            navs.push(...teacherRoutes);
            return;
          case "ROLE_VENDING":
            navs.push(...vendingRoutes);
            return;
          case "ROLE_SHOP":
            console.log("Adding shop route");
            navs.push(...shopRoutes);
            return;
          case "ROLE_ACCOUNTING":
            navs.push(...accountantRoutes);
            return;

          default:
            return;
        }
      });
    }

    setRoutes(
      Array.from(new Set(navs.map((item) => item.path))).map((path) => {
        return {
          component: navs.find((s) => s.path === path).component,
          icon: navs.find((s) => s.path === path).icon,
          label: navs.find((s) => s.path === path).label,
          path: navs.find((s) => s.path === path).path,
          showHeader: navs.find((s) => s.path === path).showHeader,
        };
      })
    );
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        user.loggingIn ? (
          <Layout style={{ minHeight: "100vh" }}>
            <Sider width={250}>
              <a href="/" className="logo">
                <span className="logo-lg p-1">
                  <img src={require("../../assets/images/oyunlag_new.png")} />
                </span>
              </a>
              <VerticalMenu />
            </Sider>
            <Layout className="site-layout">
              <MainHeader setCollapsed={() => setCollapsed(!collapsed)} />
              <Content>
                {routes.map((item) => (
                  <PrivateRoute
                    key={item.path}
                    path={item.path}
                    component={item.component}
                    showHeader={item.showHeader}
                    exact={item.exact}
                  />
                ))}
              </Content>
              <Footer style={{ textAlign: "center" }}>Оюунлаг ©2022</Footer>
            </Layout>
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

function mapState(state) {
  const { authentication } = state;
  return { user: authentication };
}

const connectedRouteContainer = connect(mapState, null)(RouteContainer);
export { connectedRouteContainer as RouteContainer };
