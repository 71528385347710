import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function Profile({ user }) {
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      lastName: user.lastName,
      firstName: user.firstName,
      registerNumber: user.registerNumber,
      email: user.email,
    });
  }, [user]);

  const onFinish = (values) => {
    console.log("Finish:", values);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onChangePassword = (values) => {
    setLoading(true);
    API.put(`users/change-password`, {
      id: user.id,
      newPassword: values.password,
      isDashboard: true,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          changePasswordForm.resetFields();
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setLoading(false);
    });
  };

  return (
    <>
      <PageTitle title={"Профайл"} description={"Өөрийн мэдээллээ засах"} />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form {...formItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                          name="cover"
                          label="Профайл зураг"
                          valuePropName="fileList"
                          getValueFromEvent={normCover}
                          rules={[
                            {
                              required: true,
                              message: "Зураг сонгоно уу!",
                            },
                          ]}
                        >
                          <Upload
                            customRequest={dummyRequest}
                            listType="picture"
                          >
                            <Button>
                              <UploadOutlined /> сонгох
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          label="Овог"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Овог оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Нэр"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Нэр оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Регистрийн дугаар"
                          name="registerNumber"
                          rules={[
                            {
                              required: true,
                              message: "Регистрийн дугаар оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Имэйл хаяг"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Имэйл хаяг оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Хадгалах
                          </Button>
                        </Form.Item>
                      </Form>

                      <Row>
                        <Col span={14} offset={6}>
                          <h6>Нууц үг солих</h6>
                        </Col>
                      </Row>

                      <Form
                        {...formItemLayout}
                        form={changePasswordForm}
                        onFinish={onChangePassword}
                      >
                        <Form.Item
                          label="Шинэ нууц үг"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Нууц үг оруулна уу!",
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Нууц үг солих
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedProfile = connect(mapState, actionCreators)(Profile);
export { connectedProfile as Profile };
