import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";

import {
  notification,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  Modal,
  message,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import NumberFormat from "react-number-format";
import moment from "moment";
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function KitchenAssign({ user, userData, teacherData, schoolClass }) {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [students, setStudents] = useState([]);
  const [studentIds, setStudentIds] = useState([]);

  const onFinish = (values) => {
    form
      .validateFields()
      .then((values) => {
        if (values.students.length > 0) {
          var day = moment(values.date).day();

          var isWeekend = day === 6 || day === 0;
          if (!isWeekend) {
            setButtonLoading(true);
            setLoading(true);
            API.post("kitchen/assign-food-count", {
              date: values.date,
              classId: values.classId,
              count: values.count,
              students: values.students,
            }).then((result) => {
              if (result.status === 200) {
                if (result.data.success) {
                  setVisible(false);
                  form.resetFields();
                  notification["success"]({
                    message: "Амжилттай",
                    description: result.data.message,
                  });
                } else {
                  notification["error"]({
                    message: "Амжилтгүй",
                    description: result.data.message,
                  });
                }
                setButtonLoading(false);
                setLoading(false);
              }
            });
          } else {
            Modal.error({
              title: "Алдаа",
              content: "Хүүхэд сонгоно уу",
            });
          }
        } else {
          Modal.error({
            title: "Алдаа",
            content: "Ажлын өдөр сонгоно уу",
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const changeStudents = (value) => {
    let counts = value.length;
    form.setFieldsValue({ count: counts });

    return value;
  };

  const changeClass = (value) => {
    API.get(`class/students/${value}`).then((result) => {
      if (result.status === 200) {
        let ids = [];
        if (result.data.success) {
          setStudents(result.data.content);
          result.data.content.map((item) => {
            if (item.kitchenBalance > 12000 * -1) {
              ids.push(item.studentId);
            }
          });
          setStudentIds(ids);
          form.setFieldsValue({ students: ids, count: ids.length });
        }
      }
    });

    return value;
  };

  return (
    <>
      <PageTitle
        title={"Хоолны тоо"}
        description={"Хоол идэх сурагчдын тоог гал тогоо руу илгээх"}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form
                        {...formItemLayout}
                        form={form}
                        initialValues={{
                          hasTeacher: false,
                        }}
                      >
                        <Form.Item
                          name="classId"
                          label="Анги"
                          rules={[
                            { required: true, message: "Анги сонгоно уу!" },
                          ]}
                          getValueFromEvent={changeClass}
                        >
                          <Select>
                            {schoolClass.map((item) => (
                              <Option value={item.id}>
                                {item.displayName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Хоол идэх огноо"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Хоол идэх огноо оруулна уу!",
                            },
                          ]}
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          label="Хүүхдүүд"
                          name="students"
                          rules={[
                            { required: true, message: "Хүүхдүүд сонгоно уу!" },
                          ]}
                          getValueFromEvent={changeStudents}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {students.map((item) => {
                              let isDisabled =
                                item.kitchenBalance > 12000 * -1 ? false : true;

                              return (
                                <Option
                                  value={item.studentId}
                                  disabled={isDisabled}
                                >
                                  {item.displayName} (
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={"₮"}
                                    value={item.kitchenBalance}
                                  />
                                  )
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Тоо" name="count">
                          <Input disabled />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button
                            type="primary"
                            onClick={() => onFinish()}
                            loading={buttonLoading}
                          >
                            Илгээх
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapState = function (state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return {
    user: authentication,
    userData: user,
    teacherData: teacher,
    schoolClass,
  };
};

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedKitchenAssign = connect(mapState, actionCreators)(KitchenAssign);
export { connectedKitchenAssign as KitchenAssign };
