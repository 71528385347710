import React, { Children, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import { MainHeader, PageTitle, VerticalMenu } from "../";

const { Header, Content, Footer, Sider } = Layout;
const PrivateRoute = ({ component: Component, showHeader, user, ...rest }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Route
      {...rest}
      render={(props) =>
        user.loggingIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

function mapState(state) {
  const { authentication } = state;
  return { user: authentication };
}

const connectedPrivateRoute = connect(mapState, null)(PrivateRoute);
export { connectedPrivateRoute as PrivateRoute };
