import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";
import { TeacherAccountHistory } from "./TeacherAccountHistory";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

function TeacherItem({ userData }) {
  let { teacherId } = useParams();

  const [data, setData] = useState({});
  const [name, setName] = useState("-");
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadgin, setLoading] = useState(false);

  useEffect(() => {
    fetchData();

    if (
      userData.userRoles.includes("ROLE_ADMIN") ||
      userData.userRoles.includes("ROLE_EMPLOYEE")
    ) {
      setIsAdmin(true);
    }
  }, []);

  const fetchData = () => {
    setLoading(true);

    console.log("teacherId was : " + teacherId);

    // API.get(`students/${studentId}`, {}).then((result) => {
    //   if (result.status == 200) {
    //     setName(result.data.content.displayName);
    //     setData(result.data.content);
    //     setLoading(false);
    //   }
    // });
  };

  return (
    <>
      <PageTitle
        title={name}
        description={
          <Link
            to={{
              pathname: `/teachers`,
            }}
          >
            Буцах
          </Link>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Tabs defaultActiveKey="9" onChange={callback}>
                      <TabPane tab={<span>Дансны хуулга</span>} key="5">
                        {<TeacherAccountHistory />}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { userData: user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTeacherItem = connect(mapState, actionCreators)(TeacherItem);
export { connectedTeacherItem as TeacherItem };
