import React, { useContext, useState, useEffect, useRef } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

function Schedule({ userData, schoolClass }) {
  const [isTeacher, setIsTeacher] = useState(false);
  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchKey, setSearchKey] = useState("displayName");
  const [currentClass, setCurrentClass] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const [dataSource, setDataSource] = useState([]);

  const columnsTable = [
    {
      title: "Цаг",
      dataIndex: "time",
      width: 150,
      editable: true,
    },
    {
      title: "Даваа",
      dataIndex: "mon",
      editable: true,
    },
    {
      title: "Мягмар",
      dataIndex: "tue",
      editable: true,
    },
    {
      title: "Лхагва",
      dataIndex: "wed",
      editable: true,
    },
    {
      title: "Пүрэв",
      dataIndex: "thu",
      editable: true,
    },
    {
      title: "Баасан",
      dataIndex: "fri",
      editable: true,
    },
    {
      title: "Үйлдэл",
      dataIndex: "action",
      render: (text, record) => <a onClick={() => removeRow(record)}>Устгах</a>,
    },
  ];

  useEffect(() => {
    fetchData();

    if (
      !userData.userRoles.includes("ROLE_EMPLOYEE") &&
      userData.userRoles.includes("ROLE_TEACHER")
    ) {
      setIsTeacher(true);
    }
  }, []);

  const fetchData = () => {
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, teacherId: null } }
    ).then((result) => {
      if (result.status === 200) {
        setClasses(result.data.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const removeRow = (item) => {
    const newArr = dataSource.filter((day) => day.key !== item.key);
    setDataSource(newArr);
  };

  const columns = columnsTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const getSchedule = () => {
    if (currentClass === null) {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Анги сонгоно уу",
      });
      return;
    }
    API.put(`settings/timetable/${currentClass}`).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setDataSource(result.data.content);
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          setDataSource([]);
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
    });
  };

  const saveSchedule = () => {
    if (dataSource === null) {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Алдаа гарлаа",
      });
      return;
    }

    if (dataSource.length === 0) {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Алдаа гарлаа",
      });
      return;
    }

    API.post("settings/timetable/add", {
      classId: currentClass,
      data: dataSource,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
    });
  };

  const addRow = () => {
    const lastItem = dataSource[dataSource.length - 1];

    setDataSource([
      ...dataSource,
      {
        key: lastItem.key + 1,
        time: "-",
        mon: "-",
        tue: "-",
        wed: "-",
        thu: "-",
        fri: "-",
      },
    ]);
  };

  return (
    <>
      <PageTitle title="Хичээлийн хуваарь" description="Хичээлийн хуваарь" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="schedule-controls">
                    <Select
                      placeholder="Сонгоно уу"
                      showSearch
                      allowClear
                      onChange={(val) => setCurrentClass(val)}
                      style={{ marginRight: 20 }}
                    >
                      {isTeacher
                        ? schoolClass.map((item) => (
                            <Option value={item.id}>{item.displayName}</Option>
                          ))
                        : classes.map((cat) => (
                            <Option value={cat.id} key={cat.id}>
                              {cat.displayName}
                            </Option>
                          ))}
                    </Select>
                    <Button
                      onClick={() => getSchedule()}
                      style={{ marginRight: 20 }}
                      type="primary"
                    >
                      Харах
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginRight: 20 }}
                      onClick={() => saveSchedule()}
                    >
                      Хадгалах
                    </Button>
                    {dataSource.length > 0 ? (
                      <Button type="primary" onClick={() => addRow()}>
                        Мөр нэмэх
                      </Button>
                    ) : null}
                  </div>
                  <div className="table-responsive">
                    <Table
                      components={components}
                      rowClassName={() => "editable-row"}
                      bordered
                      pagination={false}
                      dataSource={dataSource}
                      columns={columns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return { userData: user, teacherData: teacher, schoolClass };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedSchedule = connect(mapState, actionCreators)(Schedule);
export { connectedSchedule as Schedule };
