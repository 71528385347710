import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  notification,
  Space,
  Modal,
  Button,
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Card,
  Divider,
} from "antd";

import {
  BookOutlined,
  UserSwitchOutlined,
  BlockOutlined,
  BankOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";
import { Training } from "./Training";
import { Rehearsal } from "./Rehearsal";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function Settings({ user }) {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [preschoolPrice, setPreschoolPrice] = useState(0);
  const [teacherFoodPrice, setTeacherFoodPrice] = useState(0);

  const [loadingGrade, setLoadingGrade] = useState(false);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [loadingClass, setLoadingClass] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showPreSchoolModal, setShowPreSchoolModal] = useState(false);
  const [showTeacherFoodModal, setTeacherFoodModal] = useState(false);

  useEffect(() => {
    getClasses();
    fetchPrice();
  }, []);

  const getClasses = () => {
    API.post(
      "class/all",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: 0, size: 50 } }
    ).then((result) => {
      if (result.status === 200) {
        setCategories(result.data.content);
      }
    });
  };

  const syncGrades = () => {
    setLoadingGrade(true);
    API.get("integration/sync-classes").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: "Мэдээлэл амжилттай шинэчлэгдлээ",
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setLoadingGrade(false);
    });
  };

  const syncTeachers = () => {
    setLoadingStudent(true);
    API.get("integration/sync-teacher").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: "Мэдээлэл амжилттай шинэчлэгдлээ",
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setLoadingStudent(false);
    });
  };

  const syncClass = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingClass(true);
        API.get("integration/sync-class-students", {
          params: {
            classOid: values.classId,
          },
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setShowModal(false);
              notification["success"]({
                message: "Амжилттай",
                description: "Мэдээлэл амжилттай шинэчлэгдлээ",
              });
            } else {
              setLoadingClass(false);
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
          setLoadingClass(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const fetchPrice = () => {
    API.get("kinder-garden/get-price").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setPreschoolPrice(result.data.content);
        } else {
          setPreschoolPrice(0);
        }
      }
    });

    API.get("teacher/get-price").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setTeacherFoodPrice(result.data.content);
        } else {
          setTeacherFoodPrice(0);
        }
      }
    });
  };

  const updatePrice = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setLoadingClass(true);
        API.post("kinder-garden/set-price", {
          ...values,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setShowPreSchoolModal(false);
              notification["success"]({
                message: "Амжилттай",
                description: "Мэдээлэл амжилттай шинэчлэгдлээ",
              });
            } else {
              setLoadingClass(false);
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
          setLoadingClass(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const updateFoodPrice = () => {
    form
      .validateFields()
      .then((values) => {
        setLoadingClass(true);
        API.post("teacher/set-price", {
          ...values,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setTeacherFoodModal(false);
              notification["success"]({
                message: "Амжилттай",
                description: "Мэдээлэл амжилттай шинэчлэгдлээ",
              });
            } else {
              setLoadingClass(false);
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
          setLoadingClass(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <PageTitle title={"Тохиргоо"} description={"Системийн тохиргоо"} />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <Card
            title="Мэдээлэл шинэчлэх тохиргоо"
            bordered={true}
            style={{ marginBottom: 10 }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Button
                  type="primary"
                  block
                  size="large"
                  icon={<UserSwitchOutlined style={{ borderRight: 1 }} />}
                  onClick={() => syncGrades()}
                  loading={loadingGrade}
                >
                  Office365-аас ангийн мэдээлэл шинэчлэх
                </Button>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Button
                  type="primary"
                  block
                  size="large"
                  icon={<BookOutlined style={{ borderRight: 1 }} />}
                  onClick={() => syncTeachers()}
                  loading={loadingStudent}
                >
                  Office365-аас Багшийн мэдээлэл шинэчлэх
                </Button>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Button
                  type="primary"
                  block
                  size="large"
                  icon={<BlockOutlined style={{ borderRight: 1 }} />}
                  onClick={() => setShowModal(true)}
                >
                  Office365 ангийн мэдээлэл шинэчлэх
                </Button>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Button
                  block
                  style={{
                    backgroundColor: "green",
                    color: "white",
                  }}
                  size="large"
                  icon={<BankOutlined style={{ borderRight: 1 }} />}
                  onClick={() => setShowPreSchoolModal(true)}
                >
                  Бэлтгэл ангийн бүртгэлийн хураамж шинэчлэх
                </Button>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Button
                  block
                  style={{
                    backgroundColor: "green",
                    color: "white",
                  }}
                  size="large"
                  icon={<BankOutlined style={{ borderRight: 1 }} />}
                  onClick={() => setTeacherFoodModal(true)}
                >
                  Багшийн хоолний үнийн дүн шинэчлэх
                </Button>
              </Col>
            </Row>
          </Card>

          <Card
            title="Нэмэлт тохиргоо"
            bordered={true}
            style={{ marginBottom: 10 }}
            hoverable
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Training />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Rehearsal />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal
        title="Ангийн мэдээлэл шинэчлэх"
        visible={showModal}
        onOk={syncClass}
        onCancel={() => setShowModal(false)}
        okText="Sync хийх"
        cancelText="Болих"
        confirmLoading={loadingClass}
        width={720}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ schoolClassId: null }}
        >
          <Form.Item label="Анги" name="classId">
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {categories.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Бэлтгэл ангийн бүртгэлийн хураамж"
        visible={showPreSchoolModal}
        onOk={updatePrice}
        onCancel={() => setShowPreSchoolModal(false)}
        okText="Шинэчлэх"
        cancelText="Болих"
        confirmLoading={loadingClass}
      >
        <Form form={form} initialValues={{ price: preschoolPrice }}>
          <Form.Item
            label="Үнэ"
            name="price"
            rules={[
              {
                required: true,
                message: "Үнэ оруулна уу!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Багшийн хоолний үнийн дүн"
        visible={showTeacherFoodModal}
        onOk={updateFoodPrice}
        onCancel={() => setTeacherFoodModal(false)}
        okText="Шинэчлэх"
        cancelText="Болих"
        confirmLoading={loadingClass}
      >
        <Form form={form} initialValues={{ price: teacherFoodPrice }}>
          <Form.Item
            label="Үнэ"
            name="price"
            rules={[
              {
                required: true,
                message: "Үнэ оруулна уу!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedSettings = connect(mapState, actionCreators)(Settings);
export { connectedSettings as Settings };
