import React, { useState, useEffect, useRef } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  Tag,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";
import { PageTitle, Pagination } from "../../components";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { TreeNode } = TreeSelect;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Transactions() {
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [date, setDate] = useState(moment().format("YYYY/MM/DD"));
  const [vendingAccountID, setVendingAccountID] = useState(null);
  const [kitchenAccountID, setKitchenAccountID] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("vending");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [accountType, setAccountType] = useState(null);

  let { studentId } = useParams();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const checkPayment = (paymentId) => {
    API.get("students/account/charge-check-dashboard", {
      params: {
        paymentId: paymentId,
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          form.submit();
        }
      }
    });
  };

  const getStatusName = (status) => {
    switch (status) {
      case "PAID":
        return <span style={{ color: "green" }}>Амжилттай</span>;
      case "INVOICE_CREATED":
        return <span style={{ color: "orange" }}>Нэхэмжлэл үүсэн</span>;
      default:
        return <span style={{ color: "red" }}>Төлөөгүй</span>;
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Данс",
      dataIndex: "accountType",
      key: "accountType",
      render: (text, row, index) =>
        text === "FOOD" ? "Хоолны данс" : "Вендинг машины данс",
    },
    {
      title: "Үүссэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Хэрэглэгчийн нэр",
      dataIndex: "studentName",
      key: "studentName",
      width: 200,
      // ...getColumnSearchProps("studentName"),
      render: (text, record) => {
        if (record.student !== null) {
          return (
            <>
              <Tag color="green">Сурагч:</Tag> {record.student.displayName}
            </>
          );
        } else if (record.teacher !== null) {
          return (
            <>
              <Tag color="blue">Багш:</Tag> {record.teacher.displayName}
            </>
          );
        } else {
          return "~";
        }
      },
    },
    {
      title: "Анги",
      dataIndex: "schoolClassName",
      key: "schoolClassName",
      render: (text, record) => {
        if (record.student !== null) {
          return record.student.schoolClassName;
        } else if (record.teacher !== null) {
          return record.teacher.schoolClassName;
        } else {
          return "~";
        }
      },
    },
    {
      title: "Мэйл",
      dataIndex: "microsoftMail",
      key: "microsoftMail",
      render: (text, record) => {
        if (record.student !== null) {
          return record.student.microsoftMail;
        } else if (record.teacher !== null) {
          return record.teacher.mail;
        } else {
          return "~";
        }
      },
    },
    {
      title: "Гүйлгээний дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Нэхэмжлэл үүссэн",
          value: "INVOICE_CREATED",
        },
        {
          text: "Амжилттай",
          value: "PAID",
        },
        {
          text: "Төлөөгүй",
          value: "UNPAID",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => getStatusName(text),
    },
    {
      title: "Гүйлгээний дугаар",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Системийн шалгалт",
      dataIndex: "verifiedCron",
      key: "verifiedCron",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "Гар шалгалт",
      dataIndex: "verfied",
      key: "verfied",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "Бэлтгэл бүлгийн бүртгэл",
      dataIndex: "kinderGardenInvoice",
      key: "kinderGardenInvoice",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "И-Баримт статус",
      dataIndex: "vatStatus",
      key: "vatStatus",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 24 }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: 24 }}
            />
          )
        ) : (
          <span>~</span>
        ),
    },
    {
      title: "И-Баримт имэйл",
      dataIndex: "vatClientEmail",
      key: "vatClientEmail",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <>{text}</>
          ) : (
            <span>~</span>
          )
        ) : (
          <span>~</span>
        ),
    },
    {
      title: "И-Баримт илгээсэн эсэх",
      dataIndex: "vatEmailed",
      key: "vatEmailed",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 24 }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: 24 }}
            />
          )
        ) : (
          <span>~</span>
        ),
    },

    {
      title: "Шалгах",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.status === "PAID" ? (
          <span style={{ color: "green" }}>Амжилттай</span>
        ) : (
          <Button type="primary" onClick={() => checkPayment(record.paymentId)}>
            Шалгах
          </Button>
        ),
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    API.post("payment/transactions-dashboard", null, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD 00:00"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD 23:59"),
        page: currentPage - 1,
        size: showNum,
        accountType: accountType,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
    API.post("payment/transactions-dashboard", null, {
      params: {
        startDate: moment(form.getFieldValue("date")[0]).format(
          "YYYY/MM/DD 00:00"
        ),
        endDate: moment(form.getFieldValue("date")[1]).format(
          "YYYY/MM/DD 23:59"
        ),
        page: currentPage - 1,
        size: showNum,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle title="Гүйлгээний түүх" description="Нийт гүйлгээний түүх" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Spin spinning={loading}>
                    <div className="row">
                      <div className="col-lg-8">
                        <Form
                          form={form}
                          layout="inline"
                          style={{ marginBottom: 10 }}
                          initialValues={{
                            date: [moment().subtract(1, "days"), moment()],
                          }}
                          onFinish={onFinish}
                        >
                          <Form.Item
                            name="date"
                            label="Хугацаа"
                            rules={[
                              {
                                required: true,
                                message: "Хугацаа сонгоно уу!",
                              },
                            ]}
                          >
                            <RangePicker />
                          </Form.Item>
                          <Form.Item name="type" label="Төрөл">
                            <Select
                              allowClear
                              value={accountType}
                              defaultValue={accountType}
                              style={{ width: 200 }}
                              onChange={(val) => setAccountType(val)}
                            >
                              <Option value="FOOD">Хоолны данс</Option>
                              <Option value="VENDING">
                                Вендинг машины данс
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item {...tailLayout}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              Харах
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                      <div className="col-lg-4 text-right">
                        <Select
                          defaultValue="100"
                          onChange={(val) => setShowNum(val)}
                        >
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                          <Option value={50}>50</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </div>
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <Table
                            className="table table-centered table-hover mb-0"
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            rowKey="id"
                            //   locale={{
                            //     emptyText: `${moment(form.getFieldValue("date")[0]).format(
                            //       "YYYY/MM/DD"
                            //     )} - ${moment(form.getFieldValue("date")[1]).format(
                            //       "YYYY/MM/DD"
                            //     )} хооронд гүйлгээ хийгдээгүй байна`,
                            //   }}
                          />
                        </div>
                      </div>
                    </div>
                  </Spin>
                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTransactions = connect(mapState, actionCreators)(Transactions);
export { connectedTransactions as Transactions };
