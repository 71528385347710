import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { ReactReader, EpubView } from "react-reader";
import FilePreviewer from "react-file-previewer";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function MonthlyGrade() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleBookRead, setVisibleBookRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [currentBook, setCurrentBook] = useState("");
  const [currentExt, setExt] = useState("");
  const [currentCover, setCurrentCover] = useState("");
  const [currentBookTitle, setCurrentBookTitle] = useState("");
  const [searchValue, setSearchValue] = useState(null);

  let { year, term } = useParams();

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = (currentPage) => {
    setLoading(true);
    API.get("grade/progress/available-classes", {
      params: { year: year, month: term },
    }).then((result) => {
      if (result.status == 200) {
        setData(result.data.content);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, type: "EBOOK" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;

    const isPDF =
      file.type === ".csv" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isPDF) {
      message.error("Зөвхөн MS Excel файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Анги",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/monthly-class/${year}/${term}/${record.classId}`,
            state: { classData: record },
          }}
        >
          {text}
        </Link>
      ),
    },
  ];

  const confirm = (bookId) => {
    deleteBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteBook = (bookId) => {
    API.delete(`library/books/delete/?bookId=${bookId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerBook = (values) => {
    console.log(values);
    API.post("grade/add", {
      year: values.year,
      term: values.term,
      schoolClassId: values.schoolClassId,
      file: values.excelFile[0].originFileObj,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          console.log(result);
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title={`Явцын шалгалт`}
        description={`${year} оны ${term} сар`}
        right={false}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Явцын дүн нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={form} initialValues={{ term: "1" }}>
          <Form.Item
            label="Анги"
            name="schoolClassId"
            rules={[{ required: true, message: "Анги сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {categories.map((cat) => (
                <Option value={cat.classId} key={cat.classId}>
                  {cat.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Он"
            name="year"
            rules={[{ required: true, message: "Он оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Улирал"
            name="term"
            rules={[{ required: true, message: "Улирал оруулна уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value="1" key="1">
                1-р улирал
              </Option>
              <Option value="2" key="2">
                2-р улирал
              </Option>
              <Option value="3" key="3">
                3-р улирал
              </Option>
              <Option value="4" key="4">
                4-р улирал
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="excelFile"
            label="Файл"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Ном сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedMonthlyGrade = connect(mapState, actionCreators)(MonthlyGrade);
export { connectedMonthlyGrade as MonthlyGrade };
