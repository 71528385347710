import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { VendingMachine } from "./VendingMachine";
import { VendingProduct } from "./VendingProduct";
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function VendingTable({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    API.get("vending/list").then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setProducts(result.data.content.content);
        }
      }
    });
  };

  return (
    <>
      <PageTitle title="Бараа тохируулах" description="Бараа тохируулах" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="oyunlag-vending-machine">
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={1} />
                        <VendingMachine products={products} rowNumber={2} />
                        <VendingMachine products={products} rowNumber={3} />
                        <VendingMachine products={products} rowNumber={4} />
                        <VendingMachine products={products} rowNumber={5} />
                      </div>
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={6} />
                        <VendingMachine products={products} rowNumber={7} />
                        <VendingMachine products={products} rowNumber={8} />
                        <VendingMachine products={products} rowNumber={9} />
                        <VendingMachine products={products} rowNumber={10} />
                      </div>
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={11} />
                        <VendingMachine products={products} rowNumber={12} />
                        <VendingMachine products={products} rowNumber={13} />
                        <VendingMachine products={products} rowNumber={14} />
                        <VendingMachine products={products} rowNumber={15} />
                        <VendingMachine products={products} rowNumber={16} />
                        <VendingMachine products={products} rowNumber={17} />
                        <VendingMachine products={products} rowNumber={18} />
                        <VendingMachine products={products} rowNumber={19} />
                        <VendingMachine products={products} rowNumber={20} />
                      </div>
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={21} />
                        <VendingMachine products={products} rowNumber={22} />
                        <VendingMachine products={products} rowNumber={23} />
                        <VendingMachine products={products} rowNumber={24} />
                        <VendingMachine products={products} rowNumber={25} />
                        <VendingMachine products={products} rowNumber={26} />
                        <VendingMachine products={products} rowNumber={27} />
                        <VendingMachine products={products} rowNumber={28} />
                        <VendingMachine products={products} rowNumber={29} />
                        <VendingMachine products={products} rowNumber={30} />
                      </div>
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={31} />
                        <VendingMachine products={products} rowNumber={32} />
                        <VendingMachine products={products} rowNumber={33} />
                        <VendingMachine products={products} rowNumber={34} />
                        <VendingMachine products={products} rowNumber={35} />
                        <VendingMachine products={products} rowNumber={36} />
                        <VendingMachine products={products} rowNumber={37} />
                        <VendingMachine products={products} rowNumber={38} />
                        <VendingMachine products={products} rowNumber={39} />
                        <VendingMachine products={products} rowNumber={40} />
                      </div>
                      <div className="vending-row">
                        <VendingMachine products={products} rowNumber={41} />
                        <VendingMachine products={products} rowNumber={42} />
                        <VendingMachine products={products} rowNumber={43} />
                        <VendingMachine products={products} rowNumber={44} />
                        <VendingMachine products={products} rowNumber={45} />
                        <VendingMachine products={products} rowNumber={46} />
                        <VendingMachine products={products} rowNumber={47} />
                        <VendingMachine products={products} rowNumber={48} />
                        <VendingMachine products={products} rowNumber={49} />
                        <VendingMachine products={products} rowNumber={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return { userData: user, teacherData: teacher, schoolClass };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedVendingTable = connect(mapState, actionCreators)(VendingTable);
export { connectedVendingTable as VendingTable };
