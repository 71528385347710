import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Typography,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";
import { FormItemContext } from "antd/lib/form/context";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function TimeTable({ userData }) {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const onFinish = async (values) => {
    setLoading(true);

    let days = [];
    for (
      var m = moment(values.date[0]);
      m.isSameOrBefore(values.date[1]);
      m.add(1, "days")
    ) {
      days.push({
        title: m.format("YYYY.MM.DD"),
        dataIndex: m.format("YYYY.MM.DD"),
        width: 70,
        align: "center",
        render: (text, row, index) =>
          text !== undefined ? text : <span style={{ color: "red" }}>0</span>,
      });
    }

    setDateColumns(days);

    let teachers = [];
    const result = await API.post(
      "teacher/list",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: currentPage, size: 50 } }
    );
    teachers = result.data.content.content;
    setTotalPages(result.data.content.totalPages);

    const assignedList = await API.get(`kitchen/teacher/assigned-list`, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD"),
      },
    });
    const assignedData = assignedList.data.content;
    let tableData = [];

    teachers.map((teacher) => {
      const currentAssign = assignedData.filter(
        (assign) => assign.teacher.id === teacher.id
      );
      let classData = {};
      classData.className = teacher.displayName;
      currentAssign.map((assign) => {
        classData[moment(assign.requestedDate).format("YYYY.MM.DD")] =
          assign.count;
      });

      tableData.push(classData);
    });
    setData(tableData);
    setLoading(false);
  };

  const fetchData = (currentPage) => {
    setCurrentPage(currentPage);
    form.submit();
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  console.log("data", data);

  return (
    <>
      <PageTitle
        title="Багшийн хоол идсэн түүх"
        description="Багшийн хоол идсэн түүх"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                      >
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        scroll={{ y: 600, x: 960 }}
                        columns={[
                          {
                            title: "№",
                            dataIndex: "id",
                            key: "id",
                            width: 70,
                            fixed: "left",
                            render: (text, row, index) => (
                              <>{currentPage * 50 + index + 1}</>
                            ),
                          },
                          {
                            title: "Багш",
                            dataIndex: "className",
                            key: "className",
                            width: 150,
                            fixed: "left",
                          },
                          ...dateColumns,
                          {
                            title: "Нийт",
                            dataIndex: "summary",
                            key: "summary",
                            width: 120,
                            fixed: "right",
                            render: (text, row, index) => {
                              let total = 0;
                              Object.values(row).map((item) => {
                                if (Number.isInteger(item)) {
                                  total += item;
                                }
                              });

                              return <>{total}</>;
                            },
                          },
                        ]}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTimeTable = connect(mapState, actionCreators)(TimeTable);
export { connectedTimeTable as TeachersTimeTable };
