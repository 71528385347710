import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Typography,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function KitchenServedHistory({ userData }) {
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    API.get(`kitchen/served-foods`, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content);
        }
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: "Өдөр",
      dataIndex: "requestedAt",
      key: "requestedAt",
      render: (text, record) => moment(text).format("YYYY/MM/DD"),
    },
    {
      title: "1-р хоол",
      dataIndex: "crepe",
      key: "crepe",
    },
    {
      title: "2-р хоол",
      dataIndex: "entree",
      key: "entree",
    },
    {
      title: "Хачир",
      dataIndex: "sideDish",
      key: "sideDish",
    },
    {
      title: "Салат",
      dataIndex: "salade",
      key: "salade",
    },
    {
      title: "Уух юм",
      dataIndex: "drink",
      key: "drink",
    },
  ];

  return (
    <>
      <PageTitle title="Хоол гарсан түүх" description="Хоол гарсан түүх" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                      >
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedKitchenServedHistory = connect(
  mapState,
  actionCreators
)(KitchenServedHistory);
export { connectedKitchenServedHistory as KitchenServedHistory };
