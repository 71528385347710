import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { PlusOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function NeighbourChat({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
    if (userData.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
    getClasses();
  }, []);

  const fetchData = (currentPage) => {
    setLoading(true);
    API.get("chat/rooms", {
      params: { page: currentPage - 1, size: showNum },
    }).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Нэр",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Нийтэлсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => confirm(record.id)}
            onCancel={cancel}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <a href="#">Устгах</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const confirm = (newsId) => {
    deleteRoom(newsId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteRoom = (roomId) => {
    API.delete(`chat/delete?roomId=${roomId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerBook = (values) => {
    setButtonLoading(true);

    API.post("chat/add-room", {
      roomAdminId: userData.id,
      roomName: values.name,
      classOid: values.classOid,
    }).then((result) => {
      if (result.status === 200) {
        form.resetFields();
        fetchData(currentPage);
        setVisible(false);
        setButtonLoading(false);
      }
    });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const getClasses = () => {
    API.post(
      "class/all",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: 0, size: 50 } }
    ).then((result) => {
      if (result.status === 200) {
        setCategories(result.data.content);
      }
    });
  };

  return (
    <>
      <PageTitle
        title="Чат"
        description="Чат-уудын жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Чат нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Өрөө нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        confirmLoading={buttonLoading}
        width={720}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ schoolClassId: null }}
        >
          <Form.Item
            label="Өрөөний нэр"
            name="name"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Анги" name="classOid">
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {categories.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, schoolClass } = authentication;
  return {
    userData: user,
    schoolClass: schoolClass !== undefined ? schoolClass : [],
  };
}

const connectedNeighbourChat = connect(mapState, null)(NeighbourChat);
export { connectedNeighbourChat as NeighbourChat };
