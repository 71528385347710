import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Typography,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";
import { FormItemContext } from "antd/lib/form/context";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function StudentAttendance({ userData }) {
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateColumns, setDateColumns] = useState([]);
  let { studentId } = useParams();
  const onFinish = (values) => {
    setLoading(true);

    let days = [];
    for (
      var m = moment(values.date[0]);
      m.isSameOrBefore(values.date[1]);
      m.add(1, "days")
    ) {
      days.push({
        title: m.format("YYYY.MM.DD"),
        dataIndex: m.format("YYYY.MM.DD"),
        width: 120,
        align: "center",
        render: (text, row, index) =>
          text !== undefined ? text : <span style={{ color: "red" }}>-</span>,
      });
    }

    setDateColumns(days);

    API.get(`attendance/list/${studentId}`, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD"),
        type: "STUDENT",
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          let content = result.data.content;
          let tableData = [];
          content.map((category) => {
            let classData = {};
            const classId = category.classId;

            classData.className =
              category.user.firstName + " " + category.user.lastName;

            classData[moment(category.createdAt).format("YYYY.MM.DD")] =
              category.registeredDate;

            tableData.push(classData);
          });

          setData(tableData);
        }
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Row gutter={16} align="bottom" align="top">
        <Col className="gutter-row" span={24}>
          <Form
            layout="inline"
            style={{ marginBottom: 10 }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="date"
              label="Өдөр"
              rules={[
                {
                  required: true,
                  message: "Өдөр сонгоно уу!",
                },
              ]}
            >
              <RangePicker />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Харах
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table
          className="table table-centered table-hover mb-0"
          scroll={{ y: 600, x: 960 }}
          columns={[
            {
              title: "№",
              dataIndex: "id",
              key: "id",
              width: 70,
              fixed: "left",
              render: (text, row, index) => <>{index + 1}</>,
            },
            {
              title: "Нэр",
              dataIndex: "className",
              key: "className",
              fixed: "left",
              width: 100,
            },
            ...dateColumns,
          ]}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </Spin>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentAttendance = connect(
  mapState,
  actionCreators
)(StudentAttendance);
export { connectedStudentAttendance as StudentAttendance };
