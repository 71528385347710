import { libConstants } from "../_constants";
import { alertActions } from "./";
import { libService } from "../_services";

export const libActions = {
  getDropdownLib,
};

function setLib(lib) {
  return { type: libConstants.SETLIB, lib };
}

function getDropdownLib() {
  return (dispatch) => {
    libService.getLibService().then(
      (result) => {
        dispatch(setLib(result));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}
