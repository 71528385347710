import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function BookReturn() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "library/books/orders",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: currentPage - 1, size: showNum, status: "RETURNED" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    console.log(file);
    const isPDF =
      file.type === "application/pdf" || file.type === "application/epub+zip";

    if (!isPDF) {
      message.error("Зөвхөн PDF, EPUB файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return (
          <Zoom>
            <img src={appInfo.apiURL + record.book.coverPhoto} width={50} />
          </Zoom>
        );
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/book/${record.bookId}`}>{record.book.name}</Link>
      ),
    },
    {
      title: "Авсан хүн",
      dataIndex: "isbn",
      key: "isbn",
      render: (text, record) => getDisplayName(record),
    },
    {
      title: "Хоног",
      dataIndex: "requestedCount",
      key: "requestedCount",
    },
    {
      title: "Төлөв",
      dataIndex: "bookStatus",
      key: "bookStatus",
      render: (text, record) =>
        record.book.bookCondition === "NEW" ? "Шинэ" : "Хэрэглэсэн",
    },
    {
      title: "Авсан огноо",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Буцаах огноо",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Хүлээж авсан огноо",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
  ];

  const confirm = (bookId) => {
    deleteBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteBook = (bookId) => {
    API.delete(`library/books/delete/?bookId=${bookId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const getDisplayName = (record) => {
    let displayName = "-";
    if (record.bookingOrderClientType === "STUDENT") {
      if (record.student !== null) {
        if (record.student.displayName !== null) {
          displayName = record.student.displayName;
        }
      } else {
        displayName = "Устсан";
      }
    } else {
      if (record.client !== null) {
        if (record.client.displayName !== null) {
          displayName = record.client.displayName;
        }
      } else {
        displayName = "Устсан";
      }
    }

    return displayName;
  };

  const registerBook = (values) => {
    API.post("library/books/add", {
      name: values.name,
      isbn: values.isbn,
      authorName: values.authorName,
      publishedPlace: values.publishedPlace,
      publicationDate: moment(values.publicationDate).format("YYYY-MM-DD"),
      schoolRegisteredDate: moment(values.schoolRegisteredDate).format(
        "YYYY-MM-DD"
      ),
      note: values.note,
      bookCondition: values.bookCondition,
      categoryId: values.category,
    }).then((result) => {
      if (result.status === 200) {
        const response = result.data.content;
        var formData = new FormData();
        formData.set("file", values.book[0].originFileObj);
        formData.set("id", response.id);
        formData.set("type", "FILE");
        formData.set("entity", "LIBRARY");

        API.post("file/upload", formData).then((resultFile) => {
          if (resultFile.status === 200) {
            formData.set("file", values.cover[0].originFileObj);
            formData.set("type", "COVER_PHOTO");
            API.post("file/upload", formData).then((resultCover) => {
              if (resultCover.status === 200) {
                form.resetFields();
                fetchData(currentPage);
                setVisible(false);
              }
            });
          }
        });
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title="Хүлээж авсан түүх"
        description="Ном хүлээж авсан түүхүүд"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="name" key="name">
                              Номын нэрээр
                            </Option>
                            <Option value="authorName" key="authorName">
                              Номын зохиолчоор
                            </Option>
                            <Option value="isbn" key="isbn">
                              ISBN
                            </Option>
                            <Option
                              value="publicationDate"
                              key="publicationDate"
                            >
                              Хэвлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Номын нэр">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10} key={10}>
                          10
                        </Option>
                        <Option value={20} key={20}>
                          20
                        </Option>
                        <Option value={30} key={30}>
                          30
                        </Option>
                        <Option value={40} key={40}>
                          40
                        </Option>
                        <Option value={50} key={50}>
                          50
                        </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ bookCondition: "NEW" }}
        >
          <Form.Item
            label="Номын нэр"
            name="name"
            rules={[{ required: true, message: "Номын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номын ангилал"
            name="category"
            rules={[{ required: true, message: "Номын ангилал сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {categories.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.categoryName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Үнэлгээ" name="bookCondition">
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value="NEW">Шинэ</Option>
              <Option value="USED">Дунд</Option>
              <Option value="OLD">Хуучин</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="ISBN"
            name="isbn"
            rules={[{ required: true, message: "ISBN оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Зохиолчын нэр"
            name="authorName"
            rules={[{ required: true, message: "Зохиолчын нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Хэвлэсэн газар"
            name="publishedPlace"
            rules={[{ required: true, message: "Хэвлэсэн газар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Хэвлэсэн огноо"
            name="publicationDate"
            rules={[{ required: true, message: "Хэвлэсэн огноо оруулна уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Бүртгэгдсэн огноо"
            name="schoolRegisteredDate"
            rules={[{ required: true, message: "Огноо оруулна уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="book"
            label="Файл"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Ном сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="cover"
            label="Номын хавтас"
            valuePropName="fileList"
            getValueFromEvent={normCover}
            rules={[{ required: true, message: "Номын хавтас сонгоно уу!" }]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Тэмдэглэл" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedBookReturn = connect(mapState, actionCreators)(BookReturn);
export { connectedBookReturn as BookReturn };
