import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import NumberFormat from "react-number-format";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function Revision() {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState([]);
  const [studentIds, setStudentIds] = useState([]);
  const [searchKey, setSearchKey] = useState("displayName");
  const [currentClass, setCurrentClass] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, teacherId: null } }
    ).then((result) => {
      if (result.status === 200) {
        setClasses(result.data.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = (values) => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        API.post("news/notify/revision", {
          content: values.content,
          students: values.students,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setVisible(false);
              form.resetFields();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
            setLoading(false);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const changeStudents = (value) => {
    let counts = value.length;
    form.setFieldsValue({ count: counts });

    return value;
  };

  const changeClass = (value) => {
    API.get(`class/students/${value}`).then((result) => {
      if (result.status === 200) {
        let ids = [];
        if (result.data.success) {
          setStudents(result.data.content);
          setStudentIds(ids);
        }
      }
    });

    return value;
  };

  return (
    <>
      <PageTitle
        title={"Давтлага"}
        description={"Давтлага авах сурагчдыг эцэг эхэд мэдэгдэх"}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form
                        {...formItemLayout}
                        form={form}
                        initialValues={{
                          hasTeacher: false,
                          students: studentIds,
                          count: studentIds.length,
                        }}
                      >
                        <Form.Item
                          name="classId"
                          label="Анги"
                          rules={[
                            { required: true, message: "Анги сонгоно уу!" },
                          ]}
                          getValueFromEvent={changeClass}
                        >
                          <Select>
                            {classes.map((item) => (
                              <Option value={item.id}>
                                {item.displayName} - {item.schoolClassName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Хүүхдүүд"
                          name="students"
                          rules={[
                            { required: true, message: "Хүүхдүүд сонгоно уу!" },
                          ]}
                          getValueFromEvent={changeStudents}
                        >
                          <Select mode="multiple">
                            {students.map((item) => {
                              return (
                                <Option value={item.studentId}>
                                  {item.displayName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Тоо" name="count">
                          <Input disabled />
                        </Form.Item>

                        <Form.Item
                          label="Тэмдэглэл"
                          name="content"
                          rules={[
                            { required: true, message: "Тэмдэглэл бичнэ үү!" },
                          ]}
                        >
                          <Input.TextArea />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Илгээх
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapState = function (state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return {
    user: authentication,
    userData: user,
    teacherData: teacher,
    schoolClass,
  };
};

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedRevision = connect(mapState, actionCreators)(Revision);
export { connectedRevision as Revision };
