import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";
import {
  notification,
  Button,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
} from "antd";

function ShopTable({ userData, schoolClass }) {
  const [shopForm] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    console.log("Trying to load...");
  }, []);

  const validateAndSubmit = (shopForm) => {
    shopForm.submit();
  };

  const resetForm = (shopForm) => {
    shopForm.resetFields();
  };

  const onFinishShop = (values) => {
    const rfidNumber = values.rfid;

    API.post("shop/buy", {
      studentRfid: rfidNumber,
      price: values.price,
      description: values.description,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            duration: 10,
            description: rfidNumber + " дугаартай карт: " + result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            duration: 10,
            description: result.data.message,
          });
        }
        shopForm.resetFields();
      }
      setSaveLoading(false);
    });
  };

  return (
    <>
      <PageTitle
        title="Бараа худалдаж авах"
        description="Бараа худалдаж авах"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    id="shopForm"
                    layout="vertical"
                    style={{ marginBottom: 10 }}
                    form={shopForm}
                    onFinish={onFinishShop}
                  >
                    <Row gutter={16} align="bottom">
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="price"
                          label="Үнийн дүн"
                          rules={[
                            {
                              required: true,
                              message: "Үнийн дүн оруулна уу?",
                            },
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} align="bottom">
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="description"
                          label="Гүйлгээний утга"
                          rules={[
                            {
                              required: true,
                              message: "Гүйлгээний утга бичнэ үү",
                            },
                          ]}
                        >
                          <Input.TextArea showCount maxLength={100} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16} align="bottom">
                      <Col className="gutter-row" span={6}>
                        <Form.Item
                          name="rfid"
                          label="Сурагчийн картын дугаар"
                          rules={[
                            {
                              required: true,
                              message: "Картын дугаар оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item>
                          <Popconfirm
                            title="Гүйлгээ хийхдээ итгэлтэй байна уу?"
                            onConfirm={() => validateAndSubmit(shopForm)}
                            onCancel={() => resetForm(shopForm)}
                            okText="Тийм"
                            cancelText="Үгүй"
                          >
                            <Button
                              size="large"
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              loading={saveLoading}
                            >
                              Төлөх
                            </Button>
                          </Popconfirm>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, teacher, schoolClass } = authentication;
  return { userData: user, teacherData: teacher, schoolClass };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedShopTable = connect(mapState, actionCreators)(ShopTable);
export { connectedShopTable as ShopTable };
