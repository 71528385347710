import React, { Suspense } from "react";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { alertActions } from "./_actions";
import { RouteContainer } from "./components";
import { Login } from "./pages";
import { PreSchool } from "./pages";
import { message } from "antd";
import "react-quill/dist/quill.snow.css";
import "animate.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      collapsed: false,
      loggingIn: false,
      reason: {},
      routes: [],
    };

    history.listen((location, action) => {
      this.props.clearAlerts();
    });

    this.reasonElement = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.alert !== this.props.alert) {
      if (this.props.alert.type === "alert-danger") {
        message.error(this.props.alert.message);
      } else if (this.props.alert.type === "alert-success") {
        message.success(this.props.alert.message);
      }
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/pre-school" component={PreSchool} />
          <RouteContainer />
        </Switch>
      </Router>
    );
  }
}

function mapState(state) {
  const { alert, authentication } = state;
  return { alert, user: authentication };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(withRouter(App));
export { connectedApp as App };
