import { combineReducers } from "redux";

import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
// import { registration } from './registration.reducer';
// import { loanNumber } from './loan.reducer';
// import { libReducer } from './lib.reducer';

const rootReducer = combineReducers({
  alert,
  authentication,
  //   registration,
  //   loanNumber,
  //   libReducer,
});

export default rootReducer;
