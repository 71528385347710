import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { ReactReader, EpubView } from "react-reader";
import FilePreviewer from "react-file-previewer";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function GradeClass() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleBookRead, setVisibleBookRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [lessons, setLessons] = useState([]);
  const [currentBook, setCurrentBook] = useState("");
  const [currentExt, setExt] = useState("");
  const [currentCover, setCurrentCover] = useState("");
  const [currentBookTitle, setCurrentBookTitle] = useState("");
  const [searchValue, setSearchValue] = useState(null);

  let { year, term, classId } = useParams();

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const getGrade = (record, lessonName) => {
    console.log(record.grades);
    console.log(lessonName);
    const currentLesson = record.grades.filter(
      (item) => item.lesson === lessonName
    );
    console.log("currentLesson", currentLesson);
    if (currentLesson.length > 0) {
      return currentLesson[0].gradePercentage;
    }
    return "-";
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.get(`grade/get-class/${classId}`, {
      params: { year: year, term: term },
    }).then((result) => {
      if (result.status == 200) {
        API.get(`grade/get-lessons/`, {
          params: { year: year, term: term, classId: classId },
        }).then((resultLesson) => {
          if (resultLesson.status === 200) {
            const lessonColumns = [];
            const lessonNames = resultLesson.data.content;
            lessonNames.map((item) => {
              lessonColumns.push({
                title: item,
                dataIndex: item,
                key: item,
                render: (text, record) => <>{getGrade(record, item)}</>,
              });
            });
            setLessons(lessonColumns);
          }
        });
        setData(result.data.content);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Сурагч",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/student/${record.student.studentId}`,
            state: { classData: record },
          }}
        >
          {record.student.displayName}
        </Link>
      ),
    },
    ...lessons,
  ];

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title={`Улиралын дүн`}
        description={`${year} оны ${term} улирал`}
        right={false}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedGradeClass = connect(mapState, actionCreators)(GradeClass);
export { connectedGradeClass as GradeClass };
