import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import appInfo from "../../app-info";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Archived() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "library/books/archives",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      {
        params: {
          page: currentPage - 1,
          size: showNum,
          type: "PAPER",
          status: "ARCHIVED",
        },
      }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, type: "PAPER" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const revertArchive = (bookId) => {
    API.delete(`library/books/archive/remove/?archiveId=${bookId}`).then(
      (result) => {
        if (result.status == 200) {
          fetchData(currentPage);

          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        }
      }
    );
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return (
          <Zoom>
            <img src={appInfo.apiURL + record.book.coverPhoto} width={50} />
          </Zoom>
        );
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/book/${record.book.id}`}>{record.book.name}</Link>
      ),
    },
    {
      title: "Ангилал",
      dataIndex: "category",
      key: "category",
      render: (text, record) =>
        record.book.category !== undefined ? (
          <Link
            to={{
              pathname: `/category/${record.book.category.id}`,
              state: { categoryName: record.book.category.categoryName },
            }}
          >
            {record.book.category.categoryName}
          </Link>
        ) : (
          ""
        ),
    },
    {
      title: "ISBN",
      dataIndex: "isbn",
      key: "isbn",
      render: (text, record) => <>{record.book.isbn}</>,
    },
    {
      title: "Шалтгаан",
      dataIndex: "archiveReason",
      key: "archiveReason",
      render: (text, record) => <>{record.archiveReason}</>,
    },
    {
      title: "Тоо ширхэг",
      dataIndex: "archivedCount",
      key: "archivedCount",
      render: (text, record) => text + "ш",
    },
    {
      title: "Архив хийсэн огноо",
      dataIndex: "archivedDate",
      key: "archivedDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            <Popconfirm
              title="Буцаахдаа итгэлтэй байна уу?"
              onConfirm={() => revertArchive(record.id)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-backup-restore"></i>
              </button>
            </Popconfirm>
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => confirm(record.id)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const confirm = (bookId) => {
    API.delete(`library/books/archive/delete/?archiveId=${bookId}`).then(
      (result) => {
        if (result.status == 200) {
          fetchData(currentPage);

          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        }
      }
    );
  };

  const registerBook = (values) => {
    API.post("library/books/add", {
      name: values.name,
      isbn: values.isbn,
      authorName: values.authorName,
      publishedPlace: values.publishedPlace,
      publicationDate: moment(values.publicationDate).format("YYYY-MM-DD"),
      schoolRegisteredDate: moment(values.schoolRegisteredDate).format(
        "YYYY-MM-DD"
      ),
      note: values.note,
      bookCondition: values.bookCondition,
      categoryId: values.category,
      bookType: "EBOOK",
    }).then((result) => {
      if (result.status === 200) {
        const response = result.data.content;
        var formData = new FormData();
        formData.set("file", values.book[0].originFileObj);
        formData.set("id", response.id);
        formData.set("type", "FILE");
        formData.set("entity", "LIBRARY");

        API.post("file/upload", formData).then((resultFile) => {
          if (resultFile.status === 200) {
            formData.set("file", values.cover[0].originFileObj);
            formData.set("type", "COVER_PHOTO");
            API.post("file/upload", formData).then((resultCover) => {
              if (resultCover.status === 200) {
                form.resetFields();
                fetchData(currentPage);
                setVisible(false);
              }
            });
          }
        });
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title="Номын архив"
        description="Системийн нийт номын жагсаалт"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form layout="inline" style={{ marginBottom: 10 }}>
                        <Form.Item name="type" label="Талбар">
                          <Select
                            defaultValue={searchKey}
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="name">Номын нэрээр</Option>
                            <Option value="authorName">Номын зохиолчоор</Option>
                            <Option value="isbn">ISBN</Option>
                            <Option value="publicationDate">
                              Хэвлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Номын нэр">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedArchived = connect(mapState, actionCreators)(Archived);
export { connectedArchived as Archived };
