import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  PlusOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Switch,
  Slider,
  Upload,
  Rate,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
  Typography,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function StudentProfile({ data, userData }) {
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const [changeClass] = Form.useForm();
  let { studentId } = useParams();
  const [classes, setClasses] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [teachers, setTeachers] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [isCanExchange, setIsCanExchange] = useState(false);
  const [searchKey, setSearchKey] = useState("displayName");
  const [currentClass, setCurrentClass] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      lastName: data.surname,
      firstName: data.givenName,
      registerNumber: data.registerNumber,
      email: data.microsoftMail,
    });
  }, [data]);

  const fetchData = () => {
    let teacherId = null;

    if (
      userData.userRoles.includes("ROLE_ADMIN") ||
      userData.userRoles.includes("ROLE_EMPLOYEE")
    ) {
      setIsCanExchange(true);
    }
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, teacherId: teacherId } }
    ).then((result) => {
      if (result.status === 200) {
        setClasses(result.data.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = (values) => {
    console.log("Finish:", values);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onChangePassword = (values) => {
    setLoading(true);
    API.put(`users/change-password`, {
      id: data.user.id,
      newPassword: values.password,
      isDashboard: true,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          changePasswordForm.resetFields();
          notification["success"]({
            message: result.data.message,
            description: "Сурагчийн нууц үг амжилттай солигдлоо",
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
      setLoading(false);
    });
  };

  const onChangeClass = (values) => {
    let className = classes.filter((item) => item.classId == values.classId);
    confirm({
      title: "Анги шилжүүлэх үү?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          Сурагч <span style={{ color: "red" }}>{data.displayName}</span>-ийг{" "}
          <span style={{ color: "red" }}>{className[0].displayName}</span>-руу
          шилжүүлэх үү?
        </>
      ),
      okText: "Шилжүүлэх",
      cancelText: "Болих",
      onOk() {
        setLoading(true);
        API.post(`students/change-class`, {
          studentId: studentId,
          classId: values.classId,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              changeClass.resetFields();
              notification["success"]({
                message: result.data.message,
                description: "Сурагчийг амжилттай шилжүүллээ",
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
          setLoading(false);
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form {...formItemLayout} form={form} onFinish={onFinish}>
          <Form.Item
            name="cover"
            label="Профайл зураг"
            valuePropName="fileList"
            getValueFromEvent={normCover}
            rules={[
              {
                required: true,
                message: "Зураг сонгоно уу!",
              },
            ]}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Овог"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Овог оруулна уу!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Нэр"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Нэр оруулна уу!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Регистрийн дугаар"
            name="registerNumber"
            rules={[
              {
                required: true,
                message: "Регистрийн дугаар оруулна уу!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Имэйл хаяг"
            name="email"
            rules={[
              {
                required: true,
                message: "Имэйл хаяг оруулна уу!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </Form>

        <Row>
          <Col span={14} offset={6}>
            <h6>Нууц үг солих</h6>
          </Col>
        </Row>

        <Form
          {...formItemLayout}
          form={changePasswordForm}
          onFinish={onChangePassword}
        >
          <Form.Item
            label="Шинэ нууц үг"
            name="password"
            rules={[
              {
                required: true,
                message: "Нууц үг оруулна уу!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Нууц үг солих
            </Button>
          </Form.Item>
        </Form>

        <Row>
          <Col span={14} offset={6}>
            <h6>Анги шилжих</h6>
          </Col>
        </Row>

        {isCanExchange ? (
          <Form {...formItemLayout} form={changeClass} onFinish={onChangeClass}>
            <Form.Item
              label="Шилжүүлэх анги"
              name="classId"
              rules={[
                {
                  required: true,
                  message: "Шилжүүлэх анги сонгоно уу!",
                },
              ]}
            >
              <Select placeholder="Сонгоно уу" showSearch allowClear>
                {classes.map((cat) => (
                  <Option value={cat.classId} key={cat.classId}>
                    {cat.displayName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="danger" htmlType="submit">
                Анги шилжүүлэх
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Spin>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { userData: user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentProfile = connect(
  mapState,
  actionCreators
)(StudentProfile);
export { connectedStudentProfile as StudentProfile };
