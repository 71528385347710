import React, { useState, useEffect, useRef } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  Space,
  Button,
  DatePicker,
  Form,
  Select,
  Spin,
  Input,
  Tag,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";
import { PageTitle, Pagination } from "../../components";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { Option } = Select;
const { RangePicker } = DatePicker;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function PreSchoolTransactions() {
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const checkPayment = (paymentNumber) => {
    setLoading(true);

    API.get("kinder-garden/check-by-number", {
      params: {
        paymentNumber: paymentNumber,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            form.submit();
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const invalidate = (paymentNumber) => {
    setLoading(true);

    API.get("kinder-garden/invalidate", {
      params: {
        paymentNumber: paymentNumber,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            form.submit();
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const getStatusName = (status) => {
    switch (status) {
      case "PAID":
        return <span style={{ color: "green" }}>Амжилттай</span>;
      case "INVOICE_CREATED":
        return <span style={{ color: "orange" }}>Нэхэмжлэл үүсэн</span>;
      default:
        return <span style={{ color: "red" }}>Төлөөгүй</span>;
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Нэхэмжлэл үүссэн",
          value: "INVOICE_CREATED",
        },
        {
          text: "Амжилттай",
          value: "PAID",
        },
        {
          text: "Төлөөгүй",
          value: "UNPAID",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => getStatusName(text),
    },
    {
      title: "Дугаар",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Гар шалгалт",
      dataIndex: "verfied",
      key: "verfied",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24 }} />
        ),
    },
    {
      title: "И-Баримт статус",
      dataIndex: "vatStatus",
      key: "vatStatus",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 24 }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: 24 }}
            />
          )
        ) : (
          <span>~</span>
        ),
    },
    {
      title: "И-Баримт имэйл",
      dataIndex: "vatClientEmail",
      key: "vatClientEmail",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <>{text}</>
          ) : (
            <span>~</span>
          )
        ) : (
          <span>~</span>
        ),
    },
    {
      title: "Регистер",
      dataIndex: "regNum",
      key: "regNum",
      align: "start",
    },
    {
      title: "И-Баримт илгээсэн эсэх",
      dataIndex: "vatEmailed",
      key: "vatEmailed",
      align: "start",
      render: (text, record) =>
        record?.kinderGardenInvoice ? (
          text ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 24 }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: 24 }}
            />
          )
        ) : (
          <span>~</span>
        ),
    },

    {
      title: "Шалгах",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.status === "PAID" ? (
          <span style={{ color: "green" }}>Амжилттай</span>
        ) : (
          <Button type="primary" onClick={() => checkPayment(record.number)}>
            Шалгах
          </Button>
        ),
    },
    {
      title: "Дахин үүсгэх",
      dataIndex: "invalidate",
      key: "invalidate",
      render: (text, record) =>
        record?.status === "PAID" && record?.vatStatus === "FAILED" ? (
          <Button type="danger" onClick={() => invalidate(record?.number)}>
            Дахин үүсгэх
          </Button>
        ) : (
          <>~</>
        ),
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    API.post("payment/preschool-transactions-dashboard", null, {
      params: {
        startDate: moment(values.date[0]).format("YYYY/MM/DD 00:00"),
        endDate: moment(values.date[1]).format("YYYY/MM/DD 23:59"),
        page: currentPage - 1,
        size: showNum,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);

    API.post("payment/preschool-transactions-dashboard", null, {
      params: {
        startDate: moment(form.getFieldValue("date")[0]).format(
          "YYYY/MM/DD 00:00"
        ),
        endDate: moment(form.getFieldValue("date")[1]).format(
          "YYYY/MM/DD 23:59"
        ),
        page: currentPage - 1,
        size: showNum,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num + 1);
  };

  return (
    <>
      <PageTitle
        title="Гүйлгээний түүх"
        description="Бэлтгэл бүлгийн бүртгэлийн гүйлгээний түүх"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Spin spinning={loading}>
                    <div className="row">
                      <div className="col-lg-8">
                        <Form
                          form={form}
                          layout="inline"
                          style={{ marginBottom: 10 }}
                          initialValues={{
                            date: [moment().subtract(1, "days"), moment()],
                          }}
                          onFinish={onFinish}
                        >
                          <Form.Item
                            name="date"
                            label="Хугацаа"
                            rules={[
                              {
                                required: true,
                                message: "Хугацаа сонгоно уу!",
                              },
                            ]}
                          >
                            <RangePicker />
                          </Form.Item>
                          <Form.Item {...tailLayout}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                            >
                              Харах
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                      <div className="col-lg-4 text-right">
                        <Select
                          defaultValue="100"
                          onChange={(val) => setShowNum(val)}
                        >
                          <Option value={10}>10</Option>
                          <Option value={20}>20</Option>
                          <Option value={30}>30</Option>
                          <Option value={40}>40</Option>
                          <Option value={50}>50</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </div>
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          <Table
                            className="table table-centered table-hover mb-0"
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            rowKey="id"
                          />
                        </div>
                      </div>
                    </div>
                  </Spin>
                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTransactions = connect(
  mapState,
  actionCreators
)(PreSchoolTransactions);
export { connectedTransactions as PreSchoolTransactions };
