import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
  Typography,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function TeacherFoodDetail({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [searchBook] = Form.useForm();
  const [studentBook] = Form.useForm();
  const [isTeacher, setIsTeacher] = useState(false);
  const [data, setData] = useState([]);
  const [books, setBooks] = useState([]);
  const [students, setStudents] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supplyId, setSupplyId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("bookName");
  const [searchValue, setSearchValue] = useState(null);
  const [count, setCount] = useState(1);

  useEffect(() => {
    getClasses();

    if (
      userData.userRoles.includes("ROLE_ADMIN") ||
      userData.userRoles.includes("ROLE_EMPLOYEE")
    ) {
      setIsAdmin(true);
    }

    if (
      !userData.userRoles.includes("ROLE_EMPLOYEE") &&
      userData.userRoles.includes("ROLE_TEACHER")
    ) {
      setIsTeacher(true);
    }
  }, []);

  const getClasses = () => {
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: 50 } }
    ).then((result) => {
      if (result.status === 200) {
        setCategories(result.data.content);
      }
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    API.get(`kitchen/teacher/assigned-list`, {
      params: {
        startDate: moment(values.date).format("YYYY/MM/DD"),
        endDate: moment(values.date)
          .startOf("date")
          .add(23, "hours")
          .format("YYYY/MM/DD HH:mm"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content);
        }
        setLoading(false);
      }
    });
  };
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Багшийн нэр",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => <>{record.teacher.displayName}</>,
    },
    {
      title: "Идсэн тоо",
      dataIndex: "count",
      key: "count",
    },
    isAdmin
      ? {
          title: "Үйлдэл",
          key: "action",
          render: (text, record) => (
            <Space size="middle">
              <Popconfirm
                title="Буцаах уу?"
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <a href="#" style={{ color: "red" }}>
                  Буцаах
                </a>
              </Popconfirm>
            </Space>
          ),
        }
      : [],
  ];

  const confirm = (bookId) => {
    API.post("kitchen/teacher/remove-food-count", {
      supplyId: bookId,
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
          form.submit();
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }
      }
    });
  };

  const cancel = (e) => {
    console.log(e);
  };

  return (
    <>
      <PageTitle
        title="Багшийн хоол идсэн түүх"
        description="Багшийн хоол идсэн түүх"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        onFinish={onFinish}
                        form={form}
                      >
                        <Form.Item
                          name="date"
                          label="Өдөр"
                          rules={[
                            {
                              required: true,
                              message: "Өдөр сонгоно уу!",
                            },
                          ]}
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Харах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user, schoolClass } = authentication;
  return { userData: user, users, schoolClass };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTeacherFoodDetail = connect(
  mapState,
  actionCreators
)(TeacherFoodDetail);
export { connectedTeacherFoodDetail as TeacherFoodDetail };
