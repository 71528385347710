import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

function AllClass({ userData, teacherData }) {
  const [form] = Form.useForm();
  const [searchTeacher] = Form.useForm();
  const [data, setData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchKey, setSearchKey] = useState("displayName");
  const [currentClass, setCurrentClass] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let teacherId = null;

    if (
      !userData.userRoles.includes("ROLE_EMPLOYEE") &&
      userData.userRoles.includes("ROLE_TEACHER")
    ) {
      teacherId = teacherData.teacherId;
    }
    if (userData.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, teacherId: teacherId } }
    ).then((result) => {
      if (result.status === 200) {
        setData(result.data.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Анги",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/class-item/${record.classId}`,
            state: { classData: record },
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Багш",
      dataIndex: "teacher",
      key: "teacher",
      render: (text, row, index) =>
        text !== null ? (
          <>
            <span style={{ color: "black" }}>{text.displayName}</span>
            {isAdmin ? (
              <Button
                type="link"
                style={{ color: "green" }}
                onClick={() => setTeacher(row.classId)}
              >
                Багш оноох
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>Багш сонгоогүй</span>
            {isAdmin ? (
              <Button
                type="link"
                style={{ color: "green" }}
                onClick={() => setTeacher(row.classId)}
              >
                Багш оноох
              </Button>
            ) : null}
          </>
        ),
    },
  ];

  const setTeacher = (classId) => {
    setCurrentClass(classId);
    setVisible(true);
  };

  const confirm = (userId) => {
    deleteUser(userId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setTeachers([]);
    searchTeacher.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        API.post("class/assign-teacher", {
          teacherId: values.teacherId,
          classId: currentClass,
        }).then((result) => {
          if (result.status == 200) {
            fetchData();
            setVisible(false);
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const deleteUser = (userId) => {
    API.delete(`user/delete/${userId}`).then((result) => {
      if (result.status == 200) {
        fetchData();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const onFinish = () => {
    let teacherId = null;

    if (
      !userData.userRoles.includes("ROLE_EMPLOYEE") &&
      userData.userRoles.includes("ROLE_TEACHER")
    ) {
      teacherId = teacherData.teacherId;
    }
    setLoading(true);
    API.post(
      "class/all",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum, teacherId: teacherId } }
    ).then((result) => {
      if (result.status === 200) {
        setData(result.data.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onSearchTeacher = (values) => {
    setSearchLoading(true);
    API.post(
      "teacher/list",
      {
        key: values.searchType,
        value: values.searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: 30 } }
    ).then((result) => {
      if (result.status == 200) {
        setSearchLoading(false);
        setTeachers(result.data.content.content);
        form.setFieldsValue({ bookId: [] });
      }
    });
  };

  return (
    <>
      <PageTitle title="Ангийн жагсаалт" description="Нийт ангийн жагсаалт" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="displayName">Ангийн нэрээр</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Ангийн нэр">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Багш оноох"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        okText="Нэмэх"
        cancelText="Болих"
      >
        <Form
          {...formItemLayout}
          form={searchTeacher}
          onFinish={onSearchTeacher}
          initialValues={{ searchType: "givenName" }}
        >
          <Form.Item
            label="Хайх талбар"
            name="searchType"
            rules={[{ required: true, message: "Хайх талбар оруулна уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value="givenName">Өөрийн нэр</Option>
              <Option value="mail">Имэйл</Option>
              <Option value="phone">Утасны дугаар</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Хайх утга"
            name="searchValue"
            rules={[{ required: true, message: "Хайх утга оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={searchLoading}>
              Хайх
            </Button>
          </Form.Item>
        </Form>
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label={`Багш (${teachers.length > 0 ? teachers.length : 0})`}
            name="teacherId"
            rules={[{ required: true, message: "Багш сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {teachers.map((teacher) => (
                <Option key={teacher.teacherId} value={teacher.teacherId}>
                  {teacher.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, teacher } = authentication;
  return { userData: user, teacherData: teacher };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedAllClass = connect(mapState, actionCreators)(AllClass);
export { connectedAllClass as AllClass };
