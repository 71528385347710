import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { useLocation, useHistory } from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  notification,
  Upload,
  message,
  TreeSelect,
  Button,
  Form,
  Select,
  Input,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function BookCategoryEdit() {
  const [form] = Form.useForm();
  let history = useHistory();
  const [data, setData] = useState([]);

  const [currentCover, setCurrentCover] = useState(10);
  const [coverVisible, setCoverVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [categoryData, setCategoryData] = useState({ categoryName: "" });

  let location = useLocation();

  useEffect(() => {
    setCategoryData(location.state.categoryData);
  }, [location]);

  useEffect(() => {
    fetchData();
  }, [categoryData]);

  const fetchData = () => {
    // setLoading(true);
    console.log("categoryData", categoryData);
    form.setFieldsValue(categoryData);
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setData(result.data.content);
        // setLoading(false);
      }
    });
  };

  const getStageName = (name) => {
    switch (name) {
      case "High":
        return "Ахлах";
      case "Middle":
        return "Дунд";
      case "Elementary":
        return "Бага";
      default:
        return "Нийтийн";
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreviewCover = (file) => {
    console.log(file);
    setCoverVisible(true);
    setCurrentCover(file.url);
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (values) => {
    API.put("library/books/category/edit", {
      id: categoryData.id,
      categoryName: values.categoryName,
      parentId: values.parentId,
      // stage: values.stage,
    }).then((result) => {
      if (result.status === 200) {
        const response = result.data.content;
        var formData = new FormData();
        formData.set("file", values.cover[0].originFileObj);
        formData.set("id", response.id);
        formData.set("type", "COVER_PHOTO");
        formData.set("entity", "LIBRARY_BOOK_CATEGORY");

        API.post("file/upload", formData).then((resultFile) => {
          if (resultFile.status === 200) {
            history.push("/book-category");

            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });

            fetchData();
            form.resetFields();
          }
        });
      }
    });
  };

  return (
    <>
      <PageTitle
        title={categoryData.categoryName}
        description="Системийн нийт ангиллын жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Ангилал нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    {...formItemLayout}
                    form={form}
                    initialValues={categoryData}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      label="Ангилалын нэр"
                      name="categoryName"
                      rules={[
                        {
                          required: true,
                          message: "Ангилалын нэр оруулна уу!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Дэд ангилал" name="parentId">
                      <TreeSelect
                        treeData={data}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Сонгоно уу"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        allowClear
                        treeDefaultExpandAll
                        treeNodeLabelProp="categoryName"
                        multiple={false}
                        showCheckedStrategy="SHOW_PARENT"
                      />
                    </Form.Item>
                    <Form.Item name="stage" label="Төрөл">
                      <Select>
                        <Option value="Elementary">Бага анги</Option>
                        <Option value="Middle">Дунд анги</Option>
                        <Option value="High">Ахлах анги</Option>
                        <Option value="PUBLIC">Нийтийн</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="cover"
                      label="Зураг"
                      valuePropName="fileList"
                      getValueFromEvent={normCover}
                      rules={[
                        {
                          required: true,
                          message: "Зураг сонгоно уу!",
                        },
                      ]}
                    >
                      <Upload
                        customRequest={dummyRequest}
                        listType="picture"
                        onPreview={handlePreviewCover}
                      >
                        <Button>
                          <UploadOutlined /> сонгох
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Button type="primary" htmlType="submit">
                        Хадгалах
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedBookCategoryEdit = connect(
  mapState,
  actionCreators
)(BookCategoryEdit);
export { connectedBookCategoryEdit as BookCategoryEdit };
