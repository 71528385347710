import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { history } from "../../App";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { userActions, libActions } from "../../_actions";
import { Form, Input, Button, Checkbox } from "antd";

function Login(props) {
  useEffect(() => {
    props.logout();
  }, []);

  const [form] = Form.useForm();
  const [buttonLoading, setLoading] = useState(false);
  const [loginFormHidden, setloginFormHidden] = useState(false);
  const [googleFormHidden, setGoogleFormHidden] = useState(true);
  const [confirmation, setConfirmation] = useState();
  const [qrimage, setQrimage] = useState();

  const onFinish = (values) => {
    const { username, password } = values;
    // setLoading(true);
    if (username && password) {
      props.login(username, password).then((response) => {
        // props.getDropdownLib();
        setLoading(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const inputChanged = (e) => {
    if (e.target.value) {
      setConfirmation(e.target.value);
    }
  };

  return (
    <div className="bg-primary bg-pattern vh-100">
      <div className="account-pages pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5 mt-5 text-center">
              <img
                src={require("../../assets/images/logo-white.png")}
                height="120"
                alt="logo"
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-5 col-sm-8">
              <div className="card">
                <div className="card-body p-4">
                  <div className="p-2">
                    <h5 className="mb-5 text-center">
                      Оюунлаг системд нэвтрэх
                    </h5>
                    <Form
                      className="form-horizontal"
                      form={form}
                      name="login-form"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      layout="vertical"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <Form.Item
                              label="Нэвтрэх нэр"
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Хэрэглэгчийн нэрээ оруулна уу!",
                                },
                              ]}
                            >
                              <Input type="text" />
                            </Form.Item>
                          </div>

                          <div className="form-group mb-4">
                            <Form.Item
                              label="Нууц үг"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Нууц үгээ оруулна уу!",
                                },
                              ]}
                            >
                              <Input.Password />
                            </Form.Item>
                          </div>

                          {/* <div className="row">
                            <div className="col-md-6">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customControlInline"
                                >
                                  Намайг сана
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-md-right mt-3 mt-md-0">
                                <a href="#" className="text-muted">
                                  <i className="mdi mdi-lock"></i> Нууц үгээ
                                  мартсан
                                </a>
                              </div>
                            </div>
                          </div> */}
                          <div className="mt-4">
                            <Button
                              type="primary"
                              size="large"
                              className={
                                "btn btn-success btn-block waves-effect waves-light"
                              }
                              htmlType="submit"
                              loading={buttonLoading}
                            >
                              Нэвтрэх
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="version-code">v1.5.2</p>
    </div>
  );
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  return { loggedIn };
}

const actionCreators = {
  login: userActions.login,
  getUserData: userActions.getUserData,
  logout: userActions.logout,
  getDropdownLib: libActions.getDropdownLib,
};

const connectedLogin = connect(mapState, actionCreators)(Login);

export { connectedLogin as Login };
