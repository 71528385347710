import {
  Users,
  Home,
  Library,
  BookCategory,
  Category,
  Book,
  Profile,
  Archived,
  BookRent,
  Students,
  BookReturn,
  FeedBack,
  Teachers,
  AllClass,
  Drivers,
  VendingTransactions,
  ParentalRequest,
  StudentRoutes,
  Settings,
  ParentalList,
  Grade,
  Kitchen,
  UsersDeactivated,
  Parents,
  Products,
  KitchenHistory,
  Schedule,
  KitchenAssign,
  KitchenServedHistory,
  KitchenSupplyHistory,
  DriverRouteHistory,
  TeacherFoodDetail,
  Revision,
  Monthly,
  Transactions,
  PreSchoolTransactions,
  TeachersTimeTable,
  VendingProducts,
  AndroidVendingProducts,
  Attendance,
  VendingTable,
  AndroidVendingTable,
  NeighbourChat,
  ShopTable,
  Events,
  Statistics,
} from "./pages";

// Номын санчын меню
export const librarianNav = [
  {
    path: "/library-parent",
    component: Library,
    label: "Номын сан",
    showHeader: true,
    icon: "book-open",
    childrens: [
      {
        path: "/book-rent",
        component: BookRent,
        label: "Ном өгөх",
        showHeader: true,
        icon: "rent",
      },
      {
        path: "/book-return",
        component: BookReturn,
        label: "Хүлээж авсан түүх",
        showHeader: true,
        icon: "history",
      },
      {
        path: "/library-ebook",
        component: Library,
        label: "Е-Ном",
        showHeader: true,
        icon: "tablet",
      },
      {
        path: "/library",
        component: Library,
        label: "Цаасан ном",
        showHeader: true,
        icon: "book-open",
      },
      {
        path: "/book-category",
        component: BookCategory,
        label: "Ангилалын жагсаалт",
        showHeader: true,
        icon: "book-list",
      },
      {
        path: "/archived-books",
        component: Archived,
        label: "Архив",
        showHeader: true,
        icon: "archive",
      },
    ],
  },
];
export const vendingNav = [
  {
    path: "/vending-products-2",
    component: AndroidVendingProducts,
    label: "Вендинг-2",
    showHeader: true,
    icon: "food",
    childrens: [
      {
        path: "/vending-products",
        component: VendingProducts,
        label: "Бараа",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/vending-table",
        component: VendingTable,
        label: "Бараа тохируулах",
        showHeader: true,
        icon: "food",
      },
    ],
  },
  {
    path: "/vending-products-2",
    component: AndroidVendingProducts,
    label: "Вендинг-2",
    showHeader: true,
    icon: "food",
    childrens: [
      {
        path: "/vending-products-2",
        component: AndroidVendingProducts,
        label: "Бараа-2",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/vending-table-2",
        component: AndroidVendingTable,
        label: "Бараа тохируулах-2",
        showHeader: true,
        icon: "food",
      },
    ],
  },
];

export const shopNav = [
  {
    path: "/shop-table",
    component: ShopTable,
    label: "Бараа зарах",
    showHeader: true,
    icon: "shop",
  },
  {
    path: "/shop-transactions",
    exact: true,
    component: VendingTransactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "history",
  },
];

export const teacherNav = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: Library,
  //   label: "Эхлэл",
  //   showHeader: true,
  //   icon: "airplay",
  // },
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "book-reader",
  },
  {
    path: "/news",
    component: Library,
    label: "Мэдээ",
    showHeader: true,
    icon: "news-paper",
  },
  {
    path: "/events",
    component: Library,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "news-paper",
  },
  {
    path: "/kitchen-assign",
    component: KitchenAssign,
    label: "Хоолны тоо",
    showHeader: true,
    icon: "food",
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "pen",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Ангийн Хоолны түүх",
    showHeader: true,
    icon: "food",
  },
];

export const employeeNav = [
  // Transaction history
  {
    path: "/transactions",
    component: Transactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "transaction",
    childrens: [
      {
        path: "/transactions",
        exact: true,
        component: Transactions,
        label: "Нийт гүйлгээ",
        showHeader: true,
        icon: "transaction",
      },
      {
        path: "/pre-shool-transactions",
        component: PreSchoolTransactions,
        label: "Бэлтгэл ангийн гүйлгээ",
        showHeader: true,
        icon: "transaction",
      },
    ],
  },
  {
    path: "/feedback",
    component: FeedBack,
    label: "Санал хүсэлт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/parental-request",
    component: ParentalRequest,
    label: "Хүүхэд нэмүүлэх хүсэлтүүд",
    showHeader: true,
    icon: "user-add",
  },
  {
    path: "/parental-list",
    component: ParentalList,
    label: "Холбогдсон хүүхдүүд",
    showHeader: true,
    icon: "user-add",
  },
  {
    path: "/parents",
    component: Parents,
    label: "Эцэг эх",
    showHeader: true,
    icon: "users-alt",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Ангийн Хоолны түүх",
    showHeader: true,
    icon: "food",
  },
  {
    path: "/settings",
    component: Settings,
    label: "Тохиргоо",
    showHeader: true,
    icon: "settings",
  },
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "book-reader",
  },
  {
    path: "/news",
    component: Library,
    label: "Мэдээ",
    showHeader: true,
    icon: "news-paper",
  },
  {
    path: "/events",
    component: Library,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "news-paper",
  },
  {
    path: "/kitchen-assign",
    component: KitchenAssign,
    label: "Хоолны тоо",
    showHeader: true,
    icon: "food",
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "pen",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "report",
  },
];

export const kitchenNav = [
  {
    path: "/kitchen",
    exact: true,
    component: Kitchen,
    label: "Хоолны тоо",
    showHeader: true,
    icon: "airplay",
  },
  {
    path: "/kitchen-served-history",
    exact: true,
    component: KitchenServedHistory,
    label: "Хоол гарсан түүх",
    showHeader: true,
    icon: "airplay",
  },
  {
    path: "/kitchen-supply-history",
    exact: true,
    component: KitchenSupplyHistory,
    label: "Ангийн тооны түүх",
    showHeader: true,
    icon: "airplay",
  },
];

export const adminNav = [
  // {
  //   path: "/",
  //   component: Home,
  //   label: "Эхлэл",
  //   showHeader: true,
  //   icon: "shop",
  // },
  {
    path: "/statistics",
    component: Statistics,
    label: "Статистик",
    showHeader: true,
    icon: "shop",
  },
  // Transaction history
  {
    path: "/transactions",
    component: Transactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "transaction",
    childrens: [
      {
        path: "/transactions",
        exact: true,
        component: Transactions,
        label: "Нийт гүйлгээ",
        showHeader: true,
        icon: "transaction",
      },
      {
        path: "/pre-shool-transactions",
        component: PreSchoolTransactions,
        label: "Бэлтгэл ангийн гүйлгээ",
        showHeader: true,
        icon: "transaction",
      },
    ],
  },
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "book-reader",
  },
  {
    path: "/teachers",
    component: Teachers,
    label: "Багш",
    showHeader: true,
    icon: "briefcase",
  },
  {
    path: "/parents",
    component: Parents,
    label: "Эцэг эх",
    showHeader: true,
    icon: "users-alt",
  },

  {
    path: "/users",
    component: Users,
    label: "Хэрэглэгчид",
    showHeader: true,
    icon: "users",
    childrens: [
      {
        path: "/users",
        component: Users,
        label: "Системийн хэрэглэгчид",
        showHeader: true,
        icon: "users",
      },
      {
        path: "/deactive-users",
        component: UsersDeactivated,
        label: "Идэвхгүй хэрэглэгчид",
        showHeader: true,
        icon: "users",
      },
    ],
  },

  {
    path: "/drivers",
    component: Drivers,
    label: "Автобус / Жолооч",
    showHeader: true,
    icon: "bus",
    childrens: [
      {
        path: "/drivers",
        component: Drivers,
        label: "Автобусны чиглэл",
        showHeader: true,
        icon: "bus",
      },
      {
        path: "/driver-route-history",
        component: DriverRouteHistory,
        label: "Жолоочийн түүх",
        showHeader: true,
        icon: "profile",
      },
    ],
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "pen",
  },
  {
    path: "/parental-request",
    component: ParentalRequest,
    label: "Хүүхэд нэмүүлэх хүсэлтүүд",
    showHeader: true,
    icon: "user-add",
  },
  {
    path: "/parental-list",
    component: ParentalList,
    label: "Холбогдсон хүүхдүүд",
    showHeader: true,
    icon: "user-add",
  },
  {
    path: "/news",
    component: Library,
    label: "Мэдээ",
    showHeader: true,
    icon: "news-paper",
  },
  {
    path: "/events",
    component: Library,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "news-paper",
  },
  // {
  //   path: "/finance",
  //   component: Library,
  //   label: "Санхүү",
  //   showHeader: true,
  //   icon: "calculator",
  // },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  // {
  //   path: "/report",
  //   component: Library,
  //   label: "Тайлан",
  //   showHeader: true,
  //   icon: "report",
  // },
  {
    path: "/products",
    component: Products,
    label: "Бүтээгдэхүүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/attendance",
    component: Attendance,
    label: "Ирц",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Гал тогоо",
    showHeader: true,
    icon: "food",
    childrens: [
      {
        path: "/kitchen-history",
        component: KitchenHistory,
        label: "Ангийн Хоолны түүх",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/kitchen-supply-history",
        exact: true,
        component: KitchenSupplyHistory,
        label: "Ангийн тооны түүх",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/teacher-attendance",
        exact: true,
        component: TeachersTimeTable,
        label: "Багш нарын түүх",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/teacher-detail",
        exact: true,
        component: TeacherFoodDetail,
        label: "Багшийн дэлгэрэнгүй",
        showHeader: true,
        icon: "food",
      },
    ],
  },
  {
    path: "/vending-products",
    component: VendingProducts,
    label: "Вендинг",
    showHeader: true,
    icon: "food",
    childrens: [
      {
        path: "/vending-products",
        component: VendingProducts,
        label: "Бараа",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/vending-table",
        component: VendingTable,
        label: "Бараа тохируулах",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/vending-transactions",
        exact: true,
        component: VendingTransactions,
        label: "Гүйлгээний түүх",
        showHeader: true,
        icon: "transaction",
      },
    ],
  },
  {
    path: "/vending-products-2",
    component: AndroidVendingProducts,
    label: "Вендинг-2",
    showHeader: true,
    icon: "food",
    childrens: [
      {
        path: "/vending-products-2",
        component: AndroidVendingProducts,
        label: "Бараа-2",
        showHeader: true,
        icon: "food",
      },
      {
        path: "/vending-table-2",
        component: AndroidVendingTable,
        label: "Бараа тохируулах-2",
        showHeader: true,
        icon: "food",
      },
    ],
  },

  {
    path: "/shop-table",
    component: ShopTable,
    label: "Дэлгүүр",
    showHeader: true,
    icon: "shop",
    childrens: [
      {
        path: "/shop-table",
        component: ShopTable,
        label: "Дэлгүүрийн бараа зарах",
        showHeader: true,
        icon: "shop",
      },
      {
        path: "/shop-transactions",
        exact: true,
        component: VendingTransactions,
        label: "Дэлгүүрийн гүйлгээний түүх",
        showHeader: true,
        icon: "history",
      },
    ],
  },
  {
    path: "/neighbour",
    exact: true,
    component: NeighbourChat,
    label: "Чат",
    showHeader: true,
    icon: "history",
  },
  {
    path: "/feedback",
    component: FeedBack,
    label: "Санал хүсэлт",
    showHeader: true,
    icon: "settings",
  },
  {
    path: "/settings",
    component: Settings,
    label: "Тохиргоо",
    showHeader: true,
    icon: "settings",
  },
];

export const accountantNav = [
  {
    path: "/attendance",
    component: Attendance,
    label: "Ирц",
    showHeader: true,
    icon: "report",
  },
];
