import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Upload,
  Spin,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import appInfo from "../../app-info";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

function BookRent() {
  const [form] = Form.useForm();
  const [searchBook] = Form.useForm();
  const [studentBook] = Form.useForm();

  const [data, setData] = useState([]);
  const [books, setBooks] = useState([]);
  const [students, setStudents] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);
  const [count, setCount] = useState(1);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  useEffect(() => {
    getCategories();
    fetchData(currentPage);
  }, []);

  const getCategories = () => {
    API.get("library/books/category/list").then((result) => {
      if (result.status == 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "library/books/orders",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      {
        params: { page: currentPage - 1, size: showNum, status: "WITHDRAWN" },
      }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "library/books/orders",
      {
        key: searchKey,
        value: searchValue,
        operation: "JOIN",
        joinName: "bookId",
        joinClarification: "BOOKORDER_AND_BOOK",
      },
      { params: { page: 0, size: showNum, status: "WITHDRAWN" } }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setTotalPages(result.data.content.totalPages);
        setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const getDisplayName = (record) => {
    let displayName = "-";
    if (record.bookingOrderClientType === "STUDENT") {
      if (record.student !== null) {
        if (record.student.displayName !== null) {
          displayName = record.student.displayName;
        }
      } else {
        displayName = "Устсан";
      }
    } else {
      if (record.client !== null) {
        if (record.client.displayName !== null) {
          displayName = record.client.displayName;
        }
      } else {
        displayName = "Устсан";
      }
    }

    return displayName;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хавтас",
      dataIndex: "coverPhoto",
      key: "coverPhoto",
      render: (text, record) => {
        return (
          <Zoom>
            <img src={appInfo.apiURL + record.book.coverPhoto} width={50} />
          </Zoom>
        );
      },
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/book/${record.bookId}`}>{record.book.name}</Link>
      ),
    },
    {
      title: "Авсан хүн",
      dataIndex: "isbn",
      key: "isbn",
      render: (text, record) => getDisplayName(record),
    },
    {
      title: "Тоо ширхэг",
      dataIndex: "requestedCount",
      key: "requestedCount",
    },
    {
      title: "Авсан огноо",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Буцаах огноо",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Ном хүлээж авах уу?"
            onConfirm={() => confirm(record.id)}
            onCancel={cancel}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <a href="#">Хүлээж авах</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const confirm = (bookId) => {
    reveiveBook(bookId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const reveiveBook = (bookId) => {
    API.post(`library/books/return`, {
      bookOrderId: bookId,
    }).then((result) => {
      if (result.status == 200) {
        console.log(result);
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onWithdDraw(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  const onSearchBook = (values) => {
    API.post(
      "library/books",
      {
        key: values.searchType,
        value: values.searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: 30, type: "PAPER" } }
    ).then((result) => {
      if (result.status == 200) {
        setBooks(result.data.content.content);
        form.setFieldsValue({ bookId: [] });
      }
    });
  };

  const onSearchStudent = (values) => {
    API.post("students", {
      key: "microsoftMail",
      value: values.searchValue,
      operation: "LIKE",
    }).then((result) => {
      if (result.status == 200) {
        if (result.status == 200) {
          setStudents(result.data.content.content);
          form.setFieldsValue({ clientId: [] });
        }
      }
    });
  };

  const onWithdDraw = (values) => {
    setButtonLoading(true);
    API.post("library/books/withdraw", {
      bookId: values.bookId,
      clientId: values.clientId,
      startDate: values.startDate,
      endDate: values.endDate,
      count: values.count,
      description: values.note,
      bookingOrderClientType: "STUDENT",
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          fetchData(currentPage);
          setButtonLoading(false);
          setVisible(false);
          form.resetFields();
        }
      }
    });
  };

  const listenCount = (val) => {
    const startDate = form.getFieldValue("startDate");
    form.setFieldsValue({
      endDate: moment(startDate).add(val, "days"),
    });
    return val;
  };

  return (
    <>
      <PageTitle
        title="Ном өгөх"
        description="Ном авсан хүүхдүүдийн жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Ном өгөх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="name">Номын нэрээр</Option>
                            <Option value="authorName">Номын зохиолчоор</Option>
                            <Option value="isbn">ISBN</Option>
                            <Option value="publicationDate">
                              Хэвлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном өгөх"
        visible={visible}
        onOk={handleOk}
        confirmLoading={buttonLoading}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={searchBook}
          initialValues={{ bookCondition: "NEW" }}
          onFinish={onSearchBook}
          initialValues={{ searchType: "name" }}
        >
          <Form.Item
            label="Хайх талбар"
            name="searchType"
            rules={[{ required: true, message: "Номын нэр оруулна уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value="name">Номын нэрээр</Option>
              <Option value="authorName">Номын зохиолчоор</Option>
              <Option value="isbn">ISBN</Option>
              <Option value="publicationDate">Хэвлэгдсэн огноогоор</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Хайх утга"
            name="searchValue"
            rules={[{ required: true, message: "Хайх утга оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Form>
        <Form
          {...formItemLayout}
          form={studentBook}
          initialValues={{ bookCondition: "NEW" }}
          onFinish={onSearchStudent}
        >
          <Form.Item
            label="Сурагчийн дугаар"
            name="searchValue"
            rules={[
              { required: true, message: "Сурагчийн дугаар оруулна уу!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Form>
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ startDate: moment(), count: 1, dayCount: 1 }}
        >
          <Form.Item
            label={`Ном (${books.length > 0 ? books.length : 0})`}
            name="bookId"
            rules={[{ required: true, message: "Ном сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {books.map((book) => (
                <Option value={book.id}>{book.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Тоо ширхэг"
            name="count"
            rules={[{ required: true, message: "Тоо ширхэг оруулна уу!" }]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label={`Сурагч (${students.length > 0 ? students.length : 0})`}
            name="clientId"
            rules={[{ required: true, message: "Сурагч сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {students.map((student) => (
                <Option value={student.studentId}>
                  {student.displayName} {student.className}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Эхлэх огноо"
            name="startDate"
            rules={[{ required: true, message: "Эхлэх огноо оруулна уу!" }]}
          >
            <DatePicker
              disabledDate={(d) =>
                !d || d.isBefore(moment().subtract(1, "days"))
              }
            />
          </Form.Item>

          <Form.Item
            label="Хоног"
            name="dayCount"
            rules={[{ required: true, message: "Хоног оруулна уу!" }]}
            getValueFromEvent={(val) => listenCount(val)}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            label="Дуусах огноо"
            name="endDate"
            rules={[{ required: true, message: "Огноо оруулна уу!" }]}
          >
            <DatePicker disabled />
          </Form.Item>
          <Form.Item label="Тэмдэглэл" name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedBookRent = connect(mapState, actionCreators)(BookRent);
export { connectedBookRent as BookRent };
