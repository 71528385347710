import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import appInfo from "../../app-info";
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function News({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
    if (userData.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
    getClasses();
  }, []);

  const getClasses = () => {
    API.post(
      "class/all",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      { params: { page: 0, size: 50 } }
    ).then((result) => {
      if (result.status === 200) {
        setCategories(result.data.content);
      }
    });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "news",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      {
        params: { page: currentPage - 1, size: showNum },
      }
    ).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setCurrentPage(currentPage);
        setTotalPages(result.data.content.totalPages);
        setLoading(false);
      }
    });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "news",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum } }
    ).then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setTotalPages(result.data.content.totalPages);
          setCurrentPage(1);
          setLoading(false);
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
          setLoading(false);
        }
      }
    });
  };

  const normCover = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Зураг",
      dataIndex: "coverPath",
      key: "coverPath",
      render: (text, record) => {
        if (text !== null) {
          return <img width={100} src={appInfo.apiURL + text} />;
        }
        return "Зураггүй";
      },
    },
    {
      title: "ID",
      dataIndex: "nid",
      key: "nid",
      render: (text, record) => record.id,
    },
    {
      title: "Нийтэлсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Мэдээ",
      dataIndex: "content",
      key: "content",
      render: (text, record) => (
        <div className="news-content">{parse(text)}</div>
      ),
      width: 400,
    },
    {
      title: "Анги",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Нийтэлсэн",
      dataIndex: "createdByName",
      key: "createdByName",
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => confirm(record.id)}
            onCancel={cancel}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <a href="#">Устгах</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const confirm = (newsId) => {
    deleteNews(newsId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const deleteNews = (newsId) => {
    API.delete(`news/delete/?newsId=${newsId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerBook(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerBook = (values) => {
    setButtonLoading(true);

    API.post("news/add", {
      title: values.name,
      content: value,
      createdBy: userData.id,
      schoolClassId: values.schoolClassId,
    })
      .then((result) => {
        if (result.status === 200) {
          const response = result.data.content;
          if (values.photo.length === 0) {
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          }

          if (values.photo.length > 0) {
            var formData = new FormData();
            formData.set("file", values.photo[0].originFileObj);
            formData.set("id", response.id);
            formData.set("type", "FILE");
            formData.set("entity", "NEWS");

            API.post("file/upload", formData)
              .then((resultFile) => {
                if (resultFile.status === 200) {
                  form.resetFields();
                  fetchData(currentPage);
                  setVisible(false);
                  setButtonLoading(false);
                }
              })
              .catch((e) => {
                console.log("Error while uploading", e.toString() || e.message);
              });
          } else {
            form.resetFields();
            fetchData(currentPage);
            setVisible(false);
            setButtonLoading(false);
          }
        }
      })
      .catch((e) => {
        setButtonLoading(false);
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title="Мэдээний жагсаалт"
        description="Нийт мэдээний жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Мэдээ нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form layout="inline" style={{ marginBottom: 10 }}>
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="title">Мэдээний нэрээр</Option>
                            <Option value="publicationDate">
                              Нийтлэгдсэн огноогоор
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Хайх утга">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0 news-table"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Мэдээ нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        confirmLoading={buttonLoading}
        width={720}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{ schoolClassId: null }}
        >
          <Form.Item
            label="Гарчиг"
            name="name"
            rules={[{ required: true, message: "Гарчиг оруулна уу!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Анги" name="schoolClassId">
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              <Option value={null} key="public">
                Бүх анги
              </Option>
              {categories.map((cat) => (
                <Option value={cat.classId} key={cat.classId}>
                  {cat.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="photo"
            label="Мэдээний зураг"
            valuePropName="fileList"
            getValueFromEvent={normCover}
          >
            <Upload customRequest={dummyRequest} listType="picture">
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Контент">
            <ReactQuill
              theme="snow"
              defaultValue={value}
              value={value}
              onChange={setValue}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, schoolClass } = authentication;
  return {
    userData: user,
    schoolClass: schoolClass !== undefined ? schoolClass : [],
  };
}

const connectedNews = connect(mapState, null)(News);
export { connectedNews as News };
