import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Select,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
  notification,
  Upload,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import NumberFormat from "react-number-format";
import appInfo from "../../app-info";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function VendingItem({ user }) {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("-");

  let { productId } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.put(`vending/${productId}`).then((result) => {
      if (result.status == 200) {
        setName(result.data.content.name);
        form.setFieldsValue({
          name: result.data.content.name,
          price: result.data.content.price,
          count: result.data.content.count,
          cover: [
            {
              url: appInfo.apiURL + result.data.content.pictureUrl,
              name: result.data.content.name,
              type: "image/*",
              uid: "cover",
            },
          ],
        });
        setLoading(false);
      }
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    API.post(`vending/edit`, {
      id: productId,
      name: values.name,
      price: values.price,
      count: values.count,
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }

        setLoading(false);
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handlePreview = (file) => {
    console.log(file);
  };

  return (
    <>
      <PageTitle title={name} description={"Вендинг барааны хуудас"} />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form {...formItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                          label="Нэр"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Нэр оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Үнэ"
                          name="price"
                          rules={[
                            {
                              required: true,
                              message: "Үнэ оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="cover"
                          label="Зураг"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            { required: true, message: "Зураг сонгоно уу!" },
                          ]}
                        >
                          <Upload
                            customRequest={dummyRequest}
                            listType="picture"
                            onPreview={handlePreview}
                          >
                            <Button>
                              <UploadOutlined /> сонгох
                            </Button>
                          </Upload>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Хадгалах
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedVendingItem = connect(mapState, actionCreators)(VendingItem);
export { connectedVendingItem as VendingItem };
