import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  Table,
  notification,
  Modal,
  Button,
  Form,
  Select,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { Link } from "react-router-dom";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Teachers() {
  const [form] = Form.useForm();
  const [formRfid] = Form.useForm();
  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("givenName");
  const [searchValue, setSearchValue] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "teacher/list",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: currentPage - 1, size: showNum } }
    ).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setCurrentPage(currentPage);
          setTotalPages(result.data.content.totalPages);
        }
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Нэр",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => (
        <Link to={`/teacher/${record.teacherId}`}>{text}</Link>
      ),
    },
    {
      title: "Имэйл",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "Утасны дугаар",
      dataIndex: "mobilePhone",
      key: "mobilePhone",
    },
    {
      title: "Анги",
      dataIndex: "schoolClasses",
      key: "schoolClasses",
      render: (text, row, index) => getClassNames(text),
    },
    {
      title: "RFID",
      dataIndex: "rfid",
      key: "rfid",
      render: (text, row, index) =>
        row.user !== null ? (
          row.user.rfidCardId !== null ? (
            <>
              {row.user.rfidCardId}{" "}
              <Button
                type="link"
                onClick={() => {
                  setVisible(true);
                  setSelectedTeacher(row.user.id);
                }}
              >
                RFID оноох
              </Button>
            </>
          ) : (
            <Button
              type="link"
              onClick={() => {
                setVisible(true);
                setSelectedTeacher(row.user.id);
              }}
            >
              RFID оноох
            </Button>
          )
        ) : (
          "Хэрэглэгч үүсээгүй"
        ),
    },
  ];

  const handleOkRFID = () => {
    formRfid
      .validateFields()
      .then((values) => {
        API.post("users/rfid/assign", {
          userId: selectedTeacher,
          rfid: values.rfid,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setVisible(false);
              setSelectedTeacher(null);
              formRfid.resetFields();
              fetchData(currentPage);
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const getClassNames = (classes) => {
    let className = "";
    if (classes.length > 0) {
      classes.map((item) => (className += " " + item.displayName));
      return <span>{className}</span>;
    }
    return <span style={{ color: "red" }}>Анги сонгоогүй</span>;
  };

  const deleteUser = (userId) => {
    API.delete(`user/delete/${userId}`).then((result) => {
      if (result.status == 200) {
        fetchData();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const registerUser = (values) => {
    API.post("auth/signup", {
      username: values.username,
      email: values.email,
      password: values.password,
      registerNumber: values.registerNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      roles: values.role,
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.success) {
          } else {
            message.error(result.data.message);
          }
        }
      })
      .catch((error) => {
        const result = error.response;
        if (result.status === 400) {
          if (result.data.errors !== undefined) {
            if (result.data.errors.length > 0) {
              message.error(result.data.errors[0].defaultMessage);
            } else {
              message.error("Алдаа гарлаа");
            }
          } else {
            message.error(result.data.message);
          }
        }
      });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "teacher/list",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum } }
    ).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setTotalPages(result.data.content.totalPages);
          setCurrentPage(1);
        }
        setLoading(false);
      }
    });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title="Багш нарын жагсаалт"
        description="Системийн нийт багш нарын жагсаалт"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="givenName">Өөрийн нэр</Option>
                            <Option value="mail">Имэйл</Option>
                            <Option value="mobilePhone">Утасны дугаар</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Хайх утга">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue={showNum}
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      loading={loading}
                    />
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num + 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="RFID оноох"
        visible={visible}
        onOk={handleOkRFID}
        onCancel={() => {
          setVisible(false);
          setSelectedTeacher(null);
          formRfid.resetFields();
        }}
        okText="Оноох"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={formRfid}>
          <Form.Item
            label="Картын дугаар"
            name="rfid"
            rules={[{ required: true, message: "Картын дугаар оруулна уу" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  console.log(state);
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedTeachers = connect(mapState, actionCreators)(Teachers);
export { connectedTeachers as Teachers };
