import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import { ReactReader, EpubView } from "react-reader";
import FilePreviewer from "react-file-previewer";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function StudentMonthResult() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleBookRead, setVisibleBookRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [year, setYear] = useState(2020);
  const [term, setTerm] = useState(1);

  let { studentId } = useParams();

  const fetchData = () => {
    if (year === null) {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Он сонгоно уу",
      });

      return;
    }
    if (term === null) {
      notification["error"]({
        message: "Амжилтгүй",
        description: "Сар сонгоно уу",
      });

      return;
    }
    setLoading(true);
    API.get(`grade/progress/get/${studentId}`, {
      params: { year: year, term: term },
    }).then((result) => {
      if (result.status == 200) {
        setData(result.data.content);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Хичээлийн нэр",
      dataIndex: "lesson",
      key: "lesson",
      width: 200,
    },
    {
      title: "Дүн",
      dataIndex: "gradePercentage",
      key: "gradePercentage",
      render: (text, row, index) => (
        <>
          {text} {row.gradePrefix}
        </>
      ),
    },
  ];

  return (
    <>
      <Form
        layout="inline"
        style={{ marginBottom: 10 }}
        initialValues={{ year, term }}
      >
        <Form.Item name="year" label="Он">
          <Select
            style={{ width: 300 }}
            onChange={(val) => setYear(val)}
            allowClear
          >
            <Option value={2020}>2020</Option>
            <Option value={2021}>2021</Option>
            <Option value={2022}>2022</Option>
            <Option value={2023}>2023</Option>
            <Option value={2024}>2024</Option>
            <Option value={2025}>2025</Option>
            <Option value={2026}>2026</Option>
            <Option value={2027}>2027</Option>
            <Option value={2028}>2028</Option>
            <Option value={2029}>2029</Option>
            <Option value={2030}>2030</Option>
          </Select>
        </Form.Item>
        <Form.Item name="month" label="Сар">
          <Select
            style={{ width: 300 }}
            onChange={(val) => setTerm(val)}
            allowClear
          >
            <Option value={9}>9-р сар</Option>
            <Option value={10}>10-р сар</Option>
            <Option value={11}>11-р сар</Option>
            <Option value={12}>12-р сар</Option>
            <Option value={1}>1-р сар</Option>
            <Option value={2}>2-р сар</Option>
            <Option value={3}>3-р сар</Option>
            <Option value={4}>4-р сар</Option>
            <Option value={5}>5-р сар</Option>
            <Option value={6}>6-р сар</Option>
            <Option value={7}>7-р сар</Option>
            <Option value={8}>8-р сар</Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" onClick={() => fetchData()}>
            Харах
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={loading}>
        <Table
          className="table table-centered table-hover mb-0"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </Spin>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentMonthResult = connect(
  mapState,
  actionCreators
)(StudentMonthResult);
export { connectedStudentMonthResult as StudentMonthResult };
