import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  Table,
  notification,
  Button,
  Form,
  Select,
  Input,
  message,
  TreeSelect,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function StudentRoutes() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("givenName");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = (currentPage) => {
    API.post(
      "students/route-list",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: currentPage - 1, size: showNum } }
    ).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setCurrentPage(currentPage);
          setTotalPages(result.data.content.totalPages);
        }
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Нэр",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Имэйл",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "Утасны дугаар",
      dataIndex: "mobilePhone",
      key: "mobilePhone",
    },
    {
      title: "Анги",
      dataIndex: "schoolClasses",
      key: "schoolClasses",
      render: (text, row, index) => getClassNames(text),
    },
  ];

  const getClassNames = (classes) => {
    let className = "";
    if (classes.length > 0) {
      classes.map((item) => (className += " " + item.displayName));
      return <span>{className}</span>;
    }
    return <span style={{ color: "red" }}>Анги сонгоогүй</span>;
  };

  const confirm = (userId) => {
    deleteUser(userId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const deleteUser = (userId) => {
    API.delete(`user/delete/${userId}`).then((result) => {
      if (result.status == 200) {
        fetchData();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerUser(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerUser = (values) => {
    API.post("auth/signup", {
      username: values.username,
      email: values.email,
      password: values.password,
      registerNumber: values.registerNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      roles: values.role,
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.success) {
          } else {
            message.error(result.data.message);
          }
        }
      })
      .catch((error) => {
        const result = error.response;
        if (result.status === 400) {
          if (result.data.errors !== undefined) {
            if (result.data.errors.length > 0) {
              message.error(result.data.errors[0].defaultMessage);
            } else {
              message.error("Алдаа гарлаа");
            }
          } else {
            message.error(result.data.message);
          }
        }
      });
  };

  const onFinish = () => {
    setLoading(true);
    API.post(
      "students/route-list",
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      { params: { page: 0, size: showNum } }
    ).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setTotalPages(result.data.content.totalPages);
          setCurrentPage(1);
        }
        setLoading(false);
      }
    });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle
        title="Сурагчдын чиглэлийн жагсаалт"
        description="Сурагчдын чиглэлийн жагсаалт"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="givenName">Өөрийн нэр</Option>
                            <Option value="mail">Имэйл</Option>
                            <Option value="mobilePhone">Утасны дугаар</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Хайх утга">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    <div className="col-lg-4 text-right">
                      <Select
                        defaultValue="10"
                        onChange={(val) => setShowNum(val)}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                        <Option value={40}>40</Option>
                        <Option value={50}>50</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                    />
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  console.log(state);
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentRoutes = connect(mapState, actionCreators)(StudentRoutes);
export { connectedStudentRoutes as StudentRoutes };
