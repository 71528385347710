import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function StudentAccountHistory({ userData }) {
  const [form] = Form.useForm();
  const [formAccount] = Form.useForm();
  const [formAccountWithdraw] = Form.useForm();
  const [formAccountTransfer] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [transferShowModal, setTransferShowModal] = useState(false);
  const [showWithdrawModal, setShowWithDrawModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);

  const [date, setDate] = useState(moment().format("YYYY/MM/DD"));
  const [vendingAccountID, setVendingAccountID] = useState(null);
  const [kitchenAccountID, setKitchenAccountID] = useState(null);
  const [kitchenBalance, setKitchenBalance] = useState(0);
  const [vendingBalance, setVendingBalance] = useState(0);

  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("vending");

  useEffect(() => {
    fetchData();
  }, []);

  let { studentId } = useParams();

  const fetchData = () => {
    if (userData.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
    API.get(`students/account/${studentId}`).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setKitchenAccountID(result.data.content.kitchenAccountId);
          setVendingAccountID(result.data.content.vendingAccountId);
          setKitchenBalance(result.data.content.kitchenBalance);
          setVendingBalance(result.data.content.vendingBalance);

          formAccountTransfer.setFieldsValue({
            accountBalance: result.data.content.vendingBalance,
          });

          form.setFieldsValue({
            accountId: result.data.content.vendingAccountId,
          });
          getHistory(result.data.content.vendingAccountId);
        }
      }
    });
  };

  const getHistory = (accountID) => {
    if (accountID !== null) {
      API.get("students/account/history", {
        params: {
          accountId: accountID,
          startDate: date,
        },
      }).then((result) => {
        if (result.status == 200) {
          setData(result.data.content);
        }
      });
    } else {
      setData([]);
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Гүйлгээ хийсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Гүйлгээний дүн",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      render: (text, record) => (
        <NumberFormat
          renderText={(text) => (
            <span style={{ color: record.type === "INCOME" ? "green" : "red" }}>
              {record.type === "INCOME" ? "+" : "-"}
              {text}
            </span>
          )}
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Өмнөх дүн",
      dataIndex: "prevBalance",
      key: "prevBalance",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Одоогын үлдэгдэл",
      dataIndex: "newBalance",
      key: "newBalance",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    API.get("students/account/history", {
      params: {
        accountId: values.accountId,
        startDate: moment(values.startDate).format("YYYY/MM/DD"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content);
        }
      }
      setLoading(false);
    });
  };

  const handleOk = () => {
    formAccount
      .validateFields()
      .then((values) => {
        API.post("students/account/charge-manually", {
          amount: values.amount,
          studentId: studentId,
          type: values.type,
          paymentType: "MANUAL",
          transactionDescription: values.transactionDescription,
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setShowModal(false);
              formAccount.resetFields();
              fetchData();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleWithdrawOk = () => {
    formAccountWithdraw
      .validateFields()
      .then((values) => {
        API.post("students/account/withdraw-manually", {
          amount: values.amount,
          studentId: studentId,
          type: values.type,
          paymentType: "MANUAL",
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setShowWithDrawModal(false);
              formAccountWithdraw.resetFields();
              fetchData();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleTransferOk = () => {
    formAccountTransfer
      .validateFields()
      .then((values) => {
        API.post("students/account/exchange", {
          amount: values.amount,
          studentId: studentId,
          from: values.type,
          to: values.type === "FOOD" ? "VENDING" : "FOOD",
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setTransferShowModal(false);
              formAccountTransfer.resetFields();
              fetchData();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const changeAccount = (value) => {
    formAccountTransfer.setFieldsValue({
      receive: value === "FOOD" ? "VENDING" : "FOOD",
      accountBalance: value === "FOOD" ? kitchenBalance : vendingBalance,
    });
    return value;
  };

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={16} align="bottom" align="top">
          <Col className="gutter-row" span={14}>
            <Form
              form={form}
              layout="inline"
              style={{ marginBottom: 10 }}
              initialValues={{
                startDate: moment(),
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="startDate"
                label="Он сар"
                rules={[{ required: true, message: "Он сар сонгоно уу!" }]}
              >
                <DatePicker picker="month" />
              </Form.Item>
              <Form.Item
                name="accountId"
                label="Дансны төрөл"
                rules={[
                  { required: true, message: "Дансны төрөл сонгоно уу!" },
                ]}
              >
                <Select style={{ width: 300 }} allowClear>
                  <Option value={vendingAccountID}>Вендинг машины данс</Option>
                  <Option value={kitchenAccountID}>Хоолны данс</Option>
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Харах
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {isAdmin ? (
            <Col className="gutter-row text-right" span={10}>
              <Button
                onClick={() => setShowModal(true)}
                style={{ color: "green", marginRight: 20 }}
              >
                Цэнэглэх
              </Button>
              <Button
                onClick={() => setShowWithDrawModal(true)}
                style={{ color: "red", marginRight: 20 }}
              >
                Хасах
              </Button>
              <Button
                onClick={() => setTransferShowModal(true)}
                style={{ color: "green" }}
              >
                Мөнгө шилжүүлэх
              </Button>
            </Col>
          ) : null}
        </Row>
        <Table
          className="table table-centered table-hover mb-0"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          locale={{
            emptyText: `${moment(form.getFieldValue("startDate")).format(
              "YYYY/MM"
            )} сард ${
              form.getFieldValue("accountId") === vendingAccountID
                ? "вендинг машины дансаар"
                : "хоолны дансаар"
            } гүйлгээ хийгдээгүй байна`,
          }}
        />
      </Spin>
      <Modal
        title="Данс цэнэглэх"
        visible={showModal}
        onOk={handleOk}
        onCancel={() => {
          setShowModal(false);
          formAccount.resetFields();
        }}
        okText="Оноох"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={formAccount}
          initialValues={{ type: "VENDING" }}
        >
          <Form.Item
            name="type"
            label="Дансны төрөл"
            rules={[{ required: true, message: "Дансны төрөл сонгоно уу!" }]}
          >
            <Select style={{ width: 300 }} allowClear>
              <Option value="VENDING">Вендинг машины данс</Option>
              <Option value="FOOD">Хоолны данс</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Цэнэглэх дүн"
            name="amount"
            rules={[{ required: true, message: "Цэнэглэх дүн оруулна уу" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Гүйлгээний утга" name="transactionDescription">
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Данснаас хасалт хийх"
        visible={showWithdrawModal}
        onOk={handleWithdrawOk}
        onCancel={() => {
          setShowWithDrawModal(false);
          formAccountWithdraw.resetFields();
        }}
        okText="Оноох"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={formAccountWithdraw}
          initialValues={{ type: "VENDING" }}
        >
          <Form.Item
            name="type"
            label="Дансны төрөл"
            rules={[{ required: true, message: "Дансны төрөл сонгоно уу!" }]}
          >
            <Select style={{ width: 300 }} allowClear>
              <Option value="VENDING">Вендинг машины данс</Option>
              <Option value="FOOD">Хоолны данс</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Хасах дүн"
            name="amount"
            rules={[{ required: true, message: "Хасах дүн оруулна уу" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Шилжүүлэг хийх"
        visible={transferShowModal}
        onOk={handleTransferOk}
        onCancel={() => {
          setTransferShowModal(false);
          formAccountTransfer.resetFields();
        }}
        destroyOnClose={true}
        okText="Шилжүүлэх"
        cancelText="Болих"
        width={720}
      >
        <Form
          {...formItemLayout}
          form={formAccountTransfer}
          initialValues={{
            type: "VENDING",
            receive: "FOOD",
            accountBalance: vendingBalance,
          }}
        >
          <Form.Item
            name="type"
            label="Шилжүүлэх данс"
            rules={[{ required: true, message: "Дансны төрөл сонгоно уу!" }]}
            getValueFromEvent={changeAccount}
          >
            <Select style={{ width: 300 }} allowClear>
              <Option value="VENDING">Вендинг машины данс</Option>
              <Option value="FOOD">Хоолны данс</Option>
            </Select>
          </Form.Item>
          <Form.Item name="accountBalance" label="Дансны үлдэгдэл">
            <NumberFormat
              customInput={Input}
              thousandSeparator={true}
              suffix={"₮"}
              disabled
            />
          </Form.Item>
          <Form.Item name="receive" label="Хүлээн авах данс">
            <Select style={{ width: 300 }} allowClear disabled>
              <Option value="VENDING">Вендинг машины данс</Option>
              <Option value="FOOD">Хоолны данс</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Шилжүүлэх дүн"
            name="amount"
            rules={[{ required: true, message: "Шилжүүлэх дүн оруулна уу" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { userData: user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentAccountHistory = connect(
  mapState,
  actionCreators
)(StudentAccountHistory);
export { connectedStudentAccountHistory as StudentAccountHistory };
