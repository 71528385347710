import React, { useState, useEffect } from "react";
import { API } from "../../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  Form,
  Select,
  Upload,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../../_actions";
import { PageTitle, Pagination } from "../../../components";
import NumberFormat from "react-number-format";
import Zoom from "react-medium-image-zoom";
import appInfo from "../../../app-info";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function AndroidVendingProducts() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getProducts = () => {
    API.get("vending/android/dashboard/list").then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
        }
      }
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const confirm = (productId) => {
    deleteProduct(productId);
  };

  const cancel = (e) => {
    console.log(e);
  };
  const deleteProduct = (productId) => {
    API.delete(`vending/android/dashboard/delete/?productId=${productId}`).then(
      (result) => {
        if (result.status == 200) {
          getProducts();

          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        }
      }
    );
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Хавтас",
      dataIndex: "pictureUrl",
      key: "pictureUrl",
      render: (text, record) => {
        return text !== null ? (
          <Zoom>
            <img src={appInfo.apiURL + text} width={50} />
          </Zoom>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Барааны нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Үнэ",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={"₮"}
          value={text}
        />
      ),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div className="btn-group" role="group">
            {/* <Link
              to={`/vending-product/${record.id}`}
              type="button"
              className="btn btn-outline-secondary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="mdi mdi-pencil"></i>
            </Link> */}

            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => confirm(record.id)}
              onCancel={cancel}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerProduct(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerProduct = (values) => {
    setConfirmLoading(true);
    API.post("vending/android/dashboard/add", {
      name: values.name,
      price: values.price,
      count: values.count,
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.success) {
            const response = result.data.content;

            var formData = new FormData();
            formData.set("file", values.cover[0].originFileObj);
            formData.set("id", response.id);
            formData.set("type", "FILE");
            formData.set("entity", "VENDING_PRODUCT_2");

            API.post("file/upload", formData).then((resultFile) => {
              if (resultFile.status === 200) {
                notification["success"]({
                  message: "Амжилттай",
                  description: result.data.message,
                });
                getProducts();
                setVisible(false);
              }
            });
          } else {
            message.error(result.data.message);
          }
        }
        setConfirmLoading(false);
      })
      .catch((error) => {
        setConfirmLoading(false);
        const result = error.response;
        if (result.status === 400) {
          if (result.data.errors !== undefined) {
            if (result.data.errors.length > 0) {
              message.error(result.data.errors[0].defaultMessage);
            } else {
              message.error("Алдаа гарлаа");
            }
          } else {
            message.error(result.data.message);
          }
        }
      });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (e.fileList.length >= 2) {
      e.fileList = e.fileList.slice(-1);
    }
    const { file } = e;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("Зөвхөн JPEG/PNG файл сонгоно уу");
      return;
    }
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
      message.error("Файлын 200мб-аас их байна!");
      return;
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handlePreview = (file) => {
    console.log(file);
  };

  return (
    <>
      <PageTitle
        title="Барааны жагсаалт - 2"
        description="Барааны жагсаалт - 2"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Бараа нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        destroyOnClose={true}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Барааны нэр"
            name="name"
            rules={[{ required: true, message: "Барааны нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Барааны үнэ"
            name="price"
            rules={[{ required: true, message: "Барааны үнэ оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cover"
            label="Зураг"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Зураг сонгоно уу!" }]}
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture"
              onPreview={handlePreview}
            >
              <Button>
                <UploadOutlined /> сонгох
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedAndroidVendingProducts = connect(
  mapState,
  actionCreators
)(AndroidVendingProducts);
export { connectedAndroidVendingProducts as AndroidVendingProducts };
