import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { API } from "../../_helpers/service";
import {
  notification,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  Select,
  InputNumber,
} from "antd";
import appInfo from "../../app-info";
import NumberFormat from "react-number-format";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export const VendingMachine = ({ products, rowNumber }) => {
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [addCountModal, setAddCountModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const deleteRow = () => {
    API.delete(`vending/delete-row/${rowNumber}`).then((result) => {
      if (result.status == 200) {
        fetchData();
        form.resetFields();
        setVisible(false);
        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      } else {
        notification["error"]({
          message: "Амжилтгүй",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    fetchData();
    form.resetFields();
    formUpdate.resetFields();
    setVisible(false);
  };

  const handleCancelUpdate = () => {
    fetchData();
    formUpdate.resetFields();
    setAddCountModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.get("vending/get-build", {
      params: { rowNumber },
    }).then((result) => {
      if (result.status === 200) {
        const data = result.data.content;

        setData(data);
        setLoading(false);
      }
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        API.post("vending/assign-row", {
          productId: values.product,
          rowNumber: rowNumber,
          prodCount: values.count,
        }).then((result) => {
          if (result.status == 200) {
            fetchData();
            setVisible(false);
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          } else {
            notification["error"]({
              message: "Амжилтгүй",
              description: result.data.message,
            });
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleOkUpdate = () => {
    formUpdate
      .validateFields()
      .then((values) => {
        API.post("vending/update-row", {
          prodCount: values.prodCount,
          rowNumber: rowNumber,
        }).then((result) => {
          if (result.status == 200) {
            fetchData();
            setAddCountModal(false);
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          } else {
            notification["error"]({
              message: "Амжилтгүй",
              description: result.data.message,
            });
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <>
      <div className="vending-cell">
        <span className="row-number">{rowNumber}</span>
        <Spin spinning={loading}>
          <div>
            {data !== null ? (
              <div className="vending-cell-item">
                <img
                  src={appInfo.apiURL + data.product.pictureUrl}
                  width={200}
                />

                <p>
                  <span syle={{ fontWeight: "bold" }}>{data.prodCount}ш</span>{" "}
                  <br />
                  {data.product.name} /
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"₮"}
                    value={data.product.price}
                  />
                  /
                </p>
                <Button
                  onClick={() => {
                    formUpdate.setFieldsValue({ prodCount: 1 });
                    setAddCountModal(true);
                  }}
                  style={{ marginBottom: 10, width: "100%" }}
                >
                  Тоо нэмэх
                </Button>
                <Button
                  danger
                  onClick={() => deleteRow()}
                  style={{ width: "100%" }}
                >
                  Устгах
                </Button>
              </div>
            ) : (
              <span onClick={() => setVisible(true)} className="vending-choose">
                Бараа сонгох
              </span>
            )}
          </div>
        </Spin>
      </div>
      <Modal
        title="Бараа оноох"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        okText="Нэмэх"
        cancelButtonProps={{ danger: true }}
        cancelText="Болих"
        width={800}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item label={`Вендинг машины нүд`} name="rowNumber">
            <Input defaultValue={rowNumber} value={rowNumber} disabled />
          </Form.Item>
          <Form.Item
            label={`Бараа`}
            name="product"
            rules={[{ required: true, message: "Бараа сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {products.map((teacher) => (
                <Option key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={`Тоо ширхэг`}
            name="count"
            rules={[{ required: true, message: "Тоо оруулна уу!" }]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Бараа нэмэх"
        visible={addCountModal}
        onOk={handleOkUpdate}
        onCancel={handleCancelUpdate}
        destroyOnClose={true}
        okText="Нэмэх"
        cancelButtonProps={{ danger: true }}
        cancelText="Болих"
        width={800}
      >
        <Form {...formItemLayout} form={formUpdate}>
          <Form.Item
            label={`Нэмэх тоо`}
            name="prodCount"
            rules={[{ required: true, message: "Тоо оруулна уу!" }]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
