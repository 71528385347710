import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Form,
  Select,
  Spin,
  Checkbox,
  Row,
  Col,
  Input,
  message,
  notification,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

function Product({ user }) {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("-");

  let { productId } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.put(`product/${productId}`).then((result) => {
      if (result.status == 200) {
        setName(result.data.content.name);
        form.setFieldsValue({
          name: result.data.content.name,
          price: result.data.content.price,
        });
        setLoading(false);
      }
    });
  };

  const onFinish = (values) => {
    setLoading(true);

    API.post(`product/edit`, {
      id: productId,
      name: values.name,
      price: values.price,
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          notification["success"]({
            message: "Амжилттай",
            description: result.data.message,
          });
        } else {
          notification["error"]({
            message: "Амжилтгүй",
            description: result.data.message,
          });
        }

        setLoading(false);
      }
    });
  };

  return (
    <>
      <PageTitle title={name} description={"Бүтээгдэхүүний хуудас"} />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Form {...formItemLayout} form={form} onFinish={onFinish}>
                        <Form.Item
                          label="Нэр"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Нэр оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Үнэ"
                          name="price"
                          rules={[
                            {
                              required: true,
                              message: "Үнэ оруулна уу!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                          <Button type="primary" htmlType="submit">
                            Хадгалах
                          </Button>
                        </Form.Item>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedProduct = connect(mapState, actionCreators)(Product);
export { connectedProduct as Product };
