import React, { useState, useEffect, useRef } from "react";
import { API } from "../../_helpers/service";
import {
  Table,
  Button,
  DatePicker,
  Form,
  Select,
  Spin,
  TreeSelect,
  Input,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";
import { PageTitle, Pagination } from "../../components";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { TreeNode } = TreeSelect;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function ShopHistoryTable() {
  const searchInput = useRef(null);
  const [form] = Form.useForm();

  const [searchKey, setSearchKey] = useState("rfIdNumber");
  const [searchValue, setSearchValue] = useState(null);

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const [totalPages, setTotalPages] = useState(1);

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Картын дугаар",
      dataIndex: "rfIdNumber",
      key: "rfIdNumber",
    },
    {
      title: "Сурагчийн нэр",
      dataIndex: "studentName",
      key: "studentName",
      width: 200,
    },
    {
      title: "Сурагчийн код",
      dataIndex: "studentCode",
      key: "studentCode",
      width: 200,
    },
    {
      title: "Гүйлгээний дүн",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          suffix={" ₮"}
          value={text}
        />
      ),
    },
    {
      title: "Гүйлгээний утга",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
  ];

  const onFinish = (values) => {
    setLoading(true);
    API.post(
      `shop/list`,
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      {
        params: {
          page: currentPage - 1,
          size: showNum,
          startDate: moment(values.date[0]).format("YYYY-MM-DD"),
          endDate: moment(values.date[1]).format("YYYY-MM-DD"),
        },
      }
    )
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const fetchData = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);

    API.post(
      `shop/list`,
      {
        key: searchKey,
        value: searchValue,
        operation: "LIKE",
      },
      {
        params: {
          page: currentPage - 1,
          size: showNum,
          startDate: moment(form.getFieldValue("date")[0]).format("YYYY-MM-DD"),
          endDate: moment(form.getFieldValue("date")[1]).format("YYYY-MM-DD"),
        },
      }
    )
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content.content);
            setTotalPages(result.data.content.totalPages);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle title="Дэлгүүр" description="Дэлгүүрийн гүйлгээний түүх" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="row">
                    <div className="col-lg-8">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="rfIdNumber">Картын дугаараар</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="rfIdNumber" label="Картын дугаар">
                          <Input
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </div> */}
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <div className="row">
                        <div className="col-lg-8">
                          <Form
                            form={form}
                            layout="inline"
                            style={{ marginBottom: 10 }}
                            initialValues={{
                              date: [moment().subtract(1, "days"), moment()],
                            }}
                            onFinish={onFinish}
                          >
                            <Form.Item
                              name="date"
                              label="Хугацаа"
                              rules={[
                                {
                                  required: true,
                                  message: "Хугацаа сонгоно уу!",
                                },
                              ]}
                            >
                              <RangePicker />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Харах
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                        <div className="col-lg-4 text-right">
                          <Select
                            defaultValue="100"
                            onChange={(val) => setShowNum(val)}
                          >
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                            <Option value={30}>30</Option>
                            <Option value={40}>40</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                          </Select>
                        </div>
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <Table
                              className="table table-centered table-hover mb-0"
                              columns={columns}
                              dataSource={data}
                              pagination={false}
                              rowKey="id"
                              locale={{
                                emptyText: `Өгөгдөл алга байна, огноо сонгоно уу?`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Spin>
                  </div>
                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedShopHistoryTable = connect(
  mapState,
  actionCreators
)(ShopHistoryTable);
export { connectedShopHistoryTable as ShopHistoryTable };
