import {
  Users,
  User,
  Home,
  Library,
  ELibrary,
  BookCategory,
  Category,
  Book,
  Profile,
  BookReturn,
  BookRent,
  Archived,
  FeedBack,
  News,
  Drivers,
  Students,
  Teachers,
  AllClass,
  ParentalRequest,
  ParentalList,
  StudentRoutes,
  NeighbourChat,
  VendingTransactions,
  Settings,
  ClassItem,
  Grade,
  Monthly,
  MonthlyList,
  MonthlyGrade,
  RouteItem,
  StudentItem,
  Products,
  Kitchen,
  KitchenHistory,
  Product,
  GradeYear,
  Schedule,
  Parents,
  GradeTerm,
  Revision,
  KitchenAssign,
  TeachersTimeTable,
  KitchenServedHistory,
  KitchenSupplyHistory,
  DriverRouteHistory,
  TeacherFoodDetail,
  Attendance,
  Transactions,
  GradeClass,
  MonthlyClass,
  VendingProducts,
  AndroidVendingProducts,
  VendingItem,
  VendingTable,
  AndroidVendingTable,
  BookCategoryEdit,
  UsersDeactivated,
  ShopTable,
  ShopHistoryTable,
  Events,
  TeacherItem,
  PreSchoolTransactions,
  Statistics,
} from "./pages";

export const initialRoute = [
  {
    path: "/profile",
    component: Profile,
    label: "Профайл",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/student/:studentId",
    component: StudentItem,
    label: "Сурагч",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/teacher/:teacherId",
    component: TeacherItem,
    label: "Багш",
    showHeader: true,
    icon: "profile",
  },
];

export const kitchenRoutes = [
  {
    path: "/kitchen",
    component: Kitchen,
    label: "Профайл",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/kitchen-served-history",
    component: KitchenServedHistory,
    label: "Хоолны тооны түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/kitchen-supply-history",
    component: KitchenSupplyHistory,
    label: "Ангийн тооны түүх",
    showHeader: true,
    icon: "profile",
  },
];

export const vendingRoutes = [
  {
    path: "/vending-products",
    component: VendingProducts,
    label: "Вендинг",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-product/:productId",
    component: VendingItem,
    label: "Вендинг бараа",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-table",
    component: VendingTable,
    label: "Вендинг бараа өрөх",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-table-2",
    component: AndroidVendingTable,
    label: "Вендинг бараа өрөх-2",
    showHeader: true,
    icon: "users",
  },
];

export const shopRoutes = [
  {
    path: "/shop-table",
    component: ShopTable,
    label: "Дэлгүүрийн бараа зарах",
    showHeader: true,
    icon: "shop",
  },
  {
    path: "/shop-transactions",
    component: ShopHistoryTable,
    label: "Дэлгүүрийн гүйлгээний түүх",
    showHeader: true,
    icon: "history",
  },
];

export const employeeRoutes = [
  {
    path: "/shop-table",
    component: ShopTable,
    label: "Бараа зарах",
    showHeader: true,
    icon: "shop",
  },
  {
    path: "/shop-transactions",
    component: ShopHistoryTable,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "history",
  },
  {
    path: "/transactions",
    component: Transactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/pre-shool-transactions",
    component: PreSchoolTransactions,
    label: "Бэлтгэл ангийн гүйлгээний түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/feedback",
    component: FeedBack,
    label: "Санал хүсэлт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/parents",
    component: Parents,
    label: "Эцэг эх",
    showHeader: true,
    icon: "users-alt",
  },
  {
    path: "/user/:userID",
    component: User,
    label: "Хэрэглэгч",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/parental-request",
    component: ParentalRequest,
    label: "Хүүхэд нэмүүлэх хүсэлтүүд",
    showHeader: true,
    icon: "user-add",
  },
  {
    path: "/parental-list",
    component: ParentalList,
    label: "Холбогдсон хүүхдүүд",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/grade-year/:year",
    component: GradeYear,
    label: "Ерөнхий дүн - Жил",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-term/:year/:term",
    component: GradeTerm,
    label: "Ерөнхий дүн - Улирал",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-class/:year/:term/:classId",
    component: GradeClass,
    label: "Ерөнхий дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/monthly-class/:year/:term/:classId",
    component: MonthlyClass,
    label: "Явцын дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-list/:year",
    component: MonthlyList,
    label: "Явцын шалгалт - Сараар",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-grade/:year/:term",
    component: MonthlyGrade,
    label: "Явцын шалгалт - Анги",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Сурагч",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/driver-route-history",
    component: DriverRouteHistory,
    label: "Жолоочийн зогсоолын түүх",
    showHeader: true,
    icon: "profile",
  },
  // Requested by Batzorig
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/class-item/:classId",
    component: ClassItem,
    label: "Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/news",
    component: News,
    label: "Мэдээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/events",
    component: Events,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/kitchen-assign",
    component: KitchenAssign,
    label: "Хоолны тоо",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "book-open",
  },
];

export const adminRoutes = [
  // {
  //   path: "/",
  //   component: Home,
  //   label: "Эхлэл",
  //   showHeader: true,
  //   icon: "shop",
  // },
  {
    path: "/statistics",
    component: Statistics,
    label: "Статистик",
    showHeader: true,
    icon: "shop",
  },
  {
    path: "/shop-table",
    component: ShopTable,
    label: "Бараа зарах",
    showHeader: true,
    icon: "shop",
  },
  {
    path: "/shop-transactions",
    component: ShopHistoryTable,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "history",
  },
  {
    path: "/neighbour",
    component: NeighbourChat,
    label: "Чат",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/feedback",
    component: FeedBack,
    label: "Санал хүсэлт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/teachers",
    component: Teachers,
    label: "Багш",
    showHeader: true,
    icon: "book-reader",
  },
  {
    path: "/parents",
    component: Parents,
    label: "Эцэг эх",
    showHeader: true,
    icon: "users-alt",
  },
  {
    path: "/users",
    component: Users,
    label: "Системийн хэрэглэгчид",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/deactive-users",
    component: UsersDeactivated,
    label: "Идэвхгүй хэрэглэгчид",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-products",
    component: VendingProducts,
    label: "Вендинг",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-products-2",
    component: AndroidVendingProducts,
    label: "Вендинг-2",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-product/:productId",
    component: VendingItem,
    label: "Вендинг бараа",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/attendance",
    component: Attendance,
    label: "Ирц",
    showHeader: true,
    icon: "enter",
  },
  {
    path: "/user/:userID",
    component: User,
    label: "Хэрэглэгч",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/parental-list",
    component: ParentalList,
    label: "Холбогдсон хүүхдүүд",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/parental-request",
    component: ParentalRequest,
    label: "Хүүхэд нэмүүлэх хүсэлтүүд",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-table",
    component: VendingTable,
    label: "Вендинг бараа өрөх",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/vending-table-2",
    component: AndroidVendingTable,
    label: "Вендинг бараа өрөх-2",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/class-item/:classId",
    component: ClassItem,
    label: "Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/news",
    component: News,
    label: "Мэдээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/events",
    component: Events,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/drivers",
    component: Drivers,
    label: "Автобус",
    showHeader: true,
    icon: "bus",
  },
  {
    path: "/finance",
    component: Library,
    label: "Санхүү",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/report",
    component: Library,
    label: "Тайлан",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-list/:year",
    component: MonthlyList,
    label: "Явцын шалгалт - Сараар",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-grade/:year/:term",
    component: MonthlyGrade,
    label: "Явцын шалгалт - Анги",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-year/:year",
    component: GradeYear,
    label: "Ерөнхий дүн - Жил",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-term/:year/:term",
    component: GradeTerm,
    label: "Ерөнхий дүн - Улирал",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-class/:year/:term/:classId",
    component: GradeClass,
    label: "Ерөнхий дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/monthly-class/:year/:term/:classId",
    component: MonthlyClass,
    label: "Явцын дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/products",
    component: Products,
    label: "Бүтээгдэхүүн",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/teacher-attendance",
    exact: true,
    component: TeachersTimeTable,
    label: "Багш нарын түүх",
    showHeader: true,
    icon: "food",
  },
  {
    path: "/teacher-detail",
    exact: true,
    component: TeacherFoodDetail,
    label: "Багшийн дэлгэрэнгүй",
    showHeader: true,
    icon: "food",
  },
  {
    path: "/product/:productId",
    component: Product,
    label: "Бүтээгдэхүүн",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Сурагч",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/kitchen-supply-history",
    component: KitchenSupplyHistory,
    label: "Ангийн тооны түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/transactions",
    component: Transactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/pre-shool-transactions",
    component: PreSchoolTransactions,
    label: "Бэлтгэл ангийн гүйлгээний түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/vending-transactions",
    component: VendingTransactions,
    label: "Гүйлгээний түүх",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/driver-route-history",
    component: DriverRouteHistory,
    label: "Жолоочийн зогсоолын түүх",
    showHeader: true,
    icon: "profile",
  },
];

export const teacherRoutes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: Home,
  //   label: "Эхлэл",
  //   showHeader: true,
  //   icon: "airplay",
  // },
  {
    path: "/monthly",
    component: Monthly,
    label: "Явцын шалгалт",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-list/:year",
    component: MonthlyList,
    label: "Явцын шалгалт - Сараар",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/monthly-grade/:year/:term",
    component: MonthlyGrade,
    label: "Явцын шалгалт - Анги",
    showHeader: true,
    icon: "report",
  },
  {
    path: "/user/:userID",
    component: User,
    label: "Хэрэглэгч",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/class",
    component: AllClass,
    label: "Анги",
    showHeader: true,
    icon: "users",
  },
  {
    path: "/class-item/:classId",
    component: ClassItem,
    label: "Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/news",
    component: News,
    label: "Мэдээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/events",
    component: Events,
    label: "Арга хэмжээ",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/kitchen-assign",
    component: KitchenAssign,
    label: "Хоолны тоо",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/revision",
    component: Revision,
    label: "Давтлага",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/schedule",
    component: Schedule,
    label: "Хичээлийн хуваарь",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/kitchen-history",
    component: KitchenHistory,
    label: "Сурагч",
    showHeader: true,
    icon: "profile",
  },
  {
    path: "/grade",
    component: Grade,
    label: "Ерөнхий дүн",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-year/:year",
    component: GradeYear,
    label: "Ерөнхий дүн - Жил",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-term/:year/:term",
    component: GradeTerm,
    label: "Ерөнхий дүн - Улирал",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/grade-class/:year/:term/:classId",
    component: GradeClass,
    label: "Ерөнхий дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/monthly-class/:year/:term/:classId",
    component: MonthlyClass,
    label: "Явцын дүн - Анги",
    showHeader: true,
    icon: "book-open",
  },
];

export const librarianRoutes = [
  {
    path: "/library-ebook",
    component: ELibrary,
    label: "Цахим номын жагсаалт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/library",
    component: Library,
    label: "Номын жагсаалт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/book-category",
    component: BookCategory,
    label: "Ангилалын жагсаалт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/book-category-edit/:bookId",
    component: BookCategoryEdit,
    label: "Ангилалт",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/book-return",
    component: BookReturn,
    label: "Ном авах",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/book-rent",
    component: BookRent,
    label: "Ном өгөх",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/category/:categoryId",
    component: Category,
    label: "Ангилал",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/book/:bookId",
    component: Book,
    label: "Ном",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/route/:bookId",
    component: RouteItem,
    label: "Чиглэл",
    showHeader: true,
    icon: "book-open",
  },

  {
    path: "/archived-books",
    component: Archived,
    label: "Архив",
    showHeader: true,
    icon: "book-open",
  },
  {
    path: "/settings",
    component: Settings,
    label: "Тохиргоо",
    showHeader: true,
    icon: "book-open",
  },
];

export const accountantRoutes = [
  {
    path: "/attendance",
    component: Attendance,
    label: "Ирц",
    showHeader: true,
    icon: "enter",
  },
];
