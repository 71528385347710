import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function StudentLibrary() {
  const [form] = Form.useForm();
  const [formAccount] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [date, setDate] = useState(moment().format("YYYY/MM/DD"));
  const [vendingAccountID, setVendingAccountID] = useState(null);
  const [kitchenAccountID, setKitchenAccountID] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("vending");

  useEffect(() => {
    fetchData();
  }, []);

  let { studentId } = useParams();

  const fetchData = () => {
    API.get(`library/books/orders/${studentId}`, {
      params: { startDate: date },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
        }
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Номын нэр",
      dataIndex: "book",
      key: "book",
      render: (text, row, index) => <>{row.book.name}</>,
    },
    {
      title: "Эхлэх өдөр",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Дуусах өдөр",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Тоо ширхэг",
      dataIndex: "requestedCount",
      key: "requestedCount",
      align: "center",
    },
    {
      title: "Төлөв",
      dataIndex: "bookingOrderStatus",
      key: "bookingOrderStatus",
      render: (text, record) =>
        text === "WITHDRAWN" ? (
          <span style={{ color: "red" }}>Буцаагаагүй</span>
        ) : (
          <span style={{ color: "green" }}>Буцаасан</span>
        ),
    },
    {
      title: "Буцаасан өдөр",
      dataIndex: "receivedDate",
      key: "receivedDate",

      render: (text, record) =>
        text !== null ? moment(text).format("YYYY-MM-DD HH:mm") : "-",
    },
  ];

  const getType = (type) => {
    switch (type) {
      case "cart":
        return "Данс";
      case "bus-school":
        return "Автобус";
      case "food-fork-drink":
        return "Өдрийн хоол";
      default:
        return "-";
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    API.get(`library/books/orders/${studentId}`, {
      params: {
        startDate: moment(values.startDate).format("YYYY/MM/DD"),
      },
    }).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
        }
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="inline"
          style={{ marginBottom: 10 }}
          initialValues={{
            startDate: moment(),
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="startDate"
            label="Он сар"
            rules={[{ required: true, message: "Он сар сонгоно уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Харах
            </Button>
          </Form.Item>
        </Form>
        <Table
          className="table table-centered table-hover mb-0"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          locale={{
            emptyText: `${moment(form.getFieldValue("startDate")).format(
              "YYYY/MM/DD"
            )}-нд үйлдэл хийгээгүй байна`,
          }}
        />
      </Spin>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentLibrary = connect(
  mapState,
  actionCreators
)(StudentLibrary);
export { connectedStudentLibrary as StudentLibrary };
