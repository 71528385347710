import moment from "moment";
import { cookies, API } from "../_helpers";

export const userService = {
  login,
  getUserData,
  logout,
};

async function login(username, password) {
  return API.post("auth/signin", {
    username: username,
    password: password,
  }).then((result) => {
    // eslint-disable-next-line no-undef
    let isSecure = false;
    // eslint-disable-next-line no-undef
    if (window.location.protocol === "https:") {
      isSecure = true;
    }
    cookies.set("Token", result.data.content.auth.accessToken, {
      secure: isSecure,
      expires: new Date(
        moment()
          .add(result.data.content.auth.expirationIn / 1000, "seconds")
          .format()
      ),
    });

    return result;
  });
}

function getUserData(token) {
  return API.get("user/me", null).then((result) => result.data.content);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  cookies.remove("Token");
}
