import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
} from "antd";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function ParentalRequest() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get("parents/assign/list", {
      params: {
        status: "REQUESTED",
        action: "ADD",
      },
    }).then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Эцэг/эхийн хэрэглэгчийн нэр",
      dataIndex: "parentId",
      key: "parentId",
      render: (text, record) => {
        if (record.parentId !== null) {
          return record.parentId.user.username;
        }
      },
    },
    {
      title: "Нэмүүлэх хүүхдийн нэр",
      dataIndex: "username",
      key: "username",
      render: (text, record) => {
        if (record.studentId !== null) {
          return record.studentId.displayName;
        }
      },
    },
    {
      title: "Нэмүүлэх хүүхдийн ID",
      dataIndex: "username",
      key: "username",
      render: (text, record) => {
        if (record.studentId !== null) {
          return record.studentId.microsoftMail;
        }
      },
    },
    {
      title: "Хүүхдийн анги",
      dataIndex: "class",
      key: "class",
      render: (text, record) => {
        if (record.studentId !== null) {
          return record.studentId.className;
        }
      },
    },
    {
      title: "Хүсэлт гаргасан",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => approve(record.id)}>
            Батлах
          </Button>
          <Popconfirm
            title="Цуцлахдаа итгэлтэй байна уу?"
            onConfirm={() => confirm(record.id)}
            onCancel={cancel}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button type="link">Цуцлах</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const approve = (requestId) => {
    API.post(`parents/assign/approve/${requestId}?status=APPROVED`).then(
      (result) => {
        if (result.status == 200) {
          if (result.data.success) {
            fetchData();
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          }
        }
      }
    );
  };

  const confirm = (requestId) => {
    declineRequest(requestId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const declineRequest = (requestId) => {
    API.post(`parents/assign/approve/${requestId}?status=CANCELED`).then(
      (result) => {
        if (result.status === 200) {
          if (result.data.success) {
            fetchData();
            notification["success"]({
              message: "Амжилттай",
              description: result.data.message,
            });
          }
        }
      }
    );
  };

  return (
    <>
      <PageTitle
        title="Хүүхэд нэмүүлэх хүсэлтүүд"
        description="Эцэг эхийн апп дээрээс гаргасан хүсэлтүүд"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  console.log(state);
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedParentalRequest = connect(
  mapState,
  actionCreators
)(ParentalRequest);
export { connectedParentalRequest as ParentalRequest };
