import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { Table, notification, Space, Popconfirm, Avatar } from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";

function Students() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.post("students").then((result) => {
      if (result.status == 200) {
        setData(result.data.content.content);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Овог нэр",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Анги",
      dataIndex: "className",
      key: "className",
    },
  ];

  const confirm = (userId) => {
    deleteUser(userId);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const deleteUser = (userId) => {
    API.delete(`user/delete/${userId}`).then((result) => {
      if (result.status == 200) {
        fetchData();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  return (
    <>
      <PageTitle
        title="Сурагчдын жагсаалт"
        description="Сургуулийн нийт сурагчдын жагсаалт"
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  console.log(state);
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudents = connect(mapState, actionCreators)(Students);
export { connectedStudents as Students };
