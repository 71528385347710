import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { Table, notification, Space, Popconfirm, Form, Spin } from "antd";
import { connect } from "react-redux";
import { PageTitle, Pagination } from "../../components";
import moment from "moment";

function FeedBack({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(10);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
    if (userData.userRoles.includes("ROLE_ADMIN")) {
      setIsAdmin(true);
    }
  }, []);

  const fetchData = (currentPage) => {
    setLoading(true);
    API.post(
      "feedback",
      {
        key: null,
        value: null,
        operation: "LIKE",
      },
      {
        params: { page: currentPage - 1, size: showNum },
      }
    ).then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setData(result.data.content.content);
          setCurrentPage(currentPage);
          setTotalPages(result.data.content.totalPages);
          setLoading(false);
        }
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "title",
      key: "title",
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Нийтэлсэн огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Санал хүсэлт",
      dataIndex: "content",
      key: "content",
      width: 400,
    },
    {
      title: "Анги",
      dataIndex: "stage",
      key: "stage",
      render: (text, record) => record?.children?.className,
      // record.children.map((item) => item.className).join(", "),
    },
    {
      title: "Нийтэлсэн",
      dataIndex: "createdByName",
      key: "createdByName",
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => confirm(record?.id)}
            onCancel={cancel}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <a href="#">Устгах</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const cancel = (e) => {
    console.log(e);
  };

  const confirm = (feedbackId) => {
    API.delete(`feedback/delete/?feedbackId=${feedbackId}`).then((result) => {
      if (result.status == 200) {
        fetchData(currentPage);

        notification["success"]({
          message: "Амжилттай",
          description: result?.data?.message,
        });
      }
    });
  };

  const prevPage = () => {
    fetchData(currentPage - 1);
  };

  const nextPage = () => {
    fetchData(currentPage + 1);
  };

  const changePage = (num) => {
    fetchData(num);
  };

  return (
    <>
      <PageTitle title="Санал хүсэлт" description="Санал хүсэлт" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Spin spinning={loading}>
                      <Table
                        childrenColumnName="antdChildren"
                        className="table table-centered table-hover mb-0"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        // rowKey="id"
                      />
                    </Spin>
                  </div>

                  <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user, schoolClass } = authentication;
  return {
    userData: user,
    schoolClass: schoolClass !== undefined ? schoolClass : [],
  };
}

const connectedFeedBack = connect(mapState, null)(FeedBack);
export { connectedFeedBack as FeedBack };
