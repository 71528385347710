import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  useParams,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle, Pagination } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

function RouteItem() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  let history = useHistory();
  let location = useLocation();

  const routeData =
    location.state !== undefined ? location.state.routeData : null;

  if (routeData === null) {
    history.push("/drivers");
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`students/route-list/${routeData.id}`).then((result) => {
      if (result.status === 200) {
        setData(result.data.content);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Нэр",
      dataIndex: "student",
      key: "student",
      render: (text, row, index) => (text !== null ? text.displayName : "-"),
    },
    {
      title: "Анги",
      dataIndex: "className",
      key: "className",
      render: (text, row, index) =>
        row.student !== null ? row.student.className : "-",
    },
  ];

  return (
    <>
      <PageTitle
        title={routeData.routeDescription}
        description={`${routeData.routeDescription} - нийт сурагчид`}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapState(state) {
  console.log(state);
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedRouteItem = connect(mapState, actionCreators)(RouteItem);
export { connectedRouteItem as RouteItem };
