import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Table,
  Popconfirm,
  Modal,
  Button,
  Form,
  Select,
  Input,
  message,
  notification,
} from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Drivers() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);

  const getDrivers = () => {
    API.get("driver/list").then((result) => {
      if (result.status == 200) {
        if (result.data.success) {
          setDrivers(result.data.content);
        }
      }
    });
  };

  useEffect(() => {
    getDrivers();
    fetchData();
  }, []);

  const fetchData = () => {
    API.get("driver/route-list").then((result) => {
      if (result.status === 200) {
        if (result.data.success) {
          setData(result.data.content);
        }
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{data.indexOf(row) + 1}</>,
    },
    {
      title: "Чиглэлийн нэр",
      dataIndex: "routeDescription",
      key: "routeDescription",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/route/${record.routeNumber}`,
            state: { routeData: record },
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Чиглэлийн дугаар",
      dataIndex: "routeNumber",
      key: "routeNumber",
    },
    {
      title: "Жолооч",
      dataIndex: "driverUserId",
      key: "driverUserId",
      render: (text, row, index) => (
        <>
          {row.driverUserId !== null ? (
            <>
              {row.driverUserId.lastName} {row.driverUserId.firstName}
            </>
          ) : (
            "Жолооч сонгоогүй"
          )}
        </>
      ),
    },
    {
      title: "Хүүхдийн тоо",
      dataIndex: "studentRouteCount",
      key: "studentRouteCount",
    },
    {
      title: "Үйлдэл",
      key: "action",
      align: "center",
      render: (text, record) => (
        <>
          <div className="btn-group" role="group">
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => deleteRoute(record.id)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="mdi mdi-trash-can"></i>
              </button>
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  const deleteRoute = (routeId) => {
    API.post(`driver/delete-route/${routeId}`).then((result) => {
      if (result.status == 200) {
        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });

        fetchData();
      } else {
        notification["error"]({
          message: "Амжилтгүй",
          description: result.data.message,
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        registerRoute(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // setVisible(false);
  };

  const registerRoute = (values) => {
    API.post("driver/add-route", {
      routeNumber: values.routeNumber,
      routeDescription: values.routeDescription,
      driverUserId: values.driverUserId,
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.success) {
            fetchData();
            setVisible(false);
          } else {
            message.error(result.data.message);
          }
        }
      })
      .catch((error) => {
        const result = error.response;
        if (result.status === 400) {
          if (result.data.errors !== undefined) {
            if (result.data.errors.length > 0) {
              message.error(result.data.errors[0].defaultMessage);
            } else {
              message.error("Алдаа гарлаа");
            }
          } else {
            message.error(result.data.message);
          }
        }
      });
  };
  return (
    <>
      <PageTitle
        title="Сургуулийн автобусын чиглэлийн жагсаалт"
        description="Сургуулийн автобусын чиглэлийн жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Чиглэл нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-centered table-hover mb-0"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Ном нэмэх"
        visible={visible}
        destroyOnClose={true}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Чиглэлийн дугаар"
            name="routeNumber"
            rules={[
              { required: true, message: "Чиглэлийн дугаар оруулна уу!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Чиглэлийн нэр"
            name="routeDescription"
            rules={[{ required: true, message: "Чиглэлийн нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Жолооч"
            name="driverUserId"
            rules={[{ required: true, message: "Жолооч сонгоно уу!" }]}
          >
            <Select placeholder="Сонгоно уу" showSearch allowClear>
              {drivers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.firstName + " " + item.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedDrivers = connect(mapState, actionCreators)(Drivers);
export { connectedDrivers as Drivers };
