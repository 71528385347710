import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import { Document, Page } from "react-pdf";
import {
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Upload,
  Spin,
  Input,
  message,
  TreeSelect,
  Row,
  Col,
} from "antd";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { userActions } from "../../_actions";
import moment from "moment";
import HomeWorkModal from "./HomeWorkModal";

const { TreeNode } = TreeSelect;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

function StudentHomeWork() {
  const [form] = Form.useForm();
  const [formAccount] = Form.useForm();

  const [data, setData] = useState([]);
  const [showNum, setShowNum] = useState(100);
  const [loading, setLoading] = useState(false);
  const [showHomeWork, setShowHomeWork] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateData, setCurrentDateData] = useState([]);

  let { studentId } = useParams();

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text, row, index) => (
        <>{(currentPage - 1) * showNum + index + 1}</>
      ),
    },
    {
      title: "Өдөр",
      dataIndex: "sentDate",
      key: "sentDate",
      width: 200,
      render: (text, record) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Үйлдэл",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              const formData = {
                date: moment(record.sentDate),
                subjects: JSON.parse(record.homeworkData),
              };

              setCurrentDateData(formData);
              setShowHomeWork(true);
            }}
            style={{ marginRight: 20 }}
          >
            Харах
          </Button>
          <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => deleteHomework(record.id)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button type="danger">
              <i className="mdi mdi-backup-restore"></i> Устгах
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const deleteHomework = (id) => {
    console.log(id);
    API.delete(`homework/delete?homeworkId=${id}`).then((result) => {
      if (result.status == 200) {
        form.submit();

        notification["success"]({
          message: "Амжилттай",
          description: result.data.message,
        });
      }
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    API.get("homework", {
      params: {
        startDate: moment(values.date[0]).format("YYYY-MM-DD"),
        endDate: moment(values.date[1]).format("YYYY-MM-DD"),
        studentId: studentId,
      },
    })
      .then((result) => {
        if (result.status == 200) {
          if (result.data.success) {
            setData(result.data.content);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleOk = () => {
    formAccount
      .validateFields()
      .then((values) => {
        API.post("homework/add", {
          studentId: studentId,
          homeworkData: JSON.stringify(values.subjects),
          sentDate: moment(values.date).format("YYYY-MM-DD"),
        }).then((result) => {
          if (result.status === 200) {
            if (result.data.success) {
              setShowModal(false);
              formAccount.resetFields();
              form.submit();
              notification["success"]({
                message: "Амжилттай",
                description: result.data.message,
              });
            } else {
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.message,
              });
            }
          }
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={16} align="top">
          <Col className="gutter-row" span={14}>
            <Form
              form={form}
              layout="inline"
              style={{ marginBottom: 10 }}
              initialValues={{
                date: [moment().subtract(7, "days"), moment()],
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="date"
                label="Хугацаа"
                rules={[{ required: true, message: "Хугацаа сонгоно уу!" }]}
              >
                <RangePicker />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Харах
                </Button>
              </Form.Item>
            </Form>
          </Col>

          <Col className="gutter-row text-right" span={10}>
            <Button
              onClick={() => setShowModal(true)}
              style={{ color: "green", marginRight: 20 }}
            >
              Нэмэх
            </Button>
          </Col>
        </Row>
        <Table
          className="table table-centered table-hover mb-0"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          //   locale={{
          //     emptyText: `${moment(form.getFieldValue("date")[0]).format(
          //       "YYYY/MM/DD"
          //     )} - ${moment(form.getFieldValue("date")[1]).format(
          //       "YYYY/MM/DD"
          //     )} хооронд гүйлгээ хийгдээгүй байна`,
          //   }}
        />
      </Spin>
      <Modal
        title="Даалгавар нэмэх"
        visible={showModal}
        onOk={handleOk}
        onCancel={() => {
          setShowModal(false);
          formAccount.resetFields();
        }}
        okText="Хадгалах"
        cancelText="Болих"
        width={900}
      >
        <Form
          {...formItemLayout}
          form={formAccount}
          initialValues={{ date: moment() }}
        >
          <Form.Item
            label="Өдөр"
            name="date"
            rules={[{ required: true, message: "Өдөр сонгоно уу" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.List name="subjects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "subjectName"]}
                      label="Хичээлийн нэр"
                      rules={[
                        { required: true, message: "Хичээлийн нэр сонгоно уу" },
                      ]}
                    >
                      <Select style={{ width: 200 }} allowClear>
                        <Option value="Математик">Математик</Option>
                        <Option value="Монгол хэл">Монгол хэл</Option>
                        <Option value="Уран зохиол">Уран зохиол</Option>
                        <Option value="Монгол бичиг">Монгол бичиг</Option>
                        <Option value="Түүх">Түүх</Option>
                        <Option value="Биеийн тамир">Биеийн тамир</Option>
                        <Option value="Нийгэм">Нийгэм</Option>
                        <Option value="Дуу хөгжим">Дуу хөгжим</Option>
                        <Option value="Физик">Физик</Option>
                        <Option value="Хими ">Хими </Option>
                        <Option value="Биологи">Биологи</Option>
                        <Option value="Газарзүй">Газарзүй</Option>
                        <Option value="Мэдээллийн технологи">
                          Мэдээллийн технологи
                        </Option>
                        <Option value="График дизайн">График дизайн</Option>
                        <Option value="Англи хэл">Англи хэл</Option>
                        <Option value="Математик сонгон">
                          Математик сонгон
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "status"]}
                      label="Төлөв"
                      rules={[
                        {
                          required: true,
                          message: "Хичээлийн төлөв сонгоно уу",
                        },
                      ]}
                    >
                      <Select style={{ width: 200 }} allowClear>
                        <Option value="yes">Илгээсэн</Option>
                        <Option value="no">Илгээгээгүй</Option>
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Хичээл нэмэх
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      <HomeWorkModal
        visible={showHomeWork}
        modalData={currentDateData}
        onHide={() => setShowHomeWork(false)}
        fetchData={() => form.submit()}
      />
    </>
  );
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}

const actionCreators = {
  getUsers: userActions.getAll,
  deleteUser: userActions.delete,
};

const connectedStudentHomeWork = connect(
  mapState,
  actionCreators
)(StudentHomeWork);
export { connectedStudentHomeWork as StudentHomeWork };
